import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface Option {
    id: string | number;
    name: string;
}

interface Props {
    name: string;
    placeholder?: string
    label?: string
    value?: Option
    options: Option[]
    active?: string
    textAlign?: 'center' | 'start' | 'end'
    setLastActive?: (value: React.SetStateAction<string>) => void
    setActive?: (value: React.SetStateAction<string>) => void
    [x: string]: unknown
}

const FSelectField = (props: Props) => {

    const [field, meta, helpers] = useField(props.name);
    const [value, setValue] = useState<Option | null>(props.value || null)

    useEffect(() => {
        if (field.value)
            setValue(props.options.find(item => item.id === field.value) || null)
    }, [])

    const onFocus = () => {
        if (props.setLastActive)
            props.setLastActive(props.active || '')

        if (props.setActive)
            props.setActive(props.name)
    }
    return (
        <>
            <FormControl
                fullWidth
            >
                <InputLabel id="select-label">{props.label}</InputLabel>
                <Select
                    sx={{ textAlign: props.textAlign }}
                    labelId="select-label"
                    {...field}
                    {...props}
                    value={value?.name ?? ""}
                    onFocus={onFocus}
                    onChange={(event: SelectChangeEvent) => {
                        const itemOfOption = props.options.find(item => item.name === event.target.value) as Option

                        if (event.target.value) {
                            helpers.setValue(itemOfOption.id)
                        } else {
                            helpers.setValue(null)
                        }
                        setValue(itemOfOption)
                    }}
                    defaultValue={props.options[0].name}
                >
                    {props.options.map((item) => (
                        <MenuItem
                            sx={{justifyContent: props.textAlign}}
                            key={item.id}
                            value={item.name}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

        </>


    )
}

FSelectField.defaultProps = {
    textAlign: 'start',
}

export default FSelectField
