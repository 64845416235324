// ** React Imports
import { MouseEvent, useState } from "react";

// ** MUI Imports
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// ** Icon Imports
import { Color } from "src/types/enum-types/Color";
import { Grid, Typography } from "@mui/material";
import { Role } from "src/types/enum-types/Role";
import FTooltipToggleButton from "../FTooltipToggleButton";

// Styled ToggleButton component

type Props = {
  mainRole: Role;
  setMainRole: any;
};

const FButtonToggleRole = (props: Props) => {
  // ** States
  // ** Props
  const { mainRole, setMainRole } = props;

  // ** States
  const [colorValue, setColorValue] = useState<Color>(Color.info);

  const handleAlignment = (event: MouseEvent<HTMLElement>, pValue: Role) => {
    if (pValue !== null) {
      if (pValue === Role.Admin) {
        setColorValue(Color.success);
      } else {
        setColorValue(Color.info);
      }
      setMainRole(pValue);
    } else {
      setColorValue(Color.info);
      setMainRole(Role.User);
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ maxWidth: "600px" }}
    >
      <Grid item xs={6}>
        <Typography variant="subtitle2">Role</Typography>
      </Grid>
      <Grid item xs={6} display="flex" justifyContent="right">
        <ToggleButtonGroup
          color={colorValue}
          exclusive
          value={mainRole}
          onChange={handleAlignment}
          aria-label="role-button-toggle"
        >
          <FTooltipToggleButton
            value={Role.User}
            sx={{ px: 5, textTransform: "capitalize" }}
            aria-label="user"
            TooltipProps={{ title: "Change role to User" }}
          >
            User
          </FTooltipToggleButton>
          <FTooltipToggleButton
            value={Role.Admin}
            sx={{ px: 5, textTransform: "capitalize" }}
            aria-label="admin"
            TooltipProps={{ title: "Change role to Admin" }}
          >
            Admin
          </FTooltipToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};

export default FButtonToggleRole;
