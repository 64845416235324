import { createContext, Dispatch, SetStateAction, useState } from "react";
import { Color } from "src/types/enum-types/Color";

interface IGlobalContext {
  visibilityDisabledSensors: string[];
  setVisibilityDisabledSensors: Dispatch<SetStateAction<string[]>>;
  module: boolean;
  setModule: Dispatch<SetStateAction<boolean>>;
  sensor: boolean;
  setSensor: Dispatch<SetStateAction<boolean>>;
  toastDesignColor: Color;
  setToastDesignColor: Dispatch<SetStateAction<Color>>;
  breadcrumbTitle: string;
  setBreadcrumbTitle: Dispatch<SetStateAction<string>>;
}

export const GlobalContext = createContext<IGlobalContext>({
  visibilityDisabledSensors: [],
  setVisibilityDisabledSensors: () => {},
  module: false,
  setModule: () => false,
  sensor: false,
  setSensor: () => false,
  toastDesignColor: Color.primary,
  setToastDesignColor: () => {},
  breadcrumbTitle: "",
  setBreadcrumbTitle: () => {},
});

export const GlobalProvider = (props: any) => {
  const [visibilityDisabledSensors, setVisibilityDisabledSensors] = useState<string[]>([]);
  const [module, setModule] = useState(false);
  const [sensor, setSensor] = useState(false);
  const [toastDesignColor, setToastDesignColor] = useState(Color.primary);
  const [breadcrumbTitle, setBreadcrumbTitle] = useState("");

  return (
    <GlobalContext.Provider
      value={{
        visibilityDisabledSensors,
        setVisibilityDisabledSensors,
        module,
        setModule,
        sensor,
        setSensor,
        toastDesignColor,
        setToastDesignColor,
        breadcrumbTitle,
        setBreadcrumbTitle,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
