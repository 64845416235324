import React from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";
import { InputAdornment, useTheme } from "@mui/material";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";

interface Props {
  name: string;
  type: string;
  placeholder?: string;
  active?: string;
  setActive?: (value: React.SetStateAction<string>) => void;
  setLastActive?: (value: React.SetStateAction<string>) => void;
  [x: string]: unknown;
}

export const FTextFieldEmailLogin = (props: Props) => {
  const [field, meta] = useField(props);

  const theme = useTheme();

  const adornment = (
    <InputAdornment position="start">
      <AlternateEmailRoundedIcon
        sx={{
          color: theme.palette.primary.main,
          "& .Mui-error >": {},
        }}
      />
    </InputAdornment>
  );

  const onFocus = () => {
    if (props.setLastActive) props.setLastActive(props.active || "");

    if (props.setActive) props.setActive(props.name);
  };

  return (
    <TextField
      {...field}
      {...props}
      fullWidth
      sx={{
        maxWidth: "600px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.palette.customColors.customizedComponentWithoutTheme}`,
        },
        "&:hover:not(.Mui-focused):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline":
          {
            borderColor: theme.palette.primary.main,
            borderWidth: 2,
          },
        "&:hover:not(.Mui-focused):not(.Mui-disabled) .MuiInputLabel-root": {
          color: theme.palette.primary.main,
        },
        color: theme.palette.error.main,
      }}
      InputLabelProps={{
        sx: {
          color: theme.palette.customColors.customizedTextWithoutTheme,
        },
      }}
      inputProps={{
        sx: {
          color: theme.palette.customColors.customizedTextWithoutTheme,
        },
      }}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      onFocus={onFocus}
      InputProps={{
        autoComplete: "chrome-off",
        startAdornment: adornment,
      }}
    />
  );
};
