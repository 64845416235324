// ** Context Import
import { Settings } from "src/@core/context/settingsContext";

// ** Library Imports
import Image from "mui-image";

// ** MUI Imports
import { Link, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

// ** React Import
import { ReactNode } from "react";

interface Props {
  hidden: boolean;
  navHover: boolean;
  settings: Settings;
  collapsedNavWidth: number;
  menuLockedIcon?: ReactNode;
  menuUnlockedIcon?: ReactNode;
  navigationBorderWidth: number;
  toggleNavVisibility: () => void;
  saveSettings: (values: Settings) => void;
  verticalNavMenuBranding?: (props?: any) => ReactNode;
}

type NavFooterProps = {
  navCollapsed: boolean;
};
// ** Styled Components
const MenuFooterWrapper = styled(Box)<NavFooterProps>(
  ({ theme, navCollapsed }) => ({
    position: "fixed",
    bottom: 0,
    width: navCollapsed ? "64px" : "260px",
    display: "flex",
    alignItems: "center",
    marginBottom: 0,
    backgroundColor: theme.palette.customColors.sidebar,
    paddingRight: theme.spacing(4.5),
    transition: "padding .25s ease-in-out",
    minHeight: theme.mixins.toolbar.minHeight,
  })
);

const StyledLink = styled("a")({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
});

const VerticalNavFooter = (props: Props) => {
  // ** Props
  const {
    navHover,
    settings,
    collapsedNavWidth,
    navigationBorderWidth,
    verticalNavMenuBranding: userVerticalNavMenuBranding,
  } = props;

  // ** Hooks & Vars
  const { navCollapsed } = settings;

  const menuHeaderPaddingLeft = () => {
    if (navCollapsed && !navHover) {
      if (userVerticalNavMenuBranding) {
        return 0;
      } else {
        return (collapsedNavWidth - navigationBorderWidth - 32) / 8;
      }
    } else {
      return 6;
    }
  };

  return (
    <MenuFooterWrapper
      className="nav-footer"
      sx={{ pl: menuHeaderPaddingLeft() }}
      navCollapsed={navCollapsed}
    >
      {userVerticalNavMenuBranding ? (
        userVerticalNavMenuBranding(props)
      ) : (
        <Link href="https:/delphisonic.com">
          <StyledLink>
            <Box
              sx={{
                width: "100%",
                display: navCollapsed && !navHover ? "none" : "flex",
                transition: "display .25s ease-in-out",
                marginLeft: navCollapsed ? 0 : 9,
                marginBottom: 3,
              }}
              alignItems="center"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="end"
              >
                <Image duration={1000} width={128} src="/images/logo.png" />
                <Typography variant="caption">v1.0.1</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: navCollapsed && !navHover ? "flex" : "none",
                transition: "display .25s ease-in-out",
              }}
            >
              <Image width={32} duration={500} src="/images/only-logo.png" />
            </Box>
          </StyledLink>
        </Link>
      )}
    </MenuFooterWrapper>
  );
};

export default VerticalNavFooter;
