// ** React Imports
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useReducer,
  useContext,
} from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import {
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  styled,
} from "@mui/material";

// ** Icon Imports
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

// ** Graphql Imports
import {
  UserCreateInput,
  UsersDocument,
  SensorsDocument,
  UserUpdateInput,
  useCreateUserMutation,
  useUpdateUserMutation,
  useUsersLazyQuery,
  useChangeModuleDeletedStateMutation,
} from "src/generated/graphql";

// ** Util Imports
import { hexToRGBA } from "src/@core/utils/hex-to-rgba";

// ** Context Imports
import { GlobalContext } from "src/context/GlobalContext";

// ** Type Imports
import { Color } from "src/types/enum-types/Color";
import { StateDelete } from "src/types/enum-types/StateDelete";

// Component Imports
import { FTextField } from "src/@core/components/form-field/FTextField";
import PendingToast from "src/@core/components/toast/PendingToast";
import SuccessToast from "src/@core/components/toast/SuccessToast";
import ErrorToast from "src/@core/components/toast/ErrorToast";
import DataGridSpeacial from "src/@core/components/data-grid/DataGridSpeacial";
import DeleteToast from "src/@core/components/toast/DeleteToast";

// ** Library Imports
import moment from "moment";
import * as yup from "yup";
import { string } from "yup";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { UserType } from "src/types/graphql/userType";
import { useNavigate } from "react-router";

// ** Validation Schema
const validationSchema = yup.object().shape({
  email: string().required("User name required"),
  password: string().required("Password required"),
  passwordConfirmation: string().oneOf(
    [yup.ref("password"), ""],
    "Passwords must match"
  ),
});

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: hexToRGBA(theme.palette.customColors.dialogHeaderText, 0.85),
}));

const UsersPage = () => {
  // ** Context Datas
  const { setToastDesignColor } = useContext(GlobalContext);
  const navigate = useNavigate();
  // ** States
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [rowCountState, setRowCountState] = useState(totalRowCount || 0);
  const [rows, setRows] = useState<UserType[]>([]);
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [selectedRowClone, setSelectedRowClone] = useState<string[]>([]);
  const [resetRowSelected, setResetRowSelected] = useState(false);
  const [isNew, setIsNew] = useState<boolean>(true);
  const [userModal, setUserModal] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<
    UserCreateInput | UserUpdateInput
  >({
    email: "",
    password: "",
    personalInformations: {
      firstName: "",
      lastName: "",
      title: "",
    },
  } as UserCreateInput);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  // ** Utils Functions
  const [Users, { loading }] = useUsersLazyQuery();
  const [CreateUser] = useCreateUserMutation();
  const [UpdateUser] = useUpdateUserMutation();
  const [DeleteUser] = useChangeModuleDeletedStateMutation();

  // ** DataGrid Column Design
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        flex: 0.11,
        minWidth: 100,
        field: "fullName",
        headerName: "FullName",
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              variant="body2"
              sx={{ color: "text.primary", fontWeight: 600 }}
            >
              {row.personalInformations.fullName}
            </Typography>
          );
        },
      },
      {
        flex: 0.11,
        minWidth: 100,
        field: "title",
        headerName: "Title",
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              variant="body2"
              sx={{ color: "text.primary", fontWeight: 600 }}
            >
              {row.personalInformations.title}
            </Typography>
          );
        },
      },
      {
        flex: 0.175,
        minWidth: 110,
        field: "updatedAt",
        headerName: "Last Updated Date",
        align: "right",
        headerAlign: "right",
        renderCell: (params: GridRenderCellParams) => (
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            {moment(params.row.updatedAt).fromNow()}
          </Typography>
        ),
      },
    ],
    []
  );

  const onDelete = (id: string) => {
    DeleteUser({
      variables: {
        id: id,
        isDeleted: true,
      },
      refetchQueries: [UsersDocument, SensorsDocument],
      awaitRefetchQueries: true,
    });
  };

  // ** Functions

  const openUserDialog = async (id = "new") => {
    if (id === "new") {
      setInitialValues({
        email: "",
        password: "",
        personalInformations: {
          firstName: "",
          lastName: "",
          title: "",
        },
      } as UserCreateInput);
      setIsNew(true);
    } else {
      setIsNew(false);
    }

    setUserModal(true);
  };

  const fetchTableData = useCallback(async () => {
    await Users({
      fetchPolicy: "network-only",
    }).then((res) => {
      setTotalRowCount(res.data?.users.items.length || 0);
      setRows(res.data?.users.items || ([] as UserType[]));
    });
  }, [Users]);

  const onCreateUser = () => {
    navigate("/user/new");
  };

  const resetDataGrid = () => {
    setResetRowSelected(true);
    setSelectedRow([]);
    setSelectedRowClone([]);
    fetchTableData();
  };

  const checkCollection = () => {
    if (selectedRowClone.length > 0) {
      selectedRowClone.forEach(async (id) => {
        await DeleteUser({
          variables: {
            id,
            isDeleted: true,
          },
        });
      });

      resetDataGrid();
    }
  };

  function reducer(state: any, action: any) {
    switch (action.type) {
      case "QUEUE_FOR_REMOVAL":
        return {
          stateDelete: StateDelete.select,
        };
      case "REMOVE":
        return {
          ...state,
          stateDelete: StateDelete.delete,
        };
      case "UNDO":
        return {
          undo: true,
          stateDelete: StateDelete.cancel,
        };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    stateDelete: StateDelete.select,
    undo: false,
  });

  const notify = () => {
    dispatch({
      type: "QUEUE_FOR_REMOVAL",
    });

    setToastDesignColor(Color.primary);

    toast(
      <DeleteToast
        title={`${selectedRow.length} Marka`}
        onUndo={() => {
          dispatch({
            type: "UNDO",
          });
        }}
      />,
      {
        onClose: () => {
          dispatch({ type: "REMOVE" });
        },
      }
    );
  };

  const onDeleteMany = () => {
    setSelectedRowClone(selectedRow);
    setRows(rows.filter((item) => !selectedRow.includes(item.id)));
    notify();
  };

  const onUpdate = (id: string) => {
    openUserDialog(id);
  };

  const onDetail = (id: string) => {
    //router.push(RouterPath.User_VIEW + id)
  };

  const onSubmit = async (values: UserCreateInput | UserUpdateInput) => {
    const promiseToastData = new Promise(async (resolve, reject) => {
      try {
        let returnedName;

        if (isNew) {
          const returnedData = await CreateUser({
            variables: {
              createUserInput: {
                email: values.email,
                password: "123456",
                personalInformations: {
                  firstName: values.personalInformations.firstName,
                  lastName: values.personalInformations.lastName,
                  title: values.personalInformations.title,
                },
              } as UserCreateInput,
            },
            refetchQueries: [UsersDocument],
          });

          returnedName =
            returnedData.data?.createUser.personalInformations?.firstName;
        } else {
          const updatedData = values as UserUpdateInput;
          const returnedData = await UpdateUser({
            variables: {
              updateUserInput: {
                id: updatedData.id,
                email: updatedData.email,
                personalInformations: {
                  firstName: updatedData.personalInformations.firstName,
                  lastName: updatedData.personalInformations.lastName,
                  title: updatedData.personalInformations.title,
                },
              } as UserUpdateInput,
            },
          });

          returnedName =
            returnedData.data?.updateUser.personalInformations?.firstName;
        }

        resolve(returnedName);
        setUserModal(false);
        fetchTableData();
      } catch (error) {
        const errorResult = (error as Error).message;
        reject(errorResult);
      }
    });

    toast.promise(promiseToastData, {
      pending: {
        render() {
          const description = isNew ? "is creating" : "is Updating";
          return (
            <PendingToast
              title="User"
              description={description}
              color="primary"
            />
          );
        },
        icon: false,
      },
      success: {
        render({ data }) {
          let color = Color.success;
          let description = "Has been successfully created";

          if (!isNew) {
            color = Color.warning;
            description = "Has been successfully updated";
          }
          setToastDesignColor(color);
          return (
            <SuccessToast
              title={data as string}
              description={description}
              color={color}
            />
          );
        },
      },
      error: {
        render({ data }) {
          setToastDesignColor(Color.error);
          return (
            <ErrorToast title={"Error Occurred"} description={data as string} />
          );
        },
      },
    });
  };

  // ** UseEffects
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRowCount !== undefined ? totalRowCount : prevRowCountState
    );
  }, [totalRowCount, setRowCountState]);

  useEffect(() => {
    if (state.stateDelete === StateDelete.delete && !state.undo) {
      checkCollection();
    } else if (state.undo) {
      resetDataGrid();
    }
  }, [state.stateDelete, state.undo]);

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  // ** Components
  const UserModal = React.memo(() => {
    return (
      <Dialog
        fullWidth
        open={userModal}
        maxWidth="xs"
        scroll="body"
        onClose={() => setUserModal(false)}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <DialogTitle sx={{ p: 0, mb: 5 }}>
              <Title p={5}>Create New User</Title>
            </DialogTitle>
            <DialogContent sx={{ paddingTop: "10px !important" }}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <FTextField
                    name="email"
                    fullWidth
                    label="User Email"
                    placeholder="john@transelectrica.com"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FTextField
                    name="password"
                    fullWidth
                    label="Password"
                    placeholder="*****"
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FTextField
                    name="confirmPassword"
                    fullWidth
                    label="Password Again"
                    placeholder="*****"
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FTextField
                    name="personalInformations.firstName"
                    fullWidth
                    label="First Name"
                    placeholder="John"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FTextField
                    name="personalInformations.lastName"
                    fullWidth
                    label="Last Name"
                    placeholder="Due"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FTextField
                    name="personalInformations.title"
                    fullWidth
                    label="Title"
                    placeholder="Admin"
                    type="text"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "end",
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setUserModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                startIcon={<AddRoundedIcon />}
              >
                Create
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    );
  });

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
        mb={5}
      >
        <IconButton
          sx={{ visibility: selectedRow.length > 0 ? "visible" : "hidden" }}
          onClick={onDeleteMany}
        >
          <Badge badgeContent={selectedRow.length} color="primary">
            <DeleteOutlineOutlinedIcon />
          </Badge>
        </IconButton>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddRoundedIcon />}
          onClick={onCreateUser}
        >
          Create User
        </Button>
      </Stack>
      <Card>
        <DataGridSpeacial
          loading={loading}
          columns={columns}
          rows={rows}
          onSelectedRow={setSelectedRow}
          onUpdate={onUpdate}
          onDetail={onDetail}
          resetSelectedRow={resetRowSelected}
          setResetRowSelected={setResetRowSelected}
          rowCountState={rowCountState}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </Card>
      <UserModal />
    </Box>
  );
};

export default UsersPage;
