// ** React Imports
import React, { SyntheticEvent, useEffect, useState, useCallback } from "react";

import ViewSidebarRoundedIcon from "@mui/icons-material/ViewSidebarRounded";
import ViewWeekRoundedIcon from "@mui/icons-material/ViewWeekRounded";
// ** MUI Imports
import Grid from "@mui/material/Grid";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Divider,
  Box,
  styled,
  Chip,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import MuiTab, { TabProps } from "@mui/material/Tab";

// ** Utils Import

// ** Component Imports

// ** Icon Imports
import GpsFixedRoundedIcon from "@mui/icons-material/GpsFixedRounded";

// ** Library Imports
import { useModulesLazyQuery } from "src/generated/graphql";
import GoogleMapReact from "google-map-react";
import FmdGoodRoundedIcon from "@mui/icons-material/FmdGoodRounded";
import { ModuleType } from "src/types/graphql/moduleType";
import ModuleInfoCard from "src/@core/components/card/ModuleInfoCard";

// ** Next Imports

export enum IColorsType {
  active = "success",
  pending = "warning",
  inactive = "secondary",
}

const AnyReactComponent = (props: any) => (
  <Box sx={{ position: "relative" }}>
    <FmdGoodRoundedIcon
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        fontSize: "50px",
        transition: "font-size 0.3s",
        filter: "drop-shadow(0.5mm 0.5mm 3mm #00FF99)",
        "&:hover": {
          fontSize: "70px",
        },
      }}
      color="primary"
    />
  </Box>
);

const Tab = styled(MuiTab)<TabProps>(({ theme }) => ({
  minHeight: 48,
  flexDirection: "row",
  "& svg": {
    marginBottom: "0 !important",
    marginRight: theme.spacing(3),
  },
}));

const UsersViewPage = () => {
  const defaultProps = {
    center: {
      lat: 44.333080173284976,
      lng: 26.838590382254353,
    },
    zoom: 9,
  };

  // ** States
  const [Modules] = useModulesLazyQuery();

  // ** States
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [modules, setModules] = useState<ModuleType[]>([]);

  const [value, setValue] = useState<string>("location");

  const [visibityOfUser, setVisibityOfUser] = useState<boolean>(true);

  // ** Utils Functions

  // ** Table Column Design

  // ** Functions
  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const changeVisibilityOfUser = () => {
    setVisibityOfUser(!visibityOfUser);
  };

  const fetchTableData = useCallback(async () => {
    await Modules({
      
      fetchPolicy: "cache-and-network",
    }).then((res) => {
      setTotalRowCount(res.data?.modules.count || 0);
      setModules(res.data?.modules.items || ([] as ModuleType[]));
    });
  }, []);

  // ** UseEffects
  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          sx={{ display: visibityOfUser ? "block" : "none" }}
          item
          xs={12}
          md={4}
        >
          <TabContext value={value}>
            <TabList
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleChange}
              aria-label="forced scroll tabs example"
              sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              }}
            >
              <Tab
                value="location"
                label="Location"
                icon={<GpsFixedRoundedIcon />}
              />
            </TabList>
            <Box sx={{ mt: 6 }}>
              <TabPanel sx={{ p: 0 }} value="location">
                <Box width={"100%"}>
                  <div
                    style={{
                      margin: 0,
                      padding: 0,
                      height: "calc(100vh - 200px)",
                      width: "100%",
                    }}
                  >
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyDAQIns994YxmiHuh1S2XVFDtg53mOCEX8",
                      }}
                      defaultCenter={defaultProps.center}
                      defaultZoom={defaultProps.zoom}
                    >
                      <AnyReactComponent
                        lat={45.9418997}
                        lng={25.0200795}
                        text="User A"
                      />
                    </GoogleMapReact>
                  </div>
                </Box>
              </TabPanel>
            </Box>
          </TabContext>
        </Grid>

        <Grid item xs={12} md={visibityOfUser ? 8 : 12}>
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Divider sx={{}}>
                <Chip color="primary" label={`Total number of modules: ${totalRowCount}`} />
              </Divider>
              <Tooltip title={visibityOfUser ? "Hide" : "Show"}>
                <IconButton sx={{}} onClick={changeVisibilityOfUser}>
                  {visibityOfUser ? (
                    <ViewWeekRoundedIcon />
                  ) : (
                    <ViewSidebarRoundedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Stack>
          </Box>
          <Box
            sx={{
              height: "89vh !important",
              maxHeight: "89vh !important",
              overflow: "scroll",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                {
                  /*
                }
                {modules.map((item, index) => (
                  <ModuleInfoCard
                    key={index}
                    id={item.id}
                    title={item.name}
                    description={item.description as string}
                    sensorCount={item._count?.sensors as number}
                  ></ModuleInfoCard>
                ))*/}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default UsersViewPage;
