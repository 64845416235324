// ** React Imports
import { useEffect, ReactNode } from 'react'

// ** MUI Imports
import { Direction } from '@mui/material'

interface DirectionProps {
  children: ReactNode
  direction: Direction
}

const CDirection = (props: DirectionProps) => {
  const { children, direction } = props

  useEffect(() => {
    document.dir = direction
  }, [direction])

  return <>{children}</>
}

export default CDirection
