// ** React Imports
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useReducer,
  useContext,
} from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  styled,
} from "@mui/material";

// ** Icon Imports
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

// ** Graphql Imports
import {
  SubstationCreateInput,
  SubstationsDocument,
  SubstationUpdateInput,
  useCreateSubstationMutation,
  useUpdateSubstationMutation,
  useSubstationsLazyQuery,
  useSubstationLazyQuery,
  useChangeSubstationDeletedStateMutation,
  useChangeSubstationDisabledStateMutation,
} from "src/generated/graphql";

// ** Util Imports
import { hexToRGBA } from "src/@core/utils/hex-to-rgba";

// ** Context Imports
import { GlobalContext } from "src/context/GlobalContext";
import { ModalContext } from "src/context/modalContext";

// ** Type Imports
import { Color } from "src/types/enum-types/Color";
import { StateDelete } from "src/types/enum-types/StateDelete";
import { SubstationType } from "src/types/graphql/substationType";

// Component Imports
import { FTextField } from "src/@core/components/form-field/FTextField";
import PendingToast from "src/@core/components/toast/PendingToast";
import SuccessToast from "src/@core/components/toast/SuccessToast";
import ErrorToast from "src/@core/components/toast/ErrorToast";
import DataGridSpeacial from "src/@core/components/data-grid/DataGridSpeacial";
import DeleteToast from "src/@core/components/toast/DeleteToast";
import Chip from "src/@core/components/mui/chip";

// ** Library Imports
import moment from "moment";
import * as yup from "yup";
import { string } from "yup";
import { Field, Form, Formik, useFormikContext } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// ** Validation Schema
const validationSchema = yup.object().shape({
  name: string().required("Substation name required"),
});

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: hexToRGBA(theme.palette.customColors.dialogHeaderText, 0.85),
}));

const AutoChange = () => {
  const { values, setFieldValue } = useFormikContext<
    SubstationCreateInput | SubstationUpdateInput
  >();
  useEffect(() => {
    setFieldValue("name", values.name);
  }, [values.name]);

  return null;
};

const SubstationsPage = () => {
  // ** Context Datas
  const { setSubstationModal, substationModal } = useContext(ModalContext);
  const { setToastDesignColor } = useContext(GlobalContext);
  const navigate = useNavigate();

  // ** States
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [rowCountState, setRowCountState] = useState(totalRowCount || 0);
  const [rows, setRows] = useState<SubstationType[]>([]);
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [selectedRowClone, setSelectedRowClone] = useState<string[]>([]);
  const [resetRowSelected, setResetRowSelected] = useState(false);
  const [isNew, setIsNew] = useState<boolean>(true);
  const [oneWillBeDeleted, setOneWillBeDeleted] = useState<boolean>(false);
  const [substationId, setSubstationId] = useState<string>("new");
  const [initialValues, setInitialValues] = useState<
    SubstationCreateInput | SubstationUpdateInput
  >({
    name: "",
    description: null,
    latitude: null,
    longitude: null,
  } as SubstationCreateInput);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  // ** Utils Functions
  const [Substations, {loading}] = useSubstationsLazyQuery();
  const [Substation] = useSubstationLazyQuery();
  const [CreateSubstation] = useCreateSubstationMutation();
  const [UpdateSubstation] = useUpdateSubstationMutation();
  const [ChangeDisableStateSubstation] =
    useChangeSubstationDisabledStateMutation();
  const [DeleteSubstation] = useChangeSubstationDeletedStateMutation();

  // ** DataGrid Column Design
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        flex: 0.11,
        minWidth: 100,
        field: "name",
        headerName: "Substation Name",
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              variant="body2"
              sx={{ color: "text.primary", fontWeight: 600 }}
            >
              {row.name}
            </Typography>
          );
        },
      },
      {
        flex: 0.11,
        minWidth: 100,
        field: "description",
        headerName: "Description",
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              variant="body2"
              sx={{ color: "text.primary", fontWeight: 600 }}
            >
              {row.description}
            </Typography>
          );
        },
      },
      {
        flex: 0.21,
        minWidth: 100,
        field: "locationInformation",
        headerName: "Location information",
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Box display="flex" flexDirection="row">
              <Chip
                skin="light"
                label={row.latitude ? row.latitude : 'No info'}
                color={Color.info}
                sx={{
                  height: 24,
                  "& .MuiChip-label:hover": {
                    color: (theme) =>
                      theme.palette.customColors.customizedChipText,
                  },
                  fontSize: "0.75rem",
                  "& .MuiChip-label": {
                    fontWeight: 600,
                    lineHeight: 1.4,
                  },
                }}
              />
              <Typography sx={{
                mx: 2
              }}>
              /
              </Typography>

              <Chip
                skin="light"
                label={row.longitude ? row.longitude : 'No info'}
                color={Color.info}
                sx={{
                  height: 24,
                  "& .MuiChip-label:hover": {
                    color: (theme) =>
                      theme.palette.customColors.customizedChipText,
                  },
                  fontSize: "0.75rem",
                  "& .MuiChip-label": {
                    fontWeight: 600,
                    lineHeight: 1.4,
                  },
                }}
              />
            </Box>
          );
        },
      },
      {
        flex: 0.175,
        minWidth: 110,
        field: "updatedAt",
        headerName: "Last Updated Date",
        align: "right",
        headerAlign: "right",
        renderCell: (params: GridRenderCellParams) => (
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            {moment(params.row.updatedAt).fromNow()}
          </Typography>
        ),
      },
      {
        flex: 0.05,
        minWidth: 100,
        field: "action",
        headerName: "Disabled",
        align: "center",
        headerAlign: "center",
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const onChangeDisableState = (e: any) => {
            e.stopPropagation(); // don't select this row after clicking
            changeDisableStateOne(params.row.id, params.row.isDisabled);
          };

          return (
            <Chip
              onClick={onChangeDisableState}
              skin="light"
              label={params.row.isDisabled ? "Inactive" : "Active"}
              color={params.row.isDisabled ? "secondary" : "success"}
              sx={{
                height: 24,
                "& .MuiChip-label:hover": {
                  color: (theme) =>
                    theme.palette.customColors.customizedChipText,
                },
                fontSize: "0.75rem",
                "& .MuiChip-label": {
                  fontWeight: 600,
                  lineHeight: 1.4,
                },
              }}
            />
          );
        },
      },
    ],
    []
  );

  // ** Functions
  const fillFields = async (id: string) => {
    await Substation({
      variables: {
        id: id,
      },
      fetchPolicy: "cache-and-network",
    }).then((res) => {
      setInitialValues({
        id: res.data?.substation.id,
        name: res.data?.substation?.name,
        latitude: res.data?.substation.latitude,
        longitude: res.data?.substation.longitude,
        description: res.data?.substation?.description,
      } as SubstationUpdateInput);
      setSubstationId(id);
    });
  };

  const openSubstationDialog = async (id = "new") => {
    if (id === "new") {
      setInitialValues({
        name: "",
        description: "",
        latitude: null,
        longitude: null,
      } as SubstationCreateInput);
      setIsNew(true);
    } else {
      await fillFields(id);
      setIsNew(false);
    }

    setSubstationModal(true);
  };

  const fetchTableData = useCallback(async () => {
    await Substations({
      fetchPolicy: "network-only",
    }).then((res) => {
      setTotalRowCount(res.data?.substations.count || 0);
      setRows(res.data?.substations.items || ([] as SubstationType[]));
    });
  }, [Substations]);

  const onCreateSubstation = () => {
    openSubstationDialog();
  };

  const checkCollection = () => {
    if (oneWillBeDeleted) {
      deleteOne(substationId);
      setOneWillBeDeleted(false);
    } else {
      if (selectedRowClone.length > 0) {
        selectedRowClone.forEach(async (id) => {
          console.log(id);
          deleteOne(id);
        });
      }
    }
    resetDataGrid();
  };

  function reducer(state: any, action: any) {
    switch (action.type) {
      case "QUEUE_FOR_REMOVAL":
        return {
          stateDelete: StateDelete.select,
        };
      case "REMOVE":
        return {
          ...state,
          stateDelete: StateDelete.delete,
        };
      case "UNDO":
        return {
          undo: true,
          stateDelete: StateDelete.cancel,
        };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    stateDelete: StateDelete.select,
    undo: false,
  });

  const notify = () => {
    dispatch({
      type: "QUEUE_FOR_REMOVAL",
    });

    setToastDesignColor(Color.primary);

    let title = "1 Substation";

    if (selectedRow.length > 1) {
      if (!oneWillBeDeleted) title = `${selectedRow.length} Substations`;
    }

    toast(
      <DeleteToast
        title={title}
        onUndo={() => {
          dispatch({
            type: "UNDO",
          });
        }}
      />,
      {
        onClose: () => {
          dispatch({ type: "REMOVE" });
        },
      }
    );
  };

  const resetDataGrid = () => {
    setResetRowSelected(true);
    setSelectedRow([]);
    setSelectedRowClone([]);
  };

  const deleteOne = async (id: string) => {
    await DeleteSubstation({
      variables: {
        id,
        isDeleted: true,
      },
    });
  };

  const changeDisableStateOne = async (pId: string, pIsDisabled: boolean) => {
    await ChangeDisableStateSubstation({
      variables: {
        id: pId,
        isDisabled: !pIsDisabled,
      },
    });

    fetchTableData();
  };

  const onDeleteOne = (id: string) => {
    setSubstationId(id);
    setOneWillBeDeleted(true);
    setRows(rows.filter((item) => !(item.id === id)));
    notify();
  };

  const onDeleteMany = () => {
    setSelectedRowClone(selectedRow);
    setRows(rows.filter((item) => !selectedRow.includes(item.id)));
    notify();
  };

  const onUpdate = (id: string) => {
    openSubstationDialog(id);
  };

  const onDetail = (id: string) => {
    navigate(`/substation/view/${id}`);
  };

  const onSubmit = async (
    values: SubstationCreateInput | SubstationUpdateInput
  ) => {
    const promiseToastData = new Promise(async (resolve, reject) => {
      try {
        let returnedName;

        if (isNew) {
          const returnedData = await CreateSubstation({
            variables: {
              createSubstationInput: {
                name: values.name,
                description: values.description,
                latitude: values.latitude,
                longitude: values.longitude,
              } as SubstationCreateInput,
            },
            refetchQueries: [SubstationsDocument],
          });

          returnedName = returnedData.data?.createSubstation.name;
        } else {
          const updatedData = values as SubstationUpdateInput;
          const returnedData = await UpdateSubstation({
            variables: {
              updateSubstationInput: {
                name: updatedData.name,
                description: updatedData.description,
                latitude: updatedData.latitude,
                longitude: updatedData.longitude,
              } as SubstationUpdateInput,
              id: substationId,
            },
          });

          returnedName = returnedData.data?.updateSubstation.name;
        }

        resolve(returnedName);
        setSubstationModal(false);
        fetchTableData();
      } catch (error) {
        const errorResult = (error as Error).message;
        reject(errorResult);
      }
    });

    toast.promise(promiseToastData, {
      pending: {
        render() {
          const description = isNew ? "is creating" : "is updating";
          return (
            <PendingToast
              title="Substation"
              description={description}
              color="primary"
            />
          );
        },
        icon: false,
      },
      success: {
        render({ data }) {
          let color = Color.success;
          let description = "Has been successfully created";

          if (!isNew) {
            color = Color.warning;
            description = "Has been successfully updated";
          }
          setToastDesignColor(color);
          return (
            <SuccessToast
              title={data as string}
              description={description}
              color={color}
            />
          );
        },
      },
      error: {
        render({ data }) {
          setToastDesignColor(Color.error);
          return (
            <ErrorToast title={"Error Occurred"} description={data as string} />
          );
        },
      },
    });
  };

  // ** UseEffects
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRowCount !== undefined ? totalRowCount : prevRowCountState
    );
  }, [totalRowCount, setRowCountState]);

  useEffect(() => {
    if (state.stateDelete === StateDelete.delete && !state.undo) {
      checkCollection();
    } else if (state.undo) {
      resetDataGrid();
      fetchTableData();
    }
  }, [state.stateDelete, state.undo]);

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  // ** Components
  const SubstationModal = React.memo(() => {
    return (
      <Dialog
        fullWidth
        open={substationModal}
        maxWidth="xs"
        scroll="body"
        onClose={() => setSubstationModal(false)}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <DialogTitle sx={{ p: 0, mb: 5 }}>
              <Title p={5}>
                {isNew ? "Create New Substation" : "Update Substation"}
              </Title>
            </DialogTitle>
            <DialogContent sx={{ paddingTop: "10px !important" }}>
              <AutoChange />
              <Grid container spacing={5}>
                {!isNew && <Field name="id" type="hidden" />}
                <Grid item xs={12}>
                  <FTextField
                    name="name"
                    fullWidth
                    label="Substation Name"
                    placeholder="Substation - A"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FTextField
                    name="latitude"
                    fullWidth
                    label="Substation Latitude"
                    placeholder="(Optional) 45.9418997"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FTextField
                    name="longitude"
                    fullWidth
                    label="Substation Longitude"
                    placeholder="(Optional) 25.0200795"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FTextField
                    name="description"
                    multiline
                    rows={4}
                    fullWidth
                    label="Description"
                    placeholder="(Optional) Description for Substation"
                    type="text"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "end",
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setSubstationModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                startIcon={isNew ? <AddRoundedIcon /> : <CheckRoundedIcon />}
              >
                {isNew ? "Create" : "Update"}
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    );
  });

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
        mb={5}
      >
        <IconButton
          sx={{ visibility: selectedRow.length > 0 ? "visible" : "hidden" }}
          onClick={onDeleteMany}
        >
          <Badge badgeContent={selectedRow.length} color="primary">
            <DeleteOutlineOutlinedIcon />
          </Badge>
        </IconButton>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddRoundedIcon />}
          onClick={onCreateSubstation}
        >
          Create Substation
        </Button>
      </Stack>
      <Card>
        <DataGridSpeacial
          columns={columns}
          rows={rows}
          loading={loading}
          onSelectedRow={setSelectedRow}
          onUpdate={onUpdate}
          onDelete={onDeleteOne}
          onDetail={onDetail}
          resetSelectedRow={resetRowSelected}
          setResetRowSelected={setResetRowSelected}
          rowCountState={rowCountState}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </Card>
      <SubstationModal />
    </Box>
  );
};

export default SubstationsPage;
