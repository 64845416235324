import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AirQualityDataResult = {
  __typename?: 'AirQualityDataResult';
  airQualityData: Array<ChartDataResult>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export enum ChartDataGroupType {
  All = 'all',
  OneDay = 'one_day',
  OneMonth = 'one_month',
  OneWeek = 'one_week',
  OneYear = 'one_year'
}

export type ChartDataResult = {
  __typename?: 'ChartDataResult';
  count: Scalars['Float']['output'];
  x: Scalars['String']['output'];
  y: Scalars['Float']['output'];
};

export type ConsolidatedDataResult = {
  __typename?: 'ConsolidatedDataResult';
  co2Data: Array<ChartDataResult>;
  gasPercentageData: Array<ChartDataResult>;
  gasResistanceData: Array<ChartDataResult>;
  pressureData: Array<ChartDataResult>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type Module = {
  __typename?: 'Module';
  _count: ModuleCount;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isDisabled: Scalars['Boolean']['output'];
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  sensors?: Maybe<Array<Sensor>>;
  substation: Substation;
  substationId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ModuleCount = {
  __typename?: 'ModuleCount';
  sensors: Scalars['Int']['output'];
};

export type ModuleCreateManySubstationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type ModuleCreateManySubstationInputEnvelope = {
  data: Array<ModuleCreateManySubstationInput>;
};

export type ModuleCreateNestedManyWithoutSubstationInput = {
  connect?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleCreateOrConnectWithoutSubstationInput>>;
  create?: InputMaybe<Array<ModuleCreateWithoutSubstationInput>>;
  createMany?: InputMaybe<ModuleCreateManySubstationInputEnvelope>;
};

export type ModuleCreateOrConnectWithoutSubstationInput = {
  create: ModuleCreateWithoutSubstationInput;
  where: ModuleWhereUniqueInput;
};

export type ModuleCreateWithoutSubstationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sensors?: InputMaybe<SensorCreateNestedManyWithoutModuleInput>;
};

export type ModuleListRelationFilter = {
  every?: InputMaybe<ModuleWhereInput>;
  none?: InputMaybe<ModuleWhereInput>;
  some?: InputMaybe<ModuleWhereInput>;
};

export type ModuleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ModuleOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDeleted?: InputMaybe<SortOrder>;
  isDisabled?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sensors?: InputMaybe<SensorOrderByRelationAggregateInput>;
  substation?: InputMaybe<SubstationOrderByWithRelationInput>;
  substationId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ModuleRelationFilter = {
  is?: InputMaybe<ModuleWhereInput>;
  isNot?: InputMaybe<ModuleWhereInput>;
};

export type ModuleResult = {
  __typename?: 'ModuleResult';
  count: Scalars['Float']['output'];
  items: Array<Module>;
};

export type ModuleScalarWhereInput = {
  AND?: InputMaybe<Array<ModuleScalarWhereInput>>;
  NOT?: InputMaybe<Array<ModuleScalarWhereInput>>;
  OR?: InputMaybe<Array<ModuleScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isDeleted?: InputMaybe<BoolFilter>;
  isDisabled?: InputMaybe<BoolFilter>;
  latitude?: InputMaybe<StringNullableFilter>;
  longitude?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  substationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ModuleUncheckedCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sensors?: InputMaybe<SensorUncheckedCreateNestedManyWithoutModuleInput>;
  substationId: Scalars['String']['input'];
};

export type ModuleUncheckedUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sensors?: InputMaybe<SensorUncheckedUpdateManyWithoutModuleNestedInput>;
  substationId?: InputMaybe<Scalars['String']['input']>;
};

export type ModuleUpdateManyMutationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ModuleUpdateManyWithWhereWithoutSubstationInput = {
  data: ModuleUpdateManyMutationInput;
  where: ModuleScalarWhereInput;
};

export type ModuleUpdateManyWithoutSubstationNestedInput = {
  connect?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleCreateOrConnectWithoutSubstationInput>>;
  create?: InputMaybe<Array<ModuleCreateWithoutSubstationInput>>;
  createMany?: InputMaybe<ModuleCreateManySubstationInputEnvelope>;
  delete?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ModuleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  set?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  update?: InputMaybe<Array<ModuleUpdateWithWhereUniqueWithoutSubstationInput>>;
  updateMany?: InputMaybe<Array<ModuleUpdateManyWithWhereWithoutSubstationInput>>;
  upsert?: InputMaybe<Array<ModuleUpsertWithWhereUniqueWithoutSubstationInput>>;
};

export type ModuleUpdateWithWhereUniqueWithoutSubstationInput = {
  data: ModuleUpdateWithoutSubstationInput;
  where: ModuleWhereUniqueInput;
};

export type ModuleUpdateWithoutSubstationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sensors?: InputMaybe<SensorUpdateManyWithoutModuleNestedInput>;
};

export type ModuleUpsertWithWhereUniqueWithoutSubstationInput = {
  create: ModuleCreateWithoutSubstationInput;
  update: ModuleUpdateWithoutSubstationInput;
  where: ModuleWhereUniqueInput;
};

export type ModuleWhereInput = {
  AND?: InputMaybe<Array<ModuleWhereInput>>;
  NOT?: InputMaybe<Array<ModuleWhereInput>>;
  OR?: InputMaybe<Array<ModuleWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isDeleted?: InputMaybe<BoolFilter>;
  isDisabled?: InputMaybe<BoolFilter>;
  latitude?: InputMaybe<StringNullableFilter>;
  longitude?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  sensors?: InputMaybe<SensorListRelationFilter>;
  substation?: InputMaybe<SubstationRelationFilter>;
  substationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ModuleWhereUniqueInput = {
  AND?: InputMaybe<Array<ModuleWhereInput>>;
  NOT?: InputMaybe<Array<ModuleWhereInput>>;
  OR?: InputMaybe<Array<ModuleWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<BoolFilter>;
  isDisabled?: InputMaybe<BoolFilter>;
  latitude?: InputMaybe<StringNullableFilter>;
  longitude?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  sensors?: InputMaybe<SensorListRelationFilter>;
  substation?: InputMaybe<SubstationRelationFilter>;
  substationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changeModuleDeletedState: Module;
  changeModuleDisabledState: Module;
  changeSensorDataDeletedState: SensorData;
  changeSensorDataDisabledState: SensorData;
  changeSensorDeletedState: Sensor;
  changeSensorDisabledState: Sensor;
  changeSubstationDeletedState: Substation;
  changeSubstationDisabledState: Substation;
  createModule: Module;
  createRole: SUser;
  createSensor: Sensor;
  createSensorData: SensorData;
  createSubstation: Substation;
  createUser: SUser;
  deleteModulePermanently: Module;
  deleteSensorDataPermanently: SensorData;
  deleteSensorPermanently: Sensor;
  deleteSubstationPermanently: Substation;
  destroyUserAccount: SUser;
  getRolesFromUser: Array<Scalars['String']['output']>;
  getUserByEmail?: Maybe<SUser>;
  getUserById?: Maybe<SUser>;
  me: SUser;
  updateModule: Module;
  updateSensor: Sensor;
  updateSensorData: SensorData;
  updateSubstation: Substation;
  updateUser: SUser;
};


export type MutationChangeModuleDeletedStateArgs = {
  id: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
};


export type MutationChangeModuleDisabledStateArgs = {
  id: Scalars['String']['input'];
  isDisabled: Scalars['Boolean']['input'];
};


export type MutationChangeSensorDataDeletedStateArgs = {
  id: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
};


export type MutationChangeSensorDataDisabledStateArgs = {
  id: Scalars['String']['input'];
  isDisabled: Scalars['Boolean']['input'];
};


export type MutationChangeSensorDeletedStateArgs = {
  id: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
};


export type MutationChangeSensorDisabledStateArgs = {
  id: Scalars['String']['input'];
  isDisabled: Scalars['Boolean']['input'];
};


export type MutationChangeSubstationDeletedStateArgs = {
  id: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
};


export type MutationChangeSubstationDisabledStateArgs = {
  id: Scalars['String']['input'];
  isDisabled: Scalars['Boolean']['input'];
};


export type MutationCreateModuleArgs = {
  createModuleInput: ModuleUncheckedCreateInput;
};


export type MutationCreateRoleArgs = {
  role: Scalars['String']['input'];
};


export type MutationCreateSensorArgs = {
  createSensorInput: SensorUncheckedCreateInput;
};


export type MutationCreateSensorDataArgs = {
  createSensorDataInput: SensorDataUncheckedCreateInput;
};


export type MutationCreateSubstationArgs = {
  createSubstationInput: SubstationCreateInput;
};


export type MutationCreateUserArgs = {
  createUserInput: UserCreateInput;
};


export type MutationDeleteModulePermanentlyArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSensorDataPermanentlyArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSensorPermanentlyArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSubstationPermanentlyArgs = {
  id: Scalars['String']['input'];
};


export type MutationDestroyUserAccountArgs = {
  id: Scalars['String']['input'];
};


export type MutationGetRolesFromUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationGetUserByEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationGetUserByIdArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdateModuleArgs = {
  id: Scalars['String']['input'];
  updateModuleInput: ModuleUncheckedUpdateInput;
};


export type MutationUpdateSensorArgs = {
  id: Scalars['String']['input'];
  updateSensorInput: SensorUncheckedUpdateInput;
};


export type MutationUpdateSensorDataArgs = {
  id: Scalars['String']['input'];
  updateSensorDataInput: SensorDataUncheckedUpdateInput;
};


export type MutationUpdateSubstationArgs = {
  id: Scalars['String']['input'];
  updateSubstationInput: SubstationUpdateInput;
};


export type MutationUpdateUserArgs = {
  updateUserInput: UserUpdateInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type PersonalInformationInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type PersonalInformationType = {
  __typename?: 'PersonalInformationType';
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type PreferenceInformation = {
  __typename?: 'PreferenceInformation';
  theme: Scalars['String']['output'];
};

export type PreferenceInformationInput = {
  theme: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  getDatasForAirQualityChart: AirQualityDataResult;
  getDatasForConsolidatedChart: ConsolidatedDataResult;
  getDatasForTemperatureAndHumidityChart: TemperatureAndHumidityDataResult;
  getDatasForVibrationChart: VirationDataResult;
  module: Module;
  modules: ModuleResult;
  sensor: Sensor;
  sensorData: SensorData;
  sensorDatas: SensorDataResult;
  sensors: SensorResult;
  substation: Substation;
  substations: SubstationResult;
  users: UserResult;
};


export type QueryGetDatasForAirQualityChartArgs = {
  chartDataGroup?: InputMaybe<ChartDataGroupType>;
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  sensorId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetDatasForConsolidatedChartArgs = {
  chartDataGroup?: InputMaybe<ChartDataGroupType>;
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  sensorId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetDatasForTemperatureAndHumidityChartArgs = {
  chartDataGroup?: InputMaybe<ChartDataGroupType>;
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  sensorId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetDatasForVibrationChartArgs = {
  chartDataGroup?: InputMaybe<ChartDataGroupType>;
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  sensorId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryModuleArgs = {
  id: Scalars['String']['input'];
};


export type QueryModulesArgs = {
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ModuleOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  substationId?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QuerySensorArgs = {
  id: Scalars['String']['input'];
};


export type QuerySensorDataArgs = {
  id: Scalars['String']['input'];
};


export type QuerySensorDatasArgs = {
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<SensorDataOrderByWithRelationInput>>>;
  sensorId?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QuerySensorsArgs = {
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  moduleId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<SensorOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QuerySubstationArgs = {
  id: Scalars['String']['input'];
};


export type QuerySubstationsArgs = {
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<SubstationOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryUsersArgs = {
  data?: InputMaybe<UsersInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type SUser = {
  __typename?: 'SUser';
  /** Email */
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  personalInformations?: Maybe<PersonalInformationType>;
  preferenceInformations?: Maybe<PreferenceInformation>;
  specificInformations?: Maybe<SpecificInformation>;
};

export type Sensor = {
  __typename?: 'Sensor';
  _count: SensorCount;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isDisabled: Scalars['Boolean']['output'];
  module: Module;
  moduleId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sensorDatas?: Maybe<Array<SensorData>>;
  serialNumber: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SensorCount = {
  __typename?: 'SensorCount';
  sensorDatas: Scalars['Int']['output'];
};

export type SensorCreateManyModuleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
};

export type SensorCreateManyModuleInputEnvelope = {
  data: Array<SensorCreateManyModuleInput>;
};

export type SensorCreateNestedManyWithoutModuleInput = {
  connect?: InputMaybe<Array<SensorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SensorCreateOrConnectWithoutModuleInput>>;
  create?: InputMaybe<Array<SensorCreateWithoutModuleInput>>;
  createMany?: InputMaybe<SensorCreateManyModuleInputEnvelope>;
};

export type SensorCreateOrConnectWithoutModuleInput = {
  create: SensorCreateWithoutModuleInput;
  where: SensorWhereUniqueInput;
};

export type SensorCreateWithoutModuleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sensorDatas?: InputMaybe<SensorDataCreateNestedManyWithoutSensorInput>;
  serialNumber: Scalars['String']['input'];
};

export type SensorData = {
  __typename?: 'SensorData';
  airQuality: Scalars['Float']['output'];
  co2: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  gasPercentage: Scalars['Float']['output'];
  gasResistance: Scalars['Float']['output'];
  humidity: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isDisabled: Scalars['Boolean']['output'];
  pressure: Scalars['Float']['output'];
  sensor: Sensor;
  sensorId: Scalars['String']['output'];
  temperature: Scalars['Float']['output'];
  time: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  xRMS: Scalars['Float']['output'];
  yRMS: Scalars['Float']['output'];
  zRMS: Scalars['Float']['output'];
};

export type SensorDataCreateManySensorInput = {
  airQuality?: InputMaybe<Scalars['Float']['input']>;
  co2?: InputMaybe<Scalars['Float']['input']>;
  gasPercentage?: InputMaybe<Scalars['Float']['input']>;
  gasResistance?: InputMaybe<Scalars['Float']['input']>;
  humidity?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  pressure?: InputMaybe<Scalars['Float']['input']>;
  temperature?: InputMaybe<Scalars['Float']['input']>;
  time?: InputMaybe<Scalars['DateTime']['input']>;
  xRMS?: InputMaybe<Scalars['Float']['input']>;
  yRMS?: InputMaybe<Scalars['Float']['input']>;
  zRMS?: InputMaybe<Scalars['Float']['input']>;
};

export type SensorDataCreateManySensorInputEnvelope = {
  data: Array<SensorDataCreateManySensorInput>;
};

export type SensorDataCreateNestedManyWithoutSensorInput = {
  connect?: InputMaybe<Array<SensorDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SensorDataCreateOrConnectWithoutSensorInput>>;
  create?: InputMaybe<Array<SensorDataCreateWithoutSensorInput>>;
  createMany?: InputMaybe<SensorDataCreateManySensorInputEnvelope>;
};

export type SensorDataCreateOrConnectWithoutSensorInput = {
  create: SensorDataCreateWithoutSensorInput;
  where: SensorDataWhereUniqueInput;
};

export type SensorDataCreateWithoutSensorInput = {
  airQuality?: InputMaybe<Scalars['Float']['input']>;
  co2?: InputMaybe<Scalars['Float']['input']>;
  gasPercentage?: InputMaybe<Scalars['Float']['input']>;
  gasResistance?: InputMaybe<Scalars['Float']['input']>;
  humidity?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  pressure?: InputMaybe<Scalars['Float']['input']>;
  temperature?: InputMaybe<Scalars['Float']['input']>;
  time?: InputMaybe<Scalars['DateTime']['input']>;
  xRMS?: InputMaybe<Scalars['Float']['input']>;
  yRMS?: InputMaybe<Scalars['Float']['input']>;
  zRMS?: InputMaybe<Scalars['Float']['input']>;
};

export type SensorDataListRelationFilter = {
  every?: InputMaybe<SensorDataWhereInput>;
  none?: InputMaybe<SensorDataWhereInput>;
  some?: InputMaybe<SensorDataWhereInput>;
};

export type SensorDataOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SensorDataOrderByWithRelationInput = {
  airQuality?: InputMaybe<SortOrder>;
  co2?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  gasPercentage?: InputMaybe<SortOrder>;
  gasResistance?: InputMaybe<SortOrder>;
  humidity?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDeleted?: InputMaybe<SortOrder>;
  isDisabled?: InputMaybe<SortOrder>;
  pressure?: InputMaybe<SortOrder>;
  sensor?: InputMaybe<SensorOrderByWithRelationInput>;
  sensorId?: InputMaybe<SortOrder>;
  temperature?: InputMaybe<SortOrder>;
  time?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  xRMS?: InputMaybe<SortOrder>;
  yRMS?: InputMaybe<SortOrder>;
  zRMS?: InputMaybe<SortOrder>;
};

export type SensorDataResult = {
  __typename?: 'SensorDataResult';
  count: Scalars['Float']['output'];
  items: Array<SensorData>;
};

export type SensorDataScalarWhereInput = {
  AND?: InputMaybe<Array<SensorDataScalarWhereInput>>;
  NOT?: InputMaybe<Array<SensorDataScalarWhereInput>>;
  OR?: InputMaybe<Array<SensorDataScalarWhereInput>>;
  airQuality?: InputMaybe<FloatFilter>;
  co2?: InputMaybe<FloatFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  gasPercentage?: InputMaybe<FloatFilter>;
  gasResistance?: InputMaybe<FloatFilter>;
  humidity?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  isDeleted?: InputMaybe<BoolFilter>;
  isDisabled?: InputMaybe<BoolFilter>;
  pressure?: InputMaybe<FloatFilter>;
  sensorId?: InputMaybe<StringFilter>;
  temperature?: InputMaybe<FloatFilter>;
  time?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  xRMS?: InputMaybe<FloatFilter>;
  yRMS?: InputMaybe<FloatFilter>;
  zRMS?: InputMaybe<FloatFilter>;
};

export type SensorDataUncheckedCreateInput = {
  airQuality?: InputMaybe<Scalars['Float']['input']>;
  co2?: InputMaybe<Scalars['Float']['input']>;
  gasPercentage?: InputMaybe<Scalars['Float']['input']>;
  gasResistance?: InputMaybe<Scalars['Float']['input']>;
  humidity?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  pressure?: InputMaybe<Scalars['Float']['input']>;
  sensorId: Scalars['String']['input'];
  temperature?: InputMaybe<Scalars['Float']['input']>;
  time?: InputMaybe<Scalars['DateTime']['input']>;
  xRMS?: InputMaybe<Scalars['Float']['input']>;
  yRMS?: InputMaybe<Scalars['Float']['input']>;
  zRMS?: InputMaybe<Scalars['Float']['input']>;
};

export type SensorDataUncheckedCreateNestedManyWithoutSensorInput = {
  connect?: InputMaybe<Array<SensorDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SensorDataCreateOrConnectWithoutSensorInput>>;
  create?: InputMaybe<Array<SensorDataCreateWithoutSensorInput>>;
  createMany?: InputMaybe<SensorDataCreateManySensorInputEnvelope>;
};

export type SensorDataUncheckedUpdateInput = {
  airQuality?: InputMaybe<Scalars['Float']['input']>;
  co2?: InputMaybe<Scalars['Float']['input']>;
  gasPercentage?: InputMaybe<Scalars['Float']['input']>;
  gasResistance?: InputMaybe<Scalars['Float']['input']>;
  humidity?: InputMaybe<Scalars['Float']['input']>;
  pressure?: InputMaybe<Scalars['Float']['input']>;
  sensorId?: InputMaybe<Scalars['String']['input']>;
  temperature?: InputMaybe<Scalars['Float']['input']>;
  time?: InputMaybe<Scalars['DateTime']['input']>;
  xRMS?: InputMaybe<Scalars['Float']['input']>;
  yRMS?: InputMaybe<Scalars['Float']['input']>;
  zRMS?: InputMaybe<Scalars['Float']['input']>;
};

export type SensorDataUncheckedUpdateManyWithoutSensorNestedInput = {
  connect?: InputMaybe<Array<SensorDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SensorDataCreateOrConnectWithoutSensorInput>>;
  create?: InputMaybe<Array<SensorDataCreateWithoutSensorInput>>;
  createMany?: InputMaybe<SensorDataCreateManySensorInputEnvelope>;
  delete?: InputMaybe<Array<SensorDataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SensorDataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SensorDataWhereUniqueInput>>;
  set?: InputMaybe<Array<SensorDataWhereUniqueInput>>;
  update?: InputMaybe<Array<SensorDataUpdateWithWhereUniqueWithoutSensorInput>>;
  updateMany?: InputMaybe<Array<SensorDataUpdateManyWithWhereWithoutSensorInput>>;
  upsert?: InputMaybe<Array<SensorDataUpsertWithWhereUniqueWithoutSensorInput>>;
};

export type SensorDataUpdateManyMutationInput = {
  airQuality?: InputMaybe<Scalars['Float']['input']>;
  co2?: InputMaybe<Scalars['Float']['input']>;
  gasPercentage?: InputMaybe<Scalars['Float']['input']>;
  gasResistance?: InputMaybe<Scalars['Float']['input']>;
  humidity?: InputMaybe<Scalars['Float']['input']>;
  pressure?: InputMaybe<Scalars['Float']['input']>;
  temperature?: InputMaybe<Scalars['Float']['input']>;
  time?: InputMaybe<Scalars['DateTime']['input']>;
  xRMS?: InputMaybe<Scalars['Float']['input']>;
  yRMS?: InputMaybe<Scalars['Float']['input']>;
  zRMS?: InputMaybe<Scalars['Float']['input']>;
};

export type SensorDataUpdateManyWithWhereWithoutSensorInput = {
  data: SensorDataUpdateManyMutationInput;
  where: SensorDataScalarWhereInput;
};

export type SensorDataUpdateManyWithoutSensorNestedInput = {
  connect?: InputMaybe<Array<SensorDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SensorDataCreateOrConnectWithoutSensorInput>>;
  create?: InputMaybe<Array<SensorDataCreateWithoutSensorInput>>;
  createMany?: InputMaybe<SensorDataCreateManySensorInputEnvelope>;
  delete?: InputMaybe<Array<SensorDataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SensorDataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SensorDataWhereUniqueInput>>;
  set?: InputMaybe<Array<SensorDataWhereUniqueInput>>;
  update?: InputMaybe<Array<SensorDataUpdateWithWhereUniqueWithoutSensorInput>>;
  updateMany?: InputMaybe<Array<SensorDataUpdateManyWithWhereWithoutSensorInput>>;
  upsert?: InputMaybe<Array<SensorDataUpsertWithWhereUniqueWithoutSensorInput>>;
};

export type SensorDataUpdateWithWhereUniqueWithoutSensorInput = {
  data: SensorDataUpdateWithoutSensorInput;
  where: SensorDataWhereUniqueInput;
};

export type SensorDataUpdateWithoutSensorInput = {
  airQuality?: InputMaybe<Scalars['Float']['input']>;
  co2?: InputMaybe<Scalars['Float']['input']>;
  gasPercentage?: InputMaybe<Scalars['Float']['input']>;
  gasResistance?: InputMaybe<Scalars['Float']['input']>;
  humidity?: InputMaybe<Scalars['Float']['input']>;
  pressure?: InputMaybe<Scalars['Float']['input']>;
  temperature?: InputMaybe<Scalars['Float']['input']>;
  time?: InputMaybe<Scalars['DateTime']['input']>;
  xRMS?: InputMaybe<Scalars['Float']['input']>;
  yRMS?: InputMaybe<Scalars['Float']['input']>;
  zRMS?: InputMaybe<Scalars['Float']['input']>;
};

export type SensorDataUpsertWithWhereUniqueWithoutSensorInput = {
  create: SensorDataCreateWithoutSensorInput;
  update: SensorDataUpdateWithoutSensorInput;
  where: SensorDataWhereUniqueInput;
};

export type SensorDataWhereInput = {
  AND?: InputMaybe<Array<SensorDataWhereInput>>;
  NOT?: InputMaybe<Array<SensorDataWhereInput>>;
  OR?: InputMaybe<Array<SensorDataWhereInput>>;
  airQuality?: InputMaybe<FloatFilter>;
  co2?: InputMaybe<FloatFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  gasPercentage?: InputMaybe<FloatFilter>;
  gasResistance?: InputMaybe<FloatFilter>;
  humidity?: InputMaybe<FloatFilter>;
  id?: InputMaybe<StringFilter>;
  isDeleted?: InputMaybe<BoolFilter>;
  isDisabled?: InputMaybe<BoolFilter>;
  pressure?: InputMaybe<FloatFilter>;
  sensor?: InputMaybe<SensorRelationFilter>;
  sensorId?: InputMaybe<StringFilter>;
  temperature?: InputMaybe<FloatFilter>;
  time?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  xRMS?: InputMaybe<FloatFilter>;
  yRMS?: InputMaybe<FloatFilter>;
  zRMS?: InputMaybe<FloatFilter>;
};

export type SensorDataWhereUniqueInput = {
  AND?: InputMaybe<Array<SensorDataWhereInput>>;
  NOT?: InputMaybe<Array<SensorDataWhereInput>>;
  OR?: InputMaybe<Array<SensorDataWhereInput>>;
  airQuality?: InputMaybe<FloatFilter>;
  co2?: InputMaybe<FloatFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  gasPercentage?: InputMaybe<FloatFilter>;
  gasResistance?: InputMaybe<FloatFilter>;
  humidity?: InputMaybe<FloatFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<BoolFilter>;
  isDisabled?: InputMaybe<BoolFilter>;
  pressure?: InputMaybe<FloatFilter>;
  sensor?: InputMaybe<SensorRelationFilter>;
  sensorId?: InputMaybe<StringFilter>;
  temperature?: InputMaybe<FloatFilter>;
  time?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  xRMS?: InputMaybe<FloatFilter>;
  yRMS?: InputMaybe<FloatFilter>;
  zRMS?: InputMaybe<FloatFilter>;
};

export type SensorListRelationFilter = {
  every?: InputMaybe<SensorWhereInput>;
  none?: InputMaybe<SensorWhereInput>;
  some?: InputMaybe<SensorWhereInput>;
};

export type SensorOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SensorOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDeleted?: InputMaybe<SortOrder>;
  isDisabled?: InputMaybe<SortOrder>;
  module?: InputMaybe<ModuleOrderByWithRelationInput>;
  moduleId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sensorDatas?: InputMaybe<SensorDataOrderByRelationAggregateInput>;
  serialNumber?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SensorRelationFilter = {
  is?: InputMaybe<SensorWhereInput>;
  isNot?: InputMaybe<SensorWhereInput>;
};

export type SensorResult = {
  __typename?: 'SensorResult';
  count: Scalars['Float']['output'];
  items: Array<Sensor>;
};

export type SensorScalarWhereInput = {
  AND?: InputMaybe<Array<SensorScalarWhereInput>>;
  NOT?: InputMaybe<Array<SensorScalarWhereInput>>;
  OR?: InputMaybe<Array<SensorScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isDeleted?: InputMaybe<BoolFilter>;
  isDisabled?: InputMaybe<BoolFilter>;
  moduleId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  serialNumber?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SensorUncheckedCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  moduleId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  sensorDatas?: InputMaybe<SensorDataUncheckedCreateNestedManyWithoutSensorInput>;
  serialNumber: Scalars['String']['input'];
};

export type SensorUncheckedCreateNestedManyWithoutModuleInput = {
  connect?: InputMaybe<Array<SensorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SensorCreateOrConnectWithoutModuleInput>>;
  create?: InputMaybe<Array<SensorCreateWithoutModuleInput>>;
  createMany?: InputMaybe<SensorCreateManyModuleInputEnvelope>;
};

export type SensorUncheckedUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  moduleId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sensorDatas?: InputMaybe<SensorDataUncheckedUpdateManyWithoutSensorNestedInput>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
};

export type SensorUncheckedUpdateManyWithoutModuleNestedInput = {
  connect?: InputMaybe<Array<SensorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SensorCreateOrConnectWithoutModuleInput>>;
  create?: InputMaybe<Array<SensorCreateWithoutModuleInput>>;
  createMany?: InputMaybe<SensorCreateManyModuleInputEnvelope>;
  delete?: InputMaybe<Array<SensorWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SensorScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SensorWhereUniqueInput>>;
  set?: InputMaybe<Array<SensorWhereUniqueInput>>;
  update?: InputMaybe<Array<SensorUpdateWithWhereUniqueWithoutModuleInput>>;
  updateMany?: InputMaybe<Array<SensorUpdateManyWithWhereWithoutModuleInput>>;
  upsert?: InputMaybe<Array<SensorUpsertWithWhereUniqueWithoutModuleInput>>;
};

export type SensorUpdateManyMutationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
};

export type SensorUpdateManyWithWhereWithoutModuleInput = {
  data: SensorUpdateManyMutationInput;
  where: SensorScalarWhereInput;
};

export type SensorUpdateManyWithoutModuleNestedInput = {
  connect?: InputMaybe<Array<SensorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SensorCreateOrConnectWithoutModuleInput>>;
  create?: InputMaybe<Array<SensorCreateWithoutModuleInput>>;
  createMany?: InputMaybe<SensorCreateManyModuleInputEnvelope>;
  delete?: InputMaybe<Array<SensorWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SensorScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SensorWhereUniqueInput>>;
  set?: InputMaybe<Array<SensorWhereUniqueInput>>;
  update?: InputMaybe<Array<SensorUpdateWithWhereUniqueWithoutModuleInput>>;
  updateMany?: InputMaybe<Array<SensorUpdateManyWithWhereWithoutModuleInput>>;
  upsert?: InputMaybe<Array<SensorUpsertWithWhereUniqueWithoutModuleInput>>;
};

export type SensorUpdateWithWhereUniqueWithoutModuleInput = {
  data: SensorUpdateWithoutModuleInput;
  where: SensorWhereUniqueInput;
};

export type SensorUpdateWithoutModuleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sensorDatas?: InputMaybe<SensorDataUpdateManyWithoutSensorNestedInput>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
};

export type SensorUpsertWithWhereUniqueWithoutModuleInput = {
  create: SensorCreateWithoutModuleInput;
  update: SensorUpdateWithoutModuleInput;
  where: SensorWhereUniqueInput;
};

export type SensorWhereInput = {
  AND?: InputMaybe<Array<SensorWhereInput>>;
  NOT?: InputMaybe<Array<SensorWhereInput>>;
  OR?: InputMaybe<Array<SensorWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isDeleted?: InputMaybe<BoolFilter>;
  isDisabled?: InputMaybe<BoolFilter>;
  module?: InputMaybe<ModuleRelationFilter>;
  moduleId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  sensorDatas?: InputMaybe<SensorDataListRelationFilter>;
  serialNumber?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SensorWhereUniqueInput = {
  AND?: InputMaybe<Array<SensorWhereInput>>;
  NOT?: InputMaybe<Array<SensorWhereInput>>;
  OR?: InputMaybe<Array<SensorWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<BoolFilter>;
  isDisabled?: InputMaybe<BoolFilter>;
  module?: InputMaybe<ModuleRelationFilter>;
  moduleId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  sensorDatas?: InputMaybe<SensorDataListRelationFilter>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SpecificInformation = {
  __typename?: 'SpecificInformation';
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isDisabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['String']['output'];
};

export type SpecificInformationInput = {
  createdAt: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  isDeleted: Scalars['Boolean']['input'];
  isDisabled: Scalars['Boolean']['input'];
  updatedAt: Scalars['String']['input'];
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Substation = {
  __typename?: 'Substation';
  _count: SubstationCount;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isDisabled: Scalars['Boolean']['output'];
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  modules?: Maybe<Array<Module>>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SubstationCount = {
  __typename?: 'SubstationCount';
  modules: Scalars['Int']['output'];
};

export type SubstationCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  modules?: InputMaybe<ModuleCreateNestedManyWithoutSubstationInput>;
  name: Scalars['String']['input'];
};

export type SubstationOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDeleted?: InputMaybe<SortOrder>;
  isDisabled?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  modules?: InputMaybe<ModuleOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubstationRelationFilter = {
  is?: InputMaybe<SubstationWhereInput>;
  isNot?: InputMaybe<SubstationWhereInput>;
};

export type SubstationResult = {
  __typename?: 'SubstationResult';
  count: Scalars['Float']['output'];
  items: Array<Substation>;
};

export type SubstationUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  modules?: InputMaybe<ModuleUpdateManyWithoutSubstationNestedInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SubstationWhereInput = {
  AND?: InputMaybe<Array<SubstationWhereInput>>;
  NOT?: InputMaybe<Array<SubstationWhereInput>>;
  OR?: InputMaybe<Array<SubstationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isDeleted?: InputMaybe<BoolFilter>;
  isDisabled?: InputMaybe<BoolFilter>;
  latitude?: InputMaybe<StringNullableFilter>;
  longitude?: InputMaybe<StringNullableFilter>;
  modules?: InputMaybe<ModuleListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TemperatureAndHumidityDataResult = {
  __typename?: 'TemperatureAndHumidityDataResult';
  humidityData: Array<ChartDataResult>;
  temperatureData: Array<ChartDataResult>;
};

export type UserCreateInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  personalInformations: PersonalInformationInput;
  preferenceInformations: PreferenceInformationInput;
  role: Scalars['String']['input'];
  specificInformations: SpecificInformationInput;
};

export type UserResult = {
  __typename?: 'UserResult';
  items: Array<SUser>;
  nextPaginationToken?: Maybe<Scalars['String']['output']>;
};

export type UserUpdateInput = {
  email: Scalars['String']['input'];
  id: Scalars['String']['input'];
  personalInformations: PersonalInformationInput;
  preferenceInformations: PreferenceInformationInput;
  role: Scalars['String']['input'];
  specificInformations: SpecificInformationInput;
};

export type UsersInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextPaginationToken?: InputMaybe<Scalars['String']['input']>;
};

export type VirationDataResult = {
  __typename?: 'VirationDataResult';
  xRMSData: Array<ChartDataResult>;
  yRMSData: Array<ChartDataResult>;
  zRMSData: Array<ChartDataResult>;
};

export type ModuleQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ModuleQuery = { __typename?: 'Query', module: { __typename?: 'Module', id: string, name: string, latitude?: string | null, longitude?: string | null, description?: string | null, substationId: string, isDisabled: boolean, isDeleted: boolean, createdAt: any, updatedAt: any, substation: { __typename?: 'Substation', id: string, name: string }, _count: { __typename?: 'ModuleCount', sensors: number } } };

export type ModulesQueryVariables = Exact<{
  substationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<Array<ModuleOrderByWithRelationInput> | ModuleOrderByWithRelationInput>;
  take?: InputMaybe<Scalars['Float']['input']>;
  skip?: InputMaybe<Scalars['Float']['input']>;
}>;


export type ModulesQuery = { __typename?: 'Query', modules: { __typename?: 'ModuleResult', count: number, items: Array<{ __typename?: 'Module', id: string, name: string, latitude?: string | null, longitude?: string | null, description?: string | null, substationId: string, isDisabled: boolean, isDeleted: boolean, createdAt: any, updatedAt: any, substation: { __typename?: 'Substation', id: string, name: string }, _count: { __typename?: 'ModuleCount', sensors: number } }> } };

export type CreateModuleMutationVariables = Exact<{
  createModuleInput: ModuleUncheckedCreateInput;
}>;


export type CreateModuleMutation = { __typename?: 'Mutation', createModule: { __typename?: 'Module', id: string, name: string } };

export type UpdateModuleMutationVariables = Exact<{
  updateModuleInput: ModuleUncheckedUpdateInput;
  id: Scalars['String']['input'];
}>;


export type UpdateModuleMutation = { __typename?: 'Mutation', updateModule: { __typename?: 'Module', id: string, name: string } };

export type ChangeModuleDisabledStateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  isDisabled: Scalars['Boolean']['input'];
}>;


export type ChangeModuleDisabledStateMutation = { __typename?: 'Mutation', changeModuleDisabledState: { __typename?: 'Module', id: string, name: string, isDisabled: boolean } };

export type ChangeModuleDeletedStateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
}>;


export type ChangeModuleDeletedStateMutation = { __typename?: 'Mutation', changeModuleDeletedState: { __typename?: 'Module', id: string, name: string, isDeleted: boolean } };

export type DeleteModulePermanentlyMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteModulePermanentlyMutation = { __typename?: 'Mutation', deleteModulePermanently: { __typename?: 'Module', id: string, name: string } };

export type SensorDataQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type SensorDataQuery = { __typename?: 'Query', sensorData: { __typename?: 'SensorData', id: string, temperature: number, humidity: number, pressure: number, airQuality: number, gasPercentage: number, gasResistance: number, co2: number, xRMS: number, yRMS: number, zRMS: number, sensorId: string, isDisabled: boolean, isDeleted: boolean, createdAt: any, updatedAt: any, sensor: { __typename?: 'Sensor', id: string, name: string } } };

export type SensorDatasQueryVariables = Exact<{
  sensorId?: InputMaybe<Scalars['String']['input']>;
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<Array<SensorDataOrderByWithRelationInput> | SensorDataOrderByWithRelationInput>;
  take?: InputMaybe<Scalars['Float']['input']>;
  skip?: InputMaybe<Scalars['Float']['input']>;
}>;


export type SensorDatasQuery = { __typename?: 'Query', sensorDatas: { __typename?: 'SensorDataResult', count: number, items: Array<{ __typename?: 'SensorData', id: string, temperature: number, humidity: number, pressure: number, airQuality: number, gasPercentage: number, gasResistance: number, co2: number, xRMS: number, yRMS: number, zRMS: number, sensorId: string, isDisabled: boolean, isDeleted: boolean, createdAt: any, updatedAt: any, sensor: { __typename?: 'Sensor', id: string, name: string } }> } };

export type GetDatasForConsolidatedChartQueryVariables = Exact<{
  sensorId?: InputMaybe<Scalars['String']['input']>;
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  chartDataGroup?: InputMaybe<ChartDataGroupType>;
}>;


export type GetDatasForConsolidatedChartQuery = { __typename?: 'Query', getDatasForConsolidatedChart: { __typename?: 'ConsolidatedDataResult', pressureData: Array<{ __typename?: 'ChartDataResult', x: string, y: number, count: number }>, gasPercentageData: Array<{ __typename?: 'ChartDataResult', x: string, y: number, count: number }>, gasResistanceData: Array<{ __typename?: 'ChartDataResult', x: string, y: number, count: number }>, co2Data: Array<{ __typename?: 'ChartDataResult', x: string, y: number, count: number }> } };

export type GetDatasForTemperatureAndHumidityChartQueryVariables = Exact<{
  sensorId?: InputMaybe<Scalars['String']['input']>;
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  chartDataGroup?: InputMaybe<ChartDataGroupType>;
}>;


export type GetDatasForTemperatureAndHumidityChartQuery = { __typename?: 'Query', getDatasForTemperatureAndHumidityChart: { __typename?: 'TemperatureAndHumidityDataResult', temperatureData: Array<{ __typename?: 'ChartDataResult', x: string, y: number, count: number }>, humidityData: Array<{ __typename?: 'ChartDataResult', x: string, y: number, count: number }> } };

export type GetDatasForVibrationChartQueryVariables = Exact<{
  sensorId?: InputMaybe<Scalars['String']['input']>;
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  chartDataGroup?: InputMaybe<ChartDataGroupType>;
}>;


export type GetDatasForVibrationChartQuery = { __typename?: 'Query', getDatasForVibrationChart: { __typename?: 'VirationDataResult', xRMSData: Array<{ __typename?: 'ChartDataResult', x: string, y: number, count: number }>, yRMSData: Array<{ __typename?: 'ChartDataResult', x: string, y: number, count: number }>, zRMSData: Array<{ __typename?: 'ChartDataResult', x: string, y: number, count: number }> } };

export type GetDatasForAirQualityChartQueryVariables = Exact<{
  sensorId?: InputMaybe<Scalars['String']['input']>;
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  chartDataGroup?: InputMaybe<ChartDataGroupType>;
}>;


export type GetDatasForAirQualityChartQuery = { __typename?: 'Query', getDatasForAirQualityChart: { __typename?: 'AirQualityDataResult', airQualityData: Array<{ __typename?: 'ChartDataResult', x: string, y: number, count: number }> } };

export type CreateSensorDataMutationVariables = Exact<{
  createSensorDataInput: SensorDataUncheckedCreateInput;
}>;


export type CreateSensorDataMutation = { __typename?: 'Mutation', createSensorData: { __typename?: 'SensorData', id: string } };

export type UpdateSensorDataMutationVariables = Exact<{
  updateSensorDataInput: SensorDataUncheckedUpdateInput;
  id: Scalars['String']['input'];
}>;


export type UpdateSensorDataMutation = { __typename?: 'Mutation', updateSensorData: { __typename?: 'SensorData', id: string } };

export type ChangeSensorDataDisabledStateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  isDisabled: Scalars['Boolean']['input'];
}>;


export type ChangeSensorDataDisabledStateMutation = { __typename?: 'Mutation', changeSensorDataDisabledState: { __typename?: 'SensorData', id: string, isDisabled: boolean } };

export type ChangeSensorDataDeletedStateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
}>;


export type ChangeSensorDataDeletedStateMutation = { __typename?: 'Mutation', changeSensorDataDeletedState: { __typename?: 'SensorData', id: string, isDeleted: boolean } };

export type DeleteSensorDataPermanentlyMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteSensorDataPermanentlyMutation = { __typename?: 'Mutation', deleteSensorDataPermanently: { __typename?: 'SensorData', id: string } };

export type SensorQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type SensorQuery = { __typename?: 'Query', sensor: { __typename?: 'Sensor', id: string, name: string, description?: string | null, serialNumber: string, moduleId: string, isDisabled: boolean, isDeleted: boolean, createdAt: any, updatedAt: any, module: { __typename?: 'Module', id: string, name: string } } };

export type SensorsQueryVariables = Exact<{
  moduleId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<Array<SensorOrderByWithRelationInput> | SensorOrderByWithRelationInput>;
  take?: InputMaybe<Scalars['Float']['input']>;
  skip?: InputMaybe<Scalars['Float']['input']>;
}>;


export type SensorsQuery = { __typename?: 'Query', sensors: { __typename?: 'SensorResult', count: number, items: Array<{ __typename?: 'Sensor', id: string, name: string, description?: string | null, serialNumber: string, moduleId: string, isDisabled: boolean, isDeleted: boolean, createdAt: any, updatedAt: any, module: { __typename?: 'Module', id: string, name: string } }> } };

export type CreateSensorMutationVariables = Exact<{
  createSensorInput: SensorUncheckedCreateInput;
}>;


export type CreateSensorMutation = { __typename?: 'Mutation', createSensor: { __typename?: 'Sensor', id: string, name: string } };

export type UpdateSensorMutationVariables = Exact<{
  updateSensorInput: SensorUncheckedUpdateInput;
  id: Scalars['String']['input'];
}>;


export type UpdateSensorMutation = { __typename?: 'Mutation', updateSensor: { __typename?: 'Sensor', id: string, name: string } };

export type ChangeSensorDisabledStateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  isDisabled: Scalars['Boolean']['input'];
}>;


export type ChangeSensorDisabledStateMutation = { __typename?: 'Mutation', changeSensorDisabledState: { __typename?: 'Sensor', id: string, name: string, isDisabled: boolean } };

export type ChangeSensorDeletedStateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
}>;


export type ChangeSensorDeletedStateMutation = { __typename?: 'Mutation', changeSensorDeletedState: { __typename?: 'Sensor', id: string, name: string, isDeleted: boolean } };

export type DeleteSensorPermanentlyMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteSensorPermanentlyMutation = { __typename?: 'Mutation', deleteSensorPermanently: { __typename?: 'Sensor', id: string, name: string } };

export type SubstationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type SubstationQuery = { __typename?: 'Query', substation: { __typename?: 'Substation', id: string, name: string, description?: string | null, latitude?: string | null, longitude?: string | null, isDisabled: boolean, isDeleted: boolean, createdAt: any, updatedAt: any, _count: { __typename?: 'SubstationCount', modules: number } } };

export type SubstationsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<Array<SubstationOrderByWithRelationInput> | SubstationOrderByWithRelationInput>;
  take?: InputMaybe<Scalars['Float']['input']>;
  skip?: InputMaybe<Scalars['Float']['input']>;
}>;


export type SubstationsQuery = { __typename?: 'Query', substations: { __typename?: 'SubstationResult', count: number, items: Array<{ __typename?: 'Substation', id: string, name: string, description?: string | null, latitude?: string | null, longitude?: string | null, isDisabled: boolean, isDeleted: boolean, createdAt: any, updatedAt: any, _count: { __typename?: 'SubstationCount', modules: number } }> } };

export type CreateSubstationMutationVariables = Exact<{
  createSubstationInput: SubstationCreateInput;
}>;


export type CreateSubstationMutation = { __typename?: 'Mutation', createSubstation: { __typename?: 'Substation', id: string, name: string } };

export type UpdateSubstationMutationVariables = Exact<{
  updateSubstationInput: SubstationUpdateInput;
  id: Scalars['String']['input'];
}>;


export type UpdateSubstationMutation = { __typename?: 'Mutation', updateSubstation: { __typename?: 'Substation', id: string, name: string } };

export type ChangeSubstationDisabledStateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  isDisabled: Scalars['Boolean']['input'];
}>;


export type ChangeSubstationDisabledStateMutation = { __typename?: 'Mutation', changeSubstationDisabledState: { __typename?: 'Substation', id: string, name: string, isDisabled: boolean } };

export type ChangeSubstationDeletedStateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  isDeleted: Scalars['Boolean']['input'];
}>;


export type ChangeSubstationDeletedStateMutation = { __typename?: 'Mutation', changeSubstationDeletedState: { __typename?: 'Substation', id: string, name: string, isDeleted: boolean } };

export type DeleteSubstationPermanentlyMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteSubstationPermanentlyMutation = { __typename?: 'Mutation', deleteSubstationPermanently: { __typename?: 'Substation', id: string, name: string } };

export type MeMutationVariables = Exact<{ [key: string]: never; }>;


export type MeMutation = { __typename?: 'Mutation', me: { __typename?: 'SUser', id: string, email: string, personalInformations?: { __typename?: 'PersonalInformationType', firstName: string, lastName: string, fullName: string, title: string } | null, preferenceInformations?: { __typename?: 'PreferenceInformation', theme: string } | null, specificInformations?: { __typename?: 'SpecificInformation', isDisabled: boolean, isDeleted: boolean, createdAt: string, updatedAt: string } | null } };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UserResult', nextPaginationToken?: string | null, items: Array<{ __typename?: 'SUser', id: string, email: string, personalInformations?: { __typename?: 'PersonalInformationType', firstName: string, fullName: string, lastName: string, title: string } | null }> } };

export type GetUserByIdMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetUserByIdMutation = { __typename?: 'Mutation', getUserById?: { __typename?: 'SUser', id: string, email: string, personalInformations?: { __typename?: 'PersonalInformationType', firstName: string, lastName: string, fullName: string, title: string } | null, preferenceInformations?: { __typename?: 'PreferenceInformation', theme: string } | null, specificInformations?: { __typename?: 'SpecificInformation', isDisabled: boolean, isDeleted: boolean, createdAt: string, updatedAt: string } | null } | null };

export type CreateUserMutationVariables = Exact<{
  createUserInput: UserCreateInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'SUser', id: string, email: string, personalInformations?: { __typename?: 'PersonalInformationType', firstName: string } | null } };

export type UpdateUserMutationVariables = Exact<{
  updateUserInput: UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'SUser', id: string, email: string, personalInformations?: { __typename?: 'PersonalInformationType', firstName: string, lastName: string, fullName: string, title: string } | null } };

export type DestroyUserAccountMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DestroyUserAccountMutation = { __typename?: 'Mutation', destroyUserAccount: { __typename?: 'SUser', id: string, email: string, personalInformations?: { __typename?: 'PersonalInformationType', firstName: string, lastName: string, fullName: string, title: string } | null } };


export const ModuleDocument = gql`
    query Module($id: String!) {
  module(id: $id) {
    id
    name
    latitude
    longitude
    description
    substationId
    substation {
      id
      name
    }
    isDisabled
    isDeleted
    createdAt
    updatedAt
    _count {
      sensors
    }
  }
}
    `;

/**
 * __useModuleQuery__
 *
 * To run a query within a React component, call `useModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModuleQuery(baseOptions: Apollo.QueryHookOptions<ModuleQuery, ModuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModuleQuery, ModuleQueryVariables>(ModuleDocument, options);
      }
export function useModuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModuleQuery, ModuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModuleQuery, ModuleQueryVariables>(ModuleDocument, options);
        }
export type ModuleQueryHookResult = ReturnType<typeof useModuleQuery>;
export type ModuleLazyQueryHookResult = ReturnType<typeof useModuleLazyQuery>;
export type ModuleQueryResult = Apollo.QueryResult<ModuleQuery, ModuleQueryVariables>;
export const ModulesDocument = gql`
    query Modules($substationId: String, $name: String, $isDisabled: Boolean, $orderBy: [ModuleOrderByWithRelationInput!], $take: Float, $skip: Float) {
  modules(
    substationId: $substationId
    name: $name
    isDisabled: $isDisabled
    orderBy: $orderBy
    take: $take
    skip: $skip
  ) {
    count
    items {
      id
      name
      latitude
      longitude
      description
      substationId
      substation {
        id
        name
      }
      isDisabled
      isDeleted
      createdAt
      updatedAt
      _count {
        sensors
      }
    }
  }
}
    `;

/**
 * __useModulesQuery__
 *
 * To run a query within a React component, call `useModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModulesQuery({
 *   variables: {
 *      substationId: // value for 'substationId'
 *      name: // value for 'name'
 *      isDisabled: // value for 'isDisabled'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useModulesQuery(baseOptions?: Apollo.QueryHookOptions<ModulesQuery, ModulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModulesQuery, ModulesQueryVariables>(ModulesDocument, options);
      }
export function useModulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModulesQuery, ModulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModulesQuery, ModulesQueryVariables>(ModulesDocument, options);
        }
export type ModulesQueryHookResult = ReturnType<typeof useModulesQuery>;
export type ModulesLazyQueryHookResult = ReturnType<typeof useModulesLazyQuery>;
export type ModulesQueryResult = Apollo.QueryResult<ModulesQuery, ModulesQueryVariables>;
export const CreateModuleDocument = gql`
    mutation CreateModule($createModuleInput: ModuleUncheckedCreateInput!) {
  createModule(createModuleInput: $createModuleInput) {
    id
    name
  }
}
    `;
export type CreateModuleMutationFn = Apollo.MutationFunction<CreateModuleMutation, CreateModuleMutationVariables>;

/**
 * __useCreateModuleMutation__
 *
 * To run a mutation, you first call `useCreateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModuleMutation, { data, loading, error }] = useCreateModuleMutation({
 *   variables: {
 *      createModuleInput: // value for 'createModuleInput'
 *   },
 * });
 */
export function useCreateModuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateModuleMutation, CreateModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateModuleMutation, CreateModuleMutationVariables>(CreateModuleDocument, options);
      }
export type CreateModuleMutationHookResult = ReturnType<typeof useCreateModuleMutation>;
export type CreateModuleMutationResult = Apollo.MutationResult<CreateModuleMutation>;
export type CreateModuleMutationOptions = Apollo.BaseMutationOptions<CreateModuleMutation, CreateModuleMutationVariables>;
export const UpdateModuleDocument = gql`
    mutation UpdateModule($updateModuleInput: ModuleUncheckedUpdateInput!, $id: String!) {
  updateModule(updateModuleInput: $updateModuleInput, id: $id) {
    id
    name
  }
}
    `;
export type UpdateModuleMutationFn = Apollo.MutationFunction<UpdateModuleMutation, UpdateModuleMutationVariables>;

/**
 * __useUpdateModuleMutation__
 *
 * To run a mutation, you first call `useUpdateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleMutation, { data, loading, error }] = useUpdateModuleMutation({
 *   variables: {
 *      updateModuleInput: // value for 'updateModuleInput'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateModuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModuleMutation, UpdateModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModuleMutation, UpdateModuleMutationVariables>(UpdateModuleDocument, options);
      }
export type UpdateModuleMutationHookResult = ReturnType<typeof useUpdateModuleMutation>;
export type UpdateModuleMutationResult = Apollo.MutationResult<UpdateModuleMutation>;
export type UpdateModuleMutationOptions = Apollo.BaseMutationOptions<UpdateModuleMutation, UpdateModuleMutationVariables>;
export const ChangeModuleDisabledStateDocument = gql`
    mutation ChangeModuleDisabledState($id: String!, $isDisabled: Boolean!) {
  changeModuleDisabledState(id: $id, isDisabled: $isDisabled) {
    id
    name
    isDisabled
  }
}
    `;
export type ChangeModuleDisabledStateMutationFn = Apollo.MutationFunction<ChangeModuleDisabledStateMutation, ChangeModuleDisabledStateMutationVariables>;

/**
 * __useChangeModuleDisabledStateMutation__
 *
 * To run a mutation, you first call `useChangeModuleDisabledStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeModuleDisabledStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeModuleDisabledStateMutation, { data, loading, error }] = useChangeModuleDisabledStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isDisabled: // value for 'isDisabled'
 *   },
 * });
 */
export function useChangeModuleDisabledStateMutation(baseOptions?: Apollo.MutationHookOptions<ChangeModuleDisabledStateMutation, ChangeModuleDisabledStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeModuleDisabledStateMutation, ChangeModuleDisabledStateMutationVariables>(ChangeModuleDisabledStateDocument, options);
      }
export type ChangeModuleDisabledStateMutationHookResult = ReturnType<typeof useChangeModuleDisabledStateMutation>;
export type ChangeModuleDisabledStateMutationResult = Apollo.MutationResult<ChangeModuleDisabledStateMutation>;
export type ChangeModuleDisabledStateMutationOptions = Apollo.BaseMutationOptions<ChangeModuleDisabledStateMutation, ChangeModuleDisabledStateMutationVariables>;
export const ChangeModuleDeletedStateDocument = gql`
    mutation ChangeModuleDeletedState($id: String!, $isDeleted: Boolean!) {
  changeModuleDeletedState(id: $id, isDeleted: $isDeleted) {
    id
    name
    isDeleted
  }
}
    `;
export type ChangeModuleDeletedStateMutationFn = Apollo.MutationFunction<ChangeModuleDeletedStateMutation, ChangeModuleDeletedStateMutationVariables>;

/**
 * __useChangeModuleDeletedStateMutation__
 *
 * To run a mutation, you first call `useChangeModuleDeletedStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeModuleDeletedStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeModuleDeletedStateMutation, { data, loading, error }] = useChangeModuleDeletedStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isDeleted: // value for 'isDeleted'
 *   },
 * });
 */
export function useChangeModuleDeletedStateMutation(baseOptions?: Apollo.MutationHookOptions<ChangeModuleDeletedStateMutation, ChangeModuleDeletedStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeModuleDeletedStateMutation, ChangeModuleDeletedStateMutationVariables>(ChangeModuleDeletedStateDocument, options);
      }
export type ChangeModuleDeletedStateMutationHookResult = ReturnType<typeof useChangeModuleDeletedStateMutation>;
export type ChangeModuleDeletedStateMutationResult = Apollo.MutationResult<ChangeModuleDeletedStateMutation>;
export type ChangeModuleDeletedStateMutationOptions = Apollo.BaseMutationOptions<ChangeModuleDeletedStateMutation, ChangeModuleDeletedStateMutationVariables>;
export const DeleteModulePermanentlyDocument = gql`
    mutation DeleteModulePermanently($id: String!) {
  deleteModulePermanently(id: $id) {
    id
    name
  }
}
    `;
export type DeleteModulePermanentlyMutationFn = Apollo.MutationFunction<DeleteModulePermanentlyMutation, DeleteModulePermanentlyMutationVariables>;

/**
 * __useDeleteModulePermanentlyMutation__
 *
 * To run a mutation, you first call `useDeleteModulePermanentlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModulePermanentlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModulePermanentlyMutation, { data, loading, error }] = useDeleteModulePermanentlyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteModulePermanentlyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteModulePermanentlyMutation, DeleteModulePermanentlyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteModulePermanentlyMutation, DeleteModulePermanentlyMutationVariables>(DeleteModulePermanentlyDocument, options);
      }
export type DeleteModulePermanentlyMutationHookResult = ReturnType<typeof useDeleteModulePermanentlyMutation>;
export type DeleteModulePermanentlyMutationResult = Apollo.MutationResult<DeleteModulePermanentlyMutation>;
export type DeleteModulePermanentlyMutationOptions = Apollo.BaseMutationOptions<DeleteModulePermanentlyMutation, DeleteModulePermanentlyMutationVariables>;
export const SensorDataDocument = gql`
    query SensorData($id: String!) {
  sensorData(id: $id) {
    id
    temperature
    humidity
    pressure
    airQuality
    gasPercentage
    gasResistance
    co2
    xRMS
    yRMS
    zRMS
    sensorId
    sensor {
      id
      name
    }
    isDisabled
    isDeleted
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useSensorDataQuery__
 *
 * To run a query within a React component, call `useSensorDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSensorDataQuery(baseOptions: Apollo.QueryHookOptions<SensorDataQuery, SensorDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SensorDataQuery, SensorDataQueryVariables>(SensorDataDocument, options);
      }
export function useSensorDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorDataQuery, SensorDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SensorDataQuery, SensorDataQueryVariables>(SensorDataDocument, options);
        }
export type SensorDataQueryHookResult = ReturnType<typeof useSensorDataQuery>;
export type SensorDataLazyQueryHookResult = ReturnType<typeof useSensorDataLazyQuery>;
export type SensorDataQueryResult = Apollo.QueryResult<SensorDataQuery, SensorDataQueryVariables>;
export const SensorDatasDocument = gql`
    query SensorDatas($sensorId: String, $isDisabled: Boolean, $orderBy: [SensorDataOrderByWithRelationInput!], $take: Float, $skip: Float) {
  sensorDatas(
    sensorId: $sensorId
    isDisabled: $isDisabled
    orderBy: $orderBy
    take: $take
    skip: $skip
  ) {
    count
    items {
      id
      temperature
      humidity
      pressure
      airQuality
      gasPercentage
      gasResistance
      co2
      xRMS
      yRMS
      zRMS
      sensorId
      sensor {
        id
        name
      }
      isDisabled
      isDeleted
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useSensorDatasQuery__
 *
 * To run a query within a React component, call `useSensorDatasQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorDatasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorDatasQuery({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *      isDisabled: // value for 'isDisabled'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useSensorDatasQuery(baseOptions?: Apollo.QueryHookOptions<SensorDatasQuery, SensorDatasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SensorDatasQuery, SensorDatasQueryVariables>(SensorDatasDocument, options);
      }
export function useSensorDatasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorDatasQuery, SensorDatasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SensorDatasQuery, SensorDatasQueryVariables>(SensorDatasDocument, options);
        }
export type SensorDatasQueryHookResult = ReturnType<typeof useSensorDatasQuery>;
export type SensorDatasLazyQueryHookResult = ReturnType<typeof useSensorDatasLazyQuery>;
export type SensorDatasQueryResult = Apollo.QueryResult<SensorDatasQuery, SensorDatasQueryVariables>;
export const GetDatasForConsolidatedChartDocument = gql`
    query getDatasForConsolidatedChart($sensorId: String, $isDisabled: Boolean, $chartDataGroup: ChartDataGroupType) {
  getDatasForConsolidatedChart(
    sensorId: $sensorId
    isDisabled: $isDisabled
    chartDataGroup: $chartDataGroup
  ) {
    pressureData {
      x
      y
      count
    }
    gasPercentageData {
      x
      y
      count
    }
    gasResistanceData {
      x
      y
      count
    }
    co2Data {
      x
      y
      count
    }
  }
}
    `;

/**
 * __useGetDatasForConsolidatedChartQuery__
 *
 * To run a query within a React component, call `useGetDatasForConsolidatedChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasForConsolidatedChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasForConsolidatedChartQuery({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *      isDisabled: // value for 'isDisabled'
 *      chartDataGroup: // value for 'chartDataGroup'
 *   },
 * });
 */
export function useGetDatasForConsolidatedChartQuery(baseOptions?: Apollo.QueryHookOptions<GetDatasForConsolidatedChartQuery, GetDatasForConsolidatedChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatasForConsolidatedChartQuery, GetDatasForConsolidatedChartQueryVariables>(GetDatasForConsolidatedChartDocument, options);
      }
export function useGetDatasForConsolidatedChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatasForConsolidatedChartQuery, GetDatasForConsolidatedChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatasForConsolidatedChartQuery, GetDatasForConsolidatedChartQueryVariables>(GetDatasForConsolidatedChartDocument, options);
        }
export type GetDatasForConsolidatedChartQueryHookResult = ReturnType<typeof useGetDatasForConsolidatedChartQuery>;
export type GetDatasForConsolidatedChartLazyQueryHookResult = ReturnType<typeof useGetDatasForConsolidatedChartLazyQuery>;
export type GetDatasForConsolidatedChartQueryResult = Apollo.QueryResult<GetDatasForConsolidatedChartQuery, GetDatasForConsolidatedChartQueryVariables>;
export const GetDatasForTemperatureAndHumidityChartDocument = gql`
    query getDatasForTemperatureAndHumidityChart($sensorId: String, $isDisabled: Boolean, $chartDataGroup: ChartDataGroupType) {
  getDatasForTemperatureAndHumidityChart(
    sensorId: $sensorId
    isDisabled: $isDisabled
    chartDataGroup: $chartDataGroup
  ) {
    temperatureData {
      x
      y
      count
    }
    humidityData {
      x
      y
      count
    }
  }
}
    `;

/**
 * __useGetDatasForTemperatureAndHumidityChartQuery__
 *
 * To run a query within a React component, call `useGetDatasForTemperatureAndHumidityChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasForTemperatureAndHumidityChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasForTemperatureAndHumidityChartQuery({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *      isDisabled: // value for 'isDisabled'
 *      chartDataGroup: // value for 'chartDataGroup'
 *   },
 * });
 */
export function useGetDatasForTemperatureAndHumidityChartQuery(baseOptions?: Apollo.QueryHookOptions<GetDatasForTemperatureAndHumidityChartQuery, GetDatasForTemperatureAndHumidityChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatasForTemperatureAndHumidityChartQuery, GetDatasForTemperatureAndHumidityChartQueryVariables>(GetDatasForTemperatureAndHumidityChartDocument, options);
      }
export function useGetDatasForTemperatureAndHumidityChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatasForTemperatureAndHumidityChartQuery, GetDatasForTemperatureAndHumidityChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatasForTemperatureAndHumidityChartQuery, GetDatasForTemperatureAndHumidityChartQueryVariables>(GetDatasForTemperatureAndHumidityChartDocument, options);
        }
export type GetDatasForTemperatureAndHumidityChartQueryHookResult = ReturnType<typeof useGetDatasForTemperatureAndHumidityChartQuery>;
export type GetDatasForTemperatureAndHumidityChartLazyQueryHookResult = ReturnType<typeof useGetDatasForTemperatureAndHumidityChartLazyQuery>;
export type GetDatasForTemperatureAndHumidityChartQueryResult = Apollo.QueryResult<GetDatasForTemperatureAndHumidityChartQuery, GetDatasForTemperatureAndHumidityChartQueryVariables>;
export const GetDatasForVibrationChartDocument = gql`
    query getDatasForVibrationChart($sensorId: String, $isDisabled: Boolean, $chartDataGroup: ChartDataGroupType) {
  getDatasForVibrationChart(
    sensorId: $sensorId
    isDisabled: $isDisabled
    chartDataGroup: $chartDataGroup
  ) {
    xRMSData {
      x
      y
      count
    }
    yRMSData {
      x
      y
      count
    }
    zRMSData {
      x
      y
      count
    }
  }
}
    `;

/**
 * __useGetDatasForVibrationChartQuery__
 *
 * To run a query within a React component, call `useGetDatasForVibrationChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasForVibrationChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasForVibrationChartQuery({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *      isDisabled: // value for 'isDisabled'
 *      chartDataGroup: // value for 'chartDataGroup'
 *   },
 * });
 */
export function useGetDatasForVibrationChartQuery(baseOptions?: Apollo.QueryHookOptions<GetDatasForVibrationChartQuery, GetDatasForVibrationChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatasForVibrationChartQuery, GetDatasForVibrationChartQueryVariables>(GetDatasForVibrationChartDocument, options);
      }
export function useGetDatasForVibrationChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatasForVibrationChartQuery, GetDatasForVibrationChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatasForVibrationChartQuery, GetDatasForVibrationChartQueryVariables>(GetDatasForVibrationChartDocument, options);
        }
export type GetDatasForVibrationChartQueryHookResult = ReturnType<typeof useGetDatasForVibrationChartQuery>;
export type GetDatasForVibrationChartLazyQueryHookResult = ReturnType<typeof useGetDatasForVibrationChartLazyQuery>;
export type GetDatasForVibrationChartQueryResult = Apollo.QueryResult<GetDatasForVibrationChartQuery, GetDatasForVibrationChartQueryVariables>;
export const GetDatasForAirQualityChartDocument = gql`
    query getDatasForAirQualityChart($sensorId: String, $isDisabled: Boolean, $chartDataGroup: ChartDataGroupType) {
  getDatasForAirQualityChart(
    sensorId: $sensorId
    isDisabled: $isDisabled
    chartDataGroup: $chartDataGroup
  ) {
    airQualityData {
      x
      y
      count
    }
  }
}
    `;

/**
 * __useGetDatasForAirQualityChartQuery__
 *
 * To run a query within a React component, call `useGetDatasForAirQualityChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasForAirQualityChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasForAirQualityChartQuery({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *      isDisabled: // value for 'isDisabled'
 *      chartDataGroup: // value for 'chartDataGroup'
 *   },
 * });
 */
export function useGetDatasForAirQualityChartQuery(baseOptions?: Apollo.QueryHookOptions<GetDatasForAirQualityChartQuery, GetDatasForAirQualityChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatasForAirQualityChartQuery, GetDatasForAirQualityChartQueryVariables>(GetDatasForAirQualityChartDocument, options);
      }
export function useGetDatasForAirQualityChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatasForAirQualityChartQuery, GetDatasForAirQualityChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatasForAirQualityChartQuery, GetDatasForAirQualityChartQueryVariables>(GetDatasForAirQualityChartDocument, options);
        }
export type GetDatasForAirQualityChartQueryHookResult = ReturnType<typeof useGetDatasForAirQualityChartQuery>;
export type GetDatasForAirQualityChartLazyQueryHookResult = ReturnType<typeof useGetDatasForAirQualityChartLazyQuery>;
export type GetDatasForAirQualityChartQueryResult = Apollo.QueryResult<GetDatasForAirQualityChartQuery, GetDatasForAirQualityChartQueryVariables>;
export const CreateSensorDataDocument = gql`
    mutation CreateSensorData($createSensorDataInput: SensorDataUncheckedCreateInput!) {
  createSensorData(createSensorDataInput: $createSensorDataInput) {
    id
  }
}
    `;
export type CreateSensorDataMutationFn = Apollo.MutationFunction<CreateSensorDataMutation, CreateSensorDataMutationVariables>;

/**
 * __useCreateSensorDataMutation__
 *
 * To run a mutation, you first call `useCreateSensorDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSensorDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSensorDataMutation, { data, loading, error }] = useCreateSensorDataMutation({
 *   variables: {
 *      createSensorDataInput: // value for 'createSensorDataInput'
 *   },
 * });
 */
export function useCreateSensorDataMutation(baseOptions?: Apollo.MutationHookOptions<CreateSensorDataMutation, CreateSensorDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSensorDataMutation, CreateSensorDataMutationVariables>(CreateSensorDataDocument, options);
      }
export type CreateSensorDataMutationHookResult = ReturnType<typeof useCreateSensorDataMutation>;
export type CreateSensorDataMutationResult = Apollo.MutationResult<CreateSensorDataMutation>;
export type CreateSensorDataMutationOptions = Apollo.BaseMutationOptions<CreateSensorDataMutation, CreateSensorDataMutationVariables>;
export const UpdateSensorDataDocument = gql`
    mutation UpdateSensorData($updateSensorDataInput: SensorDataUncheckedUpdateInput!, $id: String!) {
  updateSensorData(updateSensorDataInput: $updateSensorDataInput, id: $id) {
    id
  }
}
    `;
export type UpdateSensorDataMutationFn = Apollo.MutationFunction<UpdateSensorDataMutation, UpdateSensorDataMutationVariables>;

/**
 * __useUpdateSensorDataMutation__
 *
 * To run a mutation, you first call `useUpdateSensorDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSensorDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSensorDataMutation, { data, loading, error }] = useUpdateSensorDataMutation({
 *   variables: {
 *      updateSensorDataInput: // value for 'updateSensorDataInput'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSensorDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSensorDataMutation, UpdateSensorDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSensorDataMutation, UpdateSensorDataMutationVariables>(UpdateSensorDataDocument, options);
      }
export type UpdateSensorDataMutationHookResult = ReturnType<typeof useUpdateSensorDataMutation>;
export type UpdateSensorDataMutationResult = Apollo.MutationResult<UpdateSensorDataMutation>;
export type UpdateSensorDataMutationOptions = Apollo.BaseMutationOptions<UpdateSensorDataMutation, UpdateSensorDataMutationVariables>;
export const ChangeSensorDataDisabledStateDocument = gql`
    mutation ChangeSensorDataDisabledState($id: String!, $isDisabled: Boolean!) {
  changeSensorDataDisabledState(id: $id, isDisabled: $isDisabled) {
    id
    isDisabled
  }
}
    `;
export type ChangeSensorDataDisabledStateMutationFn = Apollo.MutationFunction<ChangeSensorDataDisabledStateMutation, ChangeSensorDataDisabledStateMutationVariables>;

/**
 * __useChangeSensorDataDisabledStateMutation__
 *
 * To run a mutation, you first call `useChangeSensorDataDisabledStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSensorDataDisabledStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSensorDataDisabledStateMutation, { data, loading, error }] = useChangeSensorDataDisabledStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isDisabled: // value for 'isDisabled'
 *   },
 * });
 */
export function useChangeSensorDataDisabledStateMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSensorDataDisabledStateMutation, ChangeSensorDataDisabledStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeSensorDataDisabledStateMutation, ChangeSensorDataDisabledStateMutationVariables>(ChangeSensorDataDisabledStateDocument, options);
      }
export type ChangeSensorDataDisabledStateMutationHookResult = ReturnType<typeof useChangeSensorDataDisabledStateMutation>;
export type ChangeSensorDataDisabledStateMutationResult = Apollo.MutationResult<ChangeSensorDataDisabledStateMutation>;
export type ChangeSensorDataDisabledStateMutationOptions = Apollo.BaseMutationOptions<ChangeSensorDataDisabledStateMutation, ChangeSensorDataDisabledStateMutationVariables>;
export const ChangeSensorDataDeletedStateDocument = gql`
    mutation ChangeSensorDataDeletedState($id: String!, $isDeleted: Boolean!) {
  changeSensorDataDeletedState(id: $id, isDeleted: $isDeleted) {
    id
    isDeleted
  }
}
    `;
export type ChangeSensorDataDeletedStateMutationFn = Apollo.MutationFunction<ChangeSensorDataDeletedStateMutation, ChangeSensorDataDeletedStateMutationVariables>;

/**
 * __useChangeSensorDataDeletedStateMutation__
 *
 * To run a mutation, you first call `useChangeSensorDataDeletedStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSensorDataDeletedStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSensorDataDeletedStateMutation, { data, loading, error }] = useChangeSensorDataDeletedStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isDeleted: // value for 'isDeleted'
 *   },
 * });
 */
export function useChangeSensorDataDeletedStateMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSensorDataDeletedStateMutation, ChangeSensorDataDeletedStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeSensorDataDeletedStateMutation, ChangeSensorDataDeletedStateMutationVariables>(ChangeSensorDataDeletedStateDocument, options);
      }
export type ChangeSensorDataDeletedStateMutationHookResult = ReturnType<typeof useChangeSensorDataDeletedStateMutation>;
export type ChangeSensorDataDeletedStateMutationResult = Apollo.MutationResult<ChangeSensorDataDeletedStateMutation>;
export type ChangeSensorDataDeletedStateMutationOptions = Apollo.BaseMutationOptions<ChangeSensorDataDeletedStateMutation, ChangeSensorDataDeletedStateMutationVariables>;
export const DeleteSensorDataPermanentlyDocument = gql`
    mutation DeleteSensorDataPermanently($id: String!) {
  deleteSensorDataPermanently(id: $id) {
    id
  }
}
    `;
export type DeleteSensorDataPermanentlyMutationFn = Apollo.MutationFunction<DeleteSensorDataPermanentlyMutation, DeleteSensorDataPermanentlyMutationVariables>;

/**
 * __useDeleteSensorDataPermanentlyMutation__
 *
 * To run a mutation, you first call `useDeleteSensorDataPermanentlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSensorDataPermanentlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSensorDataPermanentlyMutation, { data, loading, error }] = useDeleteSensorDataPermanentlyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSensorDataPermanentlyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSensorDataPermanentlyMutation, DeleteSensorDataPermanentlyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSensorDataPermanentlyMutation, DeleteSensorDataPermanentlyMutationVariables>(DeleteSensorDataPermanentlyDocument, options);
      }
export type DeleteSensorDataPermanentlyMutationHookResult = ReturnType<typeof useDeleteSensorDataPermanentlyMutation>;
export type DeleteSensorDataPermanentlyMutationResult = Apollo.MutationResult<DeleteSensorDataPermanentlyMutation>;
export type DeleteSensorDataPermanentlyMutationOptions = Apollo.BaseMutationOptions<DeleteSensorDataPermanentlyMutation, DeleteSensorDataPermanentlyMutationVariables>;
export const SensorDocument = gql`
    query Sensor($id: String!) {
  sensor(id: $id) {
    id
    name
    description
    serialNumber
    moduleId
    module {
      id
      name
    }
    isDisabled
    isDeleted
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useSensorQuery__
 *
 * To run a query within a React component, call `useSensorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSensorQuery(baseOptions: Apollo.QueryHookOptions<SensorQuery, SensorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SensorQuery, SensorQueryVariables>(SensorDocument, options);
      }
export function useSensorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorQuery, SensorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SensorQuery, SensorQueryVariables>(SensorDocument, options);
        }
export type SensorQueryHookResult = ReturnType<typeof useSensorQuery>;
export type SensorLazyQueryHookResult = ReturnType<typeof useSensorLazyQuery>;
export type SensorQueryResult = Apollo.QueryResult<SensorQuery, SensorQueryVariables>;
export const SensorsDocument = gql`
    query Sensors($moduleId: String, $name: String, $isDisabled: Boolean, $orderBy: [SensorOrderByWithRelationInput!], $take: Float, $skip: Float) {
  sensors(
    moduleId: $moduleId
    name: $name
    isDisabled: $isDisabled
    orderBy: $orderBy
    take: $take
    skip: $skip
  ) {
    count
    items {
      id
      name
      description
      serialNumber
      moduleId
      module {
        id
        name
      }
      isDisabled
      isDeleted
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useSensorsQuery__
 *
 * To run a query within a React component, call `useSensorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorsQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *      name: // value for 'name'
 *      isDisabled: // value for 'isDisabled'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useSensorsQuery(baseOptions?: Apollo.QueryHookOptions<SensorsQuery, SensorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SensorsQuery, SensorsQueryVariables>(SensorsDocument, options);
      }
export function useSensorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SensorsQuery, SensorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SensorsQuery, SensorsQueryVariables>(SensorsDocument, options);
        }
export type SensorsQueryHookResult = ReturnType<typeof useSensorsQuery>;
export type SensorsLazyQueryHookResult = ReturnType<typeof useSensorsLazyQuery>;
export type SensorsQueryResult = Apollo.QueryResult<SensorsQuery, SensorsQueryVariables>;
export const CreateSensorDocument = gql`
    mutation CreateSensor($createSensorInput: SensorUncheckedCreateInput!) {
  createSensor(createSensorInput: $createSensorInput) {
    id
    name
  }
}
    `;
export type CreateSensorMutationFn = Apollo.MutationFunction<CreateSensorMutation, CreateSensorMutationVariables>;

/**
 * __useCreateSensorMutation__
 *
 * To run a mutation, you first call `useCreateSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSensorMutation, { data, loading, error }] = useCreateSensorMutation({
 *   variables: {
 *      createSensorInput: // value for 'createSensorInput'
 *   },
 * });
 */
export function useCreateSensorMutation(baseOptions?: Apollo.MutationHookOptions<CreateSensorMutation, CreateSensorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSensorMutation, CreateSensorMutationVariables>(CreateSensorDocument, options);
      }
export type CreateSensorMutationHookResult = ReturnType<typeof useCreateSensorMutation>;
export type CreateSensorMutationResult = Apollo.MutationResult<CreateSensorMutation>;
export type CreateSensorMutationOptions = Apollo.BaseMutationOptions<CreateSensorMutation, CreateSensorMutationVariables>;
export const UpdateSensorDocument = gql`
    mutation UpdateSensor($updateSensorInput: SensorUncheckedUpdateInput!, $id: String!) {
  updateSensor(updateSensorInput: $updateSensorInput, id: $id) {
    id
    name
  }
}
    `;
export type UpdateSensorMutationFn = Apollo.MutationFunction<UpdateSensorMutation, UpdateSensorMutationVariables>;

/**
 * __useUpdateSensorMutation__
 *
 * To run a mutation, you first call `useUpdateSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSensorMutation, { data, loading, error }] = useUpdateSensorMutation({
 *   variables: {
 *      updateSensorInput: // value for 'updateSensorInput'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSensorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSensorMutation, UpdateSensorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSensorMutation, UpdateSensorMutationVariables>(UpdateSensorDocument, options);
      }
export type UpdateSensorMutationHookResult = ReturnType<typeof useUpdateSensorMutation>;
export type UpdateSensorMutationResult = Apollo.MutationResult<UpdateSensorMutation>;
export type UpdateSensorMutationOptions = Apollo.BaseMutationOptions<UpdateSensorMutation, UpdateSensorMutationVariables>;
export const ChangeSensorDisabledStateDocument = gql`
    mutation ChangeSensorDisabledState($id: String!, $isDisabled: Boolean!) {
  changeSensorDisabledState(id: $id, isDisabled: $isDisabled) {
    id
    name
    isDisabled
  }
}
    `;
export type ChangeSensorDisabledStateMutationFn = Apollo.MutationFunction<ChangeSensorDisabledStateMutation, ChangeSensorDisabledStateMutationVariables>;

/**
 * __useChangeSensorDisabledStateMutation__
 *
 * To run a mutation, you first call `useChangeSensorDisabledStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSensorDisabledStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSensorDisabledStateMutation, { data, loading, error }] = useChangeSensorDisabledStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isDisabled: // value for 'isDisabled'
 *   },
 * });
 */
export function useChangeSensorDisabledStateMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSensorDisabledStateMutation, ChangeSensorDisabledStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeSensorDisabledStateMutation, ChangeSensorDisabledStateMutationVariables>(ChangeSensorDisabledStateDocument, options);
      }
export type ChangeSensorDisabledStateMutationHookResult = ReturnType<typeof useChangeSensorDisabledStateMutation>;
export type ChangeSensorDisabledStateMutationResult = Apollo.MutationResult<ChangeSensorDisabledStateMutation>;
export type ChangeSensorDisabledStateMutationOptions = Apollo.BaseMutationOptions<ChangeSensorDisabledStateMutation, ChangeSensorDisabledStateMutationVariables>;
export const ChangeSensorDeletedStateDocument = gql`
    mutation ChangeSensorDeletedState($id: String!, $isDeleted: Boolean!) {
  changeSensorDeletedState(id: $id, isDeleted: $isDeleted) {
    id
    name
    isDeleted
  }
}
    `;
export type ChangeSensorDeletedStateMutationFn = Apollo.MutationFunction<ChangeSensorDeletedStateMutation, ChangeSensorDeletedStateMutationVariables>;

/**
 * __useChangeSensorDeletedStateMutation__
 *
 * To run a mutation, you first call `useChangeSensorDeletedStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSensorDeletedStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSensorDeletedStateMutation, { data, loading, error }] = useChangeSensorDeletedStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isDeleted: // value for 'isDeleted'
 *   },
 * });
 */
export function useChangeSensorDeletedStateMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSensorDeletedStateMutation, ChangeSensorDeletedStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeSensorDeletedStateMutation, ChangeSensorDeletedStateMutationVariables>(ChangeSensorDeletedStateDocument, options);
      }
export type ChangeSensorDeletedStateMutationHookResult = ReturnType<typeof useChangeSensorDeletedStateMutation>;
export type ChangeSensorDeletedStateMutationResult = Apollo.MutationResult<ChangeSensorDeletedStateMutation>;
export type ChangeSensorDeletedStateMutationOptions = Apollo.BaseMutationOptions<ChangeSensorDeletedStateMutation, ChangeSensorDeletedStateMutationVariables>;
export const DeleteSensorPermanentlyDocument = gql`
    mutation DeleteSensorPermanently($id: String!) {
  deleteSensorPermanently(id: $id) {
    id
    name
  }
}
    `;
export type DeleteSensorPermanentlyMutationFn = Apollo.MutationFunction<DeleteSensorPermanentlyMutation, DeleteSensorPermanentlyMutationVariables>;

/**
 * __useDeleteSensorPermanentlyMutation__
 *
 * To run a mutation, you first call `useDeleteSensorPermanentlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSensorPermanentlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSensorPermanentlyMutation, { data, loading, error }] = useDeleteSensorPermanentlyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSensorPermanentlyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSensorPermanentlyMutation, DeleteSensorPermanentlyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSensorPermanentlyMutation, DeleteSensorPermanentlyMutationVariables>(DeleteSensorPermanentlyDocument, options);
      }
export type DeleteSensorPermanentlyMutationHookResult = ReturnType<typeof useDeleteSensorPermanentlyMutation>;
export type DeleteSensorPermanentlyMutationResult = Apollo.MutationResult<DeleteSensorPermanentlyMutation>;
export type DeleteSensorPermanentlyMutationOptions = Apollo.BaseMutationOptions<DeleteSensorPermanentlyMutation, DeleteSensorPermanentlyMutationVariables>;
export const SubstationDocument = gql`
    query Substation($id: String!) {
  substation(id: $id) {
    id
    name
    description
    latitude
    longitude
    _count {
      modules
    }
    isDisabled
    isDeleted
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useSubstationQuery__
 *
 * To run a query within a React component, call `useSubstationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubstationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubstationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubstationQuery(baseOptions: Apollo.QueryHookOptions<SubstationQuery, SubstationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubstationQuery, SubstationQueryVariables>(SubstationDocument, options);
      }
export function useSubstationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubstationQuery, SubstationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubstationQuery, SubstationQueryVariables>(SubstationDocument, options);
        }
export type SubstationQueryHookResult = ReturnType<typeof useSubstationQuery>;
export type SubstationLazyQueryHookResult = ReturnType<typeof useSubstationLazyQuery>;
export type SubstationQueryResult = Apollo.QueryResult<SubstationQuery, SubstationQueryVariables>;
export const SubstationsDocument = gql`
    query Substations($name: String, $isDisabled: Boolean, $orderBy: [SubstationOrderByWithRelationInput!], $take: Float, $skip: Float) {
  substations(
    name: $name
    isDisabled: $isDisabled
    orderBy: $orderBy
    take: $take
    skip: $skip
  ) {
    count
    items {
      id
      name
      description
      latitude
      longitude
      _count {
        modules
      }
      isDisabled
      isDeleted
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useSubstationsQuery__
 *
 * To run a query within a React component, call `useSubstationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubstationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubstationsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      isDisabled: // value for 'isDisabled'
 *      orderBy: // value for 'orderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useSubstationsQuery(baseOptions?: Apollo.QueryHookOptions<SubstationsQuery, SubstationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubstationsQuery, SubstationsQueryVariables>(SubstationsDocument, options);
      }
export function useSubstationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubstationsQuery, SubstationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubstationsQuery, SubstationsQueryVariables>(SubstationsDocument, options);
        }
export type SubstationsQueryHookResult = ReturnType<typeof useSubstationsQuery>;
export type SubstationsLazyQueryHookResult = ReturnType<typeof useSubstationsLazyQuery>;
export type SubstationsQueryResult = Apollo.QueryResult<SubstationsQuery, SubstationsQueryVariables>;
export const CreateSubstationDocument = gql`
    mutation CreateSubstation($createSubstationInput: SubstationCreateInput!) {
  createSubstation(createSubstationInput: $createSubstationInput) {
    id
    name
  }
}
    `;
export type CreateSubstationMutationFn = Apollo.MutationFunction<CreateSubstationMutation, CreateSubstationMutationVariables>;

/**
 * __useCreateSubstationMutation__
 *
 * To run a mutation, you first call `useCreateSubstationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubstationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubstationMutation, { data, loading, error }] = useCreateSubstationMutation({
 *   variables: {
 *      createSubstationInput: // value for 'createSubstationInput'
 *   },
 * });
 */
export function useCreateSubstationMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubstationMutation, CreateSubstationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubstationMutation, CreateSubstationMutationVariables>(CreateSubstationDocument, options);
      }
export type CreateSubstationMutationHookResult = ReturnType<typeof useCreateSubstationMutation>;
export type CreateSubstationMutationResult = Apollo.MutationResult<CreateSubstationMutation>;
export type CreateSubstationMutationOptions = Apollo.BaseMutationOptions<CreateSubstationMutation, CreateSubstationMutationVariables>;
export const UpdateSubstationDocument = gql`
    mutation UpdateSubstation($updateSubstationInput: SubstationUpdateInput!, $id: String!) {
  updateSubstation(updateSubstationInput: $updateSubstationInput, id: $id) {
    id
    name
  }
}
    `;
export type UpdateSubstationMutationFn = Apollo.MutationFunction<UpdateSubstationMutation, UpdateSubstationMutationVariables>;

/**
 * __useUpdateSubstationMutation__
 *
 * To run a mutation, you first call `useUpdateSubstationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubstationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubstationMutation, { data, loading, error }] = useUpdateSubstationMutation({
 *   variables: {
 *      updateSubstationInput: // value for 'updateSubstationInput'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSubstationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubstationMutation, UpdateSubstationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubstationMutation, UpdateSubstationMutationVariables>(UpdateSubstationDocument, options);
      }
export type UpdateSubstationMutationHookResult = ReturnType<typeof useUpdateSubstationMutation>;
export type UpdateSubstationMutationResult = Apollo.MutationResult<UpdateSubstationMutation>;
export type UpdateSubstationMutationOptions = Apollo.BaseMutationOptions<UpdateSubstationMutation, UpdateSubstationMutationVariables>;
export const ChangeSubstationDisabledStateDocument = gql`
    mutation ChangeSubstationDisabledState($id: String!, $isDisabled: Boolean!) {
  changeSubstationDisabledState(id: $id, isDisabled: $isDisabled) {
    id
    name
    isDisabled
  }
}
    `;
export type ChangeSubstationDisabledStateMutationFn = Apollo.MutationFunction<ChangeSubstationDisabledStateMutation, ChangeSubstationDisabledStateMutationVariables>;

/**
 * __useChangeSubstationDisabledStateMutation__
 *
 * To run a mutation, you first call `useChangeSubstationDisabledStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSubstationDisabledStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSubstationDisabledStateMutation, { data, loading, error }] = useChangeSubstationDisabledStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isDisabled: // value for 'isDisabled'
 *   },
 * });
 */
export function useChangeSubstationDisabledStateMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSubstationDisabledStateMutation, ChangeSubstationDisabledStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeSubstationDisabledStateMutation, ChangeSubstationDisabledStateMutationVariables>(ChangeSubstationDisabledStateDocument, options);
      }
export type ChangeSubstationDisabledStateMutationHookResult = ReturnType<typeof useChangeSubstationDisabledStateMutation>;
export type ChangeSubstationDisabledStateMutationResult = Apollo.MutationResult<ChangeSubstationDisabledStateMutation>;
export type ChangeSubstationDisabledStateMutationOptions = Apollo.BaseMutationOptions<ChangeSubstationDisabledStateMutation, ChangeSubstationDisabledStateMutationVariables>;
export const ChangeSubstationDeletedStateDocument = gql`
    mutation ChangeSubstationDeletedState($id: String!, $isDeleted: Boolean!) {
  changeSubstationDeletedState(id: $id, isDeleted: $isDeleted) {
    id
    name
    isDeleted
  }
}
    `;
export type ChangeSubstationDeletedStateMutationFn = Apollo.MutationFunction<ChangeSubstationDeletedStateMutation, ChangeSubstationDeletedStateMutationVariables>;

/**
 * __useChangeSubstationDeletedStateMutation__
 *
 * To run a mutation, you first call `useChangeSubstationDeletedStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSubstationDeletedStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSubstationDeletedStateMutation, { data, loading, error }] = useChangeSubstationDeletedStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isDeleted: // value for 'isDeleted'
 *   },
 * });
 */
export function useChangeSubstationDeletedStateMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSubstationDeletedStateMutation, ChangeSubstationDeletedStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeSubstationDeletedStateMutation, ChangeSubstationDeletedStateMutationVariables>(ChangeSubstationDeletedStateDocument, options);
      }
export type ChangeSubstationDeletedStateMutationHookResult = ReturnType<typeof useChangeSubstationDeletedStateMutation>;
export type ChangeSubstationDeletedStateMutationResult = Apollo.MutationResult<ChangeSubstationDeletedStateMutation>;
export type ChangeSubstationDeletedStateMutationOptions = Apollo.BaseMutationOptions<ChangeSubstationDeletedStateMutation, ChangeSubstationDeletedStateMutationVariables>;
export const DeleteSubstationPermanentlyDocument = gql`
    mutation DeleteSubstationPermanently($id: String!) {
  deleteSubstationPermanently(id: $id) {
    id
    name
  }
}
    `;
export type DeleteSubstationPermanentlyMutationFn = Apollo.MutationFunction<DeleteSubstationPermanentlyMutation, DeleteSubstationPermanentlyMutationVariables>;

/**
 * __useDeleteSubstationPermanentlyMutation__
 *
 * To run a mutation, you first call `useDeleteSubstationPermanentlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubstationPermanentlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubstationPermanentlyMutation, { data, loading, error }] = useDeleteSubstationPermanentlyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSubstationPermanentlyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubstationPermanentlyMutation, DeleteSubstationPermanentlyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubstationPermanentlyMutation, DeleteSubstationPermanentlyMutationVariables>(DeleteSubstationPermanentlyDocument, options);
      }
export type DeleteSubstationPermanentlyMutationHookResult = ReturnType<typeof useDeleteSubstationPermanentlyMutation>;
export type DeleteSubstationPermanentlyMutationResult = Apollo.MutationResult<DeleteSubstationPermanentlyMutation>;
export type DeleteSubstationPermanentlyMutationOptions = Apollo.BaseMutationOptions<DeleteSubstationPermanentlyMutation, DeleteSubstationPermanentlyMutationVariables>;
export const MeDocument = gql`
    mutation Me {
  me {
    id
    email
    personalInformations {
      firstName
      lastName
      fullName
      title
    }
    preferenceInformations {
      theme
    }
    specificInformations {
      isDisabled
      isDeleted
      createdAt
      updatedAt
    }
  }
}
    `;
export type MeMutationFn = Apollo.MutationFunction<MeMutation, MeMutationVariables>;

/**
 * __useMeMutation__
 *
 * To run a mutation, you first call `useMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [meMutation, { data, loading, error }] = useMeMutation({
 *   variables: {
 *   },
 * });
 */
export function useMeMutation(baseOptions?: Apollo.MutationHookOptions<MeMutation, MeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MeMutation, MeMutationVariables>(MeDocument, options);
      }
export type MeMutationHookResult = ReturnType<typeof useMeMutation>;
export type MeMutationResult = Apollo.MutationResult<MeMutation>;
export type MeMutationOptions = Apollo.BaseMutationOptions<MeMutation, MeMutationVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    nextPaginationToken
    items {
      id
      email
      personalInformations {
        firstName
        fullName
        lastName
        title
      }
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const GetUserByIdDocument = gql`
    mutation GetUserById($id: String!) {
  getUserById(id: $id) {
    id
    email
    personalInformations {
      firstName
      lastName
      fullName
      title
    }
    preferenceInformations {
      theme
    }
    specificInformations {
      isDisabled
      isDeleted
      createdAt
      updatedAt
    }
  }
}
    `;
export type GetUserByIdMutationFn = Apollo.MutationFunction<GetUserByIdMutation, GetUserByIdMutationVariables>;

/**
 * __useGetUserByIdMutation__
 *
 * To run a mutation, you first call `useGetUserByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUserByIdMutation, { data, loading, error }] = useGetUserByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdMutation(baseOptions?: Apollo.MutationHookOptions<GetUserByIdMutation, GetUserByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetUserByIdMutation, GetUserByIdMutationVariables>(GetUserByIdDocument, options);
      }
export type GetUserByIdMutationHookResult = ReturnType<typeof useGetUserByIdMutation>;
export type GetUserByIdMutationResult = Apollo.MutationResult<GetUserByIdMutation>;
export type GetUserByIdMutationOptions = Apollo.BaseMutationOptions<GetUserByIdMutation, GetUserByIdMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($createUserInput: UserCreateInput!) {
  createUser(createUserInput: $createUserInput) {
    id
    email
    personalInformations {
      firstName
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      createUserInput: // value for 'createUserInput'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($updateUserInput: UserUpdateInput!) {
  updateUser(updateUserInput: $updateUserInput) {
    id
    email
    personalInformations {
      firstName
      lastName
      fullName
      title
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      updateUserInput: // value for 'updateUserInput'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DestroyUserAccountDocument = gql`
    mutation DestroyUserAccount($id: String!) {
  destroyUserAccount(id: $id) {
    id
    email
    personalInformations {
      firstName
      lastName
      fullName
      title
    }
  }
}
    `;
export type DestroyUserAccountMutationFn = Apollo.MutationFunction<DestroyUserAccountMutation, DestroyUserAccountMutationVariables>;

/**
 * __useDestroyUserAccountMutation__
 *
 * To run a mutation, you first call `useDestroyUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyUserAccountMutation, { data, loading, error }] = useDestroyUserAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDestroyUserAccountMutation(baseOptions?: Apollo.MutationHookOptions<DestroyUserAccountMutation, DestroyUserAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyUserAccountMutation, DestroyUserAccountMutationVariables>(DestroyUserAccountDocument, options);
      }
export type DestroyUserAccountMutationHookResult = ReturnType<typeof useDestroyUserAccountMutation>;
export type DestroyUserAccountMutationResult = Apollo.MutationResult<DestroyUserAccountMutation>;
export type DestroyUserAccountMutationOptions = Apollo.BaseMutationOptions<DestroyUserAccountMutation, DestroyUserAccountMutationVariables>;