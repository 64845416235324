import React from "react";

import FSelectField from "./FSelectField";

interface Option {
  id: number;
  name: string;
}

interface Props {
  name: string;
  placeholder?: string;
  label?: string;
  value?: Option;
  [x: string]: unknown;
}

const interval: Option[] = [
  {
    id: 60,
    name: "1 Minute",
  },
  {
    id: 300,
    name: "5 Minute",
  },
  {
    id: 900,
    name: "15 Minute",
  },
  {
    id: 1800,
    name: "30 Minute",
  },
  {
    id: 3600,
    name: "1 Hour",
  },
  {
    id: 10800,
    name: "3 Hour",
  },
  {
    id: 43200,
    name: "12 Hour",
  },
  {
    id: 86400,
    name: "1 Day",
  },
];

const IntervalSelectField = (props: Props) => {
  return (
    <FSelectField
      {...props}
      label="Interval"
      textAlign="end"
      options={interval}
    />
  );
};

export default IntervalSelectField;
