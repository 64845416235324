// ** MUI Imports
import { Theme } from "@mui/material/styles";
import { hexToRGBA } from "src/@core/utils/hex-to-rgba";

const Tabs = (theme: Theme) => {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor:'transparent',
          borderRadius: 9,        
          padding: 5
        },
        flexContainer: {
          display: "inline-flex",
          position: "relative",
          zIndex: 1,
        },
        scroller: {
          [theme.breakpoints.up("md")]: {
            padding: "0 8px",
          },
        },
        indicator: {
          top: 3,
          bottom: 3,
          right: 3,
          height: "auto",
          background: "none",
          "&:after": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: 4,
            right: 4,
            bottom: 0,
            borderRadius: 8,
            backgroundColor: theme.palette.primary.main,
            boxShadow: theme.shadows[1],
          },
        },
        vertical: {
          minWidth: 150,
          minHeigt: 500,
          marginRight: theme.spacing(4),
          borderRight: `1px solid ${theme.palette.divider}`,
          "& .MuiTab-root": {
            minWidth: 150,
            minHeigt: 500,
          },
          
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          borderRadius: 7,
          marginTop: 3,
          marginBottom: 3,
          textTransform: "capitalize",
          minHeight: 24,
          minWidth: 96,
          [theme.breakpoints.up("md")]: {
            minWidth: 120,
          },
          "&:hover:not(.Mui-selected):not(.Mui-disabled)": {
            color: theme.palette.primary.main,
            backgroundColor: hexToRGBA(theme.palette.primary.main, 0.21),
            opacity: 1,
          },
          "&.Mui-selected": {
            color: theme.palette.customColors.customizedTextWithoutTheme,
          },
        },
        wrapper: {
          color: theme.palette.text.primary,
        },
      },
    },
  };
};

export default Tabs;
