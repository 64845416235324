// ** MUI Imports
import { Box, Card, CardContent, Typography } from "@mui/material";
import { hexToRGBA } from "src/@core/utils/hex-to-rgba";

const MaintenancePage = () => {
  return (
    <Box sx={{ width: "100%",  height: "calc(100vh - 122px)", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Card>
        <CardContent sx={{pt:5, background: theme => hexToRGBA(theme.palette.primary.light, 0.30)}}>
          <Typography color={'primary'}>Maintenance Page includes Pro License</Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default MaintenancePage;
