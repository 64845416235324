// ** MUI Imports
import MuiChip from '@mui/material/Chip'

// ** Types
import { CustomChipProps } from './types'

// ** Hooks Imports
import useBgColor, { UseBgColorType } from 'src/@core/hooks/useBgColor'
import { Color } from 'src/types/enum-types/Color'
import { useEffect, useState } from 'react'

const Chip = (props: CustomChipProps) => {
  // ** Props
  const { sx, skin, color } = props

  // ** States
  const [colorize, setColorize] = useState<Color>(Color.success)

  useEffect(() => {
    setColorize(color as Color)
  }, [color])


  // ** Hook
  const bgColors = useBgColor()

  const colors: UseBgColorType = {
    primary: { ...bgColors.primaryLight },
    secondary: { ...bgColors.secondaryLight },
    success: { ...bgColors.successLight },
    error: { ...bgColors.errorLight },
    warning: { ...bgColors.warningLight },
    info: { ...bgColors.infoLight }
  }

  return (
    <MuiChip
      {...props}
      variant='filled'
      {...(skin === 'light' && { className: 'MuiChip-light' })}
      sx={skin === 'light' && colorize ? Object.assign(colors[colorize], sx) : sx}
    />
  )
}

export default Chip
