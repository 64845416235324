// ** React Imports

// ** MUI Imports
import { Chip } from "@mui/material";

// ** Utils Imports
// ** Component Imports

// ** Type Imports

// ** Global Context Imports
import * as MuiIcons from "@mui/icons-material";

// ** Library Imports
import { useNavigate } from "react-router";

import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { BreadCrumbData } from "src/types/component/BreadCrumb";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor = theme.palette.background.paper;
  return {
    backgroundColor,
    padding: 5,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

type propsBreadcrumbs = {
  breadcrumbDatas: BreadCrumbData[];
};

const CustomizedBreadcrumbs = (props: propsBreadcrumbs) => {
  const navigate = useNavigate();

  const { breadcrumbDatas } = props;

  const generateIcon = (icon: string) => {
    const IconComponent = MuiIcons[icon as keyof typeof MuiIcons];
    return <IconComponent fontSize="small" />;
  };

  const navigator = (path: string | undefined) => {
    if (path !== undefined) {
      navigate(path);
    }
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {breadcrumbDatas.map((item, index) => (
        <StyledBreadcrumb
          key={index}
          component="div"
          label={item.label}
          icon={
            item.icon !== undefined
              ? generateIcon(item.icon as string)
              : undefined
          }
          onClick={() => {
            navigator(item.navigateURL);
          }}
        />
      ))}
    </Breadcrumbs>
  );
};

export default CustomizedBreadcrumbs;
