// ** React Imports
import {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

// ** MUI Imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Card, CardContent, Chip, Tab } from "@mui/material";
import Divider from "@mui/material/Divider";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";

// ** Utils Imports
import {
  UserCreateInput,
  useCreateUserMutation,
} from "src/generated/graphql";

// ** Component Imports
import { FTextField } from "src/@core/components/form-field/FTextField";
import ErrorToast from "src/@core/components/toast/ErrorToast";
import SuccessToast from "src/@core/components/toast/SuccessToast";
import PendingToast from "src/@core/components/toast/PendingToast";

// ** Type Imports
import { Color } from "src/types/enum-types/Color";

// ** Global Context Imports
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// ** Library Imports
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import _ from "lodash";
import * as yup from "yup";
import { string } from "yup";
import { GlobalContext } from "src/context/GlobalContext";
import { useNavigate, useParams } from "react-router";
import CustomizedBreadcrumbs from "src/@core/components/breadcrumb/CustomizedBreadcrumb";
import { BreadCrumbData } from "src/types/component/BreadCrumb";
import { FToggleChip } from "src/@core/components/form-field/FToggleChip";
import { Theme } from "src/types/enum-types/Theme";
import { FTextFieldPassword } from "src/@core/components/form-field/FTextFieldPassword";
import FButtonToggleRole from "src/@core/components/form-field/button-toggle/FButtonToggleRole";
import { Role } from "src/types/enum-types/Role";

// ** Validation Schema
const validationSchema = yup.object().shape({
  email: string().required("Username required"),
  password: string().required("Password required"),
  passwordConfirmation: string().oneOf(
    [yup.ref("password"), ""],
    "Passwords must match"
  ),
  personalInformations: yup.object().shape({
    firstName: yup.string().required("First name required"),
    lastName: yup.string().required("Last name required"),
  }),
});

const UserAddEditPage = () => {
  // ** Context Datas
  const params = useParams();
  const navigate = useNavigate();
  const { setToastDesignColor } = useContext(GlobalContext);

  // ** States
  const [selectedFile, setSelectedFile] = useState<string>("");
  const [breadcrumbLabel, setBrandcrumbLabel] = useState<string>("Create User");
  const [theme, setTheme] = useState<Theme>(Theme.Dark);
  const [role, setRole] = useState<Role>(Role.User);

  const [isDisabledText, setIsDisabledText] = useState<string>("Active");
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isDisabledColor, setIsDisabledColor] = useState<Color>(Color.success);
  const [tabValue, setTabValue] = useState<string>("general");
  const [mainRole, setMainRole] = useState<Role>(Role.User);

  const [initialValues, setInitialValues] = useState<UserCreateInput>({
    email: "",
    password: "",
    role: Role.User,
    personalInformations: {
      firstName: "",
      lastName: "",
      title: "",
    },
    preferenceInformations: {
      theme: Theme.Dark,
    },
    specificInformations: {
      description: "",
      isDisabled: false,
      isDeleted: false,
      createdAt: Date.now().toString(),
      updatedAt: Date.now().toString(),
    },
  } as UserCreateInput);

  // ** Utils Funtions
  const [CreateUser] = useCreateUserMutation();

  const { userId } = params;
  const isNew = userId === "new";

  // by passing empty array at the end, this will always return the same function, compatible with removeEventListener
  const keyDownHandler = useCallback((event: any) => {
    if (event.keyCode === 13 && event.target.nodeName !== "BUTTON") {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler);

    return () => document.removeEventListener("keydown", keyDownHandler);
  }, []);

  useEffect(() => {}, [selectedFile, setSelectedFile]);

  const breadcrumbDatas: BreadCrumbData[] = [
    {
      icon: "PeopleAltRounded",
      label: "Users",
      navigateURL: "/users",
    },
    {
      label: breadcrumbLabel,
    },
  ];

  const onBack = () => {
    navigate(-1);
  };

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const onSubmit = async (values: UserCreateInput) => {
    const promiseToastData = new Promise(async (resolve, reject) => {
      try {
        let returnedName;

        values.role = role;

        if (isNew) {
          const returnedData = await CreateUser({
            variables: {
              createUserInput: _.omit(values, "passwordConfirmation"),
            },
          });

          returnedName =
            returnedData.data?.createUser.personalInformations?.firstName;
        }
        resolve(returnedName);
        navigate("/users");
      } catch (error) {
        const errorResult = (error as Error).message;
        reject(errorResult);
      }
    });

    toast.promise(promiseToastData, {
      pending: {
        render() {
          const description = isNew ? "is Creating" : "is Updating";
          return (
            <PendingToast
              title="User"
              description={description}
              color="primary"
            />
          );
        },
        icon: false,
      },
      success: {
        render({ data }) {
          let color = Color.success;
          let description = "Has been successfully created";

          if (!isNew) {
            color = Color.warning;
            description = "Has been successfully updated";
          }
          setToastDesignColor(color);
          return (
            <SuccessToast
              title={data as string}
              description={description}
              color={color}
            />
          );
        },
        icon: false,
      },
      error: {
        render({ data }) {
          setToastDesignColor(Color.error);
          return (
            <ErrorToast title={"Error Occurred"} description={data as string} />
          );
        },
        icon: false,
      },
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form onKeyUp={keyDownHandler}>
          <Grid container spacing={7}>
            <TabContext value={tabValue}>
              <Grid item xs={12} display="flex" justifyContent="space-between">
                <CustomizedBreadcrumbs breadcrumbDatas={breadcrumbDatas} />
                {isNew ? (
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<AddRoundedIcon />}
                  >
                    Create
                  </Button>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <TabList
                  centered
                  variant="fullWidth"
                  scrollButtons="auto"
                  onChange={handleTabChange}
                  aria-label="forced scroll tabs example"
                >
                  <Tab
                    value="general"
                    label="General"
                  />
                  <Tab
                    value="personal-informations"
                    label="Personal Information"
                  />
                  <Tab
                    value="preference-informations"
                    label="Preferences"
                  />
                  <Tab
                    value="specific-informations"
                    label="Specific Informations"
                  />
                </TabList>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                  <TabPanel sx={{ p: 0 }} value="general">
                  <Grid container spacing={7} p={7} justifyContent="center">
                    <Grid item xs={12}>
                      <Divider>
                        <Chip label="General Informations" />
                      </Divider>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <FTextField
                        name="email"
                        fullWidth
                        label="Email"
                        placeholder="example@gmail.com"
                        type="text"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <FTextFieldPassword
                        name="password"
                        fullWidth
                        label="Password"
                        placeholder="*****"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <FTextFieldPassword
                        name="passwordConfirmation"
                        fullWidth
                        label="Password Again"
                        placeholder="*****"
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel sx={{ p: 0 }} value="personal-informations">
                  <Grid container spacing={7} p={7} sx={{}}>
                    <Grid item xs={12}>
                      <Divider>
                        <Chip label="Personal Informations" />
                      </Divider>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <FTextField
                        name="personalInformations.firstName"
                        fullWidth
                        label="First Name"
                        placeholder="John"
                        type="text"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <FTextField
                        name="personalInformations.lastName"
                        fullWidth
                        label="Last Name"
                        placeholder="Due"
                        type="text"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <FTextField
                        name="personalInformations.title"
                        fullWidth
                        label="Title"
                        placeholder="Maintainer"
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel sx={{ p: 0 }} value="preference-informations">
                  <Grid
                    container
                    spacing={7}
                    p={7}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Grid item xs={12}>
                      <Divider>
                        <Chip label="Preferences" />
                      </Divider>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <FToggleChip
                        text="Theme"
                        color={Color.success}
                        label={theme}
                        onChange={() => {
                          if (theme === Theme.Dark) {
                            setTheme(Theme.Light);
                          } else {
                            setTheme(Theme.Dark);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel sx={{ p: 0 }} value="specific-informations">
                  <Grid
                    container
                    spacing={7}
                    p={7}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Grid item xs={12}>
                      <Divider>
                        <Chip label="Specific Informations" />
                      </Divider>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <FButtonToggleRole
                        mainRole={mainRole}
                        setMainRole={setMainRole}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <FToggleChip
                        text="Account Status"
                        color={isDisabledColor}
                        label={isDisabledText}
                        onChange={() => {
                          if (isDisabled) {
                            setIsDisabledColor(Color.success);
                            setIsDisabledText("Active");
                          } else {
                            setIsDisabledColor(Color.error);
                            setIsDisabledText("Disabled");
                          }

                          setIsDisabled(!isDisabled);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <FTextField
                        name="specificInformations.description"
                        fullWidth
                        label="Description"
                        multiline
                        rows={4}
                        placeholder="(Optional)"
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                  </CardContent>
                </Card>

              </Grid>
            </TabContext>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};

export default UserAddEditPage;
