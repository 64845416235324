import React, { useState, MouseEvent } from "react";
import { useField } from "formik";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import {
  Box,
  IconButton,
  InputAdornment,
  styled,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import { Theme } from "@mui/material/styles";
import useBgColor, { UseBgColorType } from "src/@core/hooks/useBgColor";
import { Color } from "src/types/enum-types/Color";

interface Props {
  name: string;
  placeholder?: string;
  active?: string;
  setActive?: (value: React.SetStateAction<string>) => void;
  setLastActive?: (value: React.SetStateAction<string>) => void;
  [x: string]: unknown;
}

export const FTextFieldPasswordLogin = (props: Props) => {
  const [field, meta] = useField(props);

  const theme = useTheme();

  const [visibilityOfPassword, setVisibilityOfPassword] = useState(false);

  const handleClickSetEnable = () => {
    setVisibilityOfPassword(!visibilityOfPassword);
  };

  const handleMouseDownEnable = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const startAdornment = (
    <InputAdornment position="start">
      <Box>
        <LockRoundedIcon
          sx={{
            color: theme.palette.primary.main,
          }}
        />
      </Box>
    </InputAdornment>
  );

  const endAdornment = (
    <InputAdornment position="end" sx={{ mr: 2 }}>
      <IconButton
        aria-label="toggle enabled"
        onClick={handleClickSetEnable}
        onMouseDown={handleMouseDownEnable}
        edge="end"
      >
        {visibilityOfPassword ? (
          <VisibilityIcon
            sx={{
              color: theme.palette.customColors.customizedComponentWithoutTheme,
            }}
          />
        ) : (
          <VisibilityOffIcon
            sx={{
              color: theme.palette.customColors.customizedComponentWithoutTheme,
            }}
          />
        )}
      </IconButton>
    </InputAdornment>
  );

  const onFocus = (event: any) => {
    if (props.setLastActive) props.setLastActive(props.active || "");

    if (props.setActive) props.setActive(props.name);
  };

  return (
    <TextField
      {...field}
      {...props}
      sx={{
        maxWidth: "600px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.palette.customColors.customizedComponentWithoutTheme}`,
        },
        "&:hover:not(.Mui-focused):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline":
          {
            borderColor: theme.palette.primary.main,
            borderWidth: 2,
          },
        "&:hover:not(.Mui-focused):not(.Mui-disabled) .MuiInputLabel-root": {
          color: theme.palette.primary.main,
        },
      }}
      InputLabelProps={{
        sx: {
          color: theme.palette.customColors.customizedTextWithoutTheme,
        },
      }}
      inputProps={{
        sx: {
          color: theme.palette.customColors.customizedTextWithoutTheme,
        },
      }}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      onFocus={onFocus}
      type={visibilityOfPassword ? "text" : "password"}
      InputProps={{
        startAdornment,
        endAdornment,
      }}
    />
  );
};
