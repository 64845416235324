// ** React Imports
import { useContext, useState } from "react";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";

// ** MUI Components
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box, { BoxProps } from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { Grid, Link, Stack } from "@mui/material";
import { Form, Formik } from "formik";

import * as yup from "yup";
import { string } from "yup";
import { toast } from "react-toastify";
import { Image } from "mui-image";
import PendingToast from "src/@core/components/toast/PendingToast";
import { GlobalContext } from "src/context/GlobalContext";
import { Color } from "src/types/enum-types/Color";
import SuccessToast from "src/@core/components/toast/SuccessToast";
import { FTextFieldPasswordLogin } from "src/@core/components/form-field/TextField/FTextFieldPasswordLogin";
import { FTextFieldEmailLogin } from "src/@core/components/form-field/TextField/FTextFieldEmailLogin";

type ILogin = {
  email: string;
  password: string;
};

const RightWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    maxWidth: 450,
  },
}));

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: 400,
  },
}));

const validationSchema = yup.object().shape({
  email: string().email("Enter a valid email").required("Email required"),
  password: string().required("Password required"),
});

const LoginPage = () => {

  const { setToastDesignColor } = useContext(GlobalContext);

  let initialValues: ILogin = {
    email: "",
    password: "",
  };

  // ** Hooks
  const theme = useTheme();
  const hidden = useMediaQuery(theme.breakpoints.down("md"));

  const onSubmit = async (data: ILogin) => {
    const promiseToastData = new Promise(async (resolve, reject) => {
      try {
        setToastDesignColor(Color.primary);

        let response = await EmailPassword.signIn({
          formFields: [
            {
              id: "email",
              value: data.email,
            },
            {
              id: "password",
              value: data.password,
            },
          ],
        });

        if (response.status === "OK") {
          resolve(response);
        } else {
          reject("Email or password is incorrect");
        }
      } catch (error) {
        reject(error);
      }
    });

    toast.promise(promiseToastData, {
      pending: {
        render() {
          return (
            <PendingToast
              title="Logging In"
              description="Awaiting response"
              color="primary"
            />
          );
        },
        icon: false,
      },
      success: {
        render() {
          setToastDesignColor(Color.success);
          return (
            <SuccessToast
              title="Session Info"
              description="Login successful"
              color={Color.success}
            />
          );
        },
      },
      error: {
        render({ data }) {
          setToastDesignColor(Color.error);
          return (
            <SuccessToast
              title="Error occurred"
              description={data as string}
              color={Color.error}
            />
          );
        },
      },
    });
  };

  return (
    <Box
      className="content-right"
      sx={{
        position: "relative",
        backgroundImage: `url(/images/login-background.jpg)`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {!hidden ? (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            position: "relative",
            background: "#354A8955",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "25%",
              left: 0,
              m: 10,
            }}
          >
            <Typography variant="h1" sx={{ color: "rgba(255,255,255, 0.77)" }}>
              Digitalize Your Business
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              m: 10,
            }}
          >
            <Stack
              spacing={2}
              direction="row"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Link href="https://delphisonic.com/">
                <Typography sx={{ color: "rgba(255,255,255, 0.9)" }}>
                  Contact Us
                </Typography>
              </Link>
              <Divider
                sx={{ borderColor: "rgba(255,255,255, 0.9)", height: 18 }}
                orientation="vertical"
              />
              <Typography sx={{ color: "rgba(255,255,255, 0.9)" }}>
                {`© ${new Date().getFullYear()} Delphi Sonic`}
              </Typography>
              <Divider
                sx={{ borderColor: "rgba(255,255,255, 0.9)", height: 18 }}
                orientation="vertical"
              />
              <Typography sx={{ color: "rgba(255,255,255, 0.9)" }}>
                All Rights Reserved
              </Typography>
            </Stack>
          </Box>
        </Box>
      ) : null}
      <RightWrapper
        sx={{
          BoxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
        }}
      >
        <Box
          sx={{
            p: 12,
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#001E3CF5",
          }}
        >
          <BoxWrapper sx={{ padding: 7, borderRadius: 3 }}>
            <Box display="flex" justifyContent="center" sx={{ my: 10 }}>
              <Image src="/images/logo.png" width={216} />
            </Box>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              <Form>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <FTextFieldEmailLogin
                      autoComplete="chrome-off"
                      name="email"
                      fullWidth
                      label="Email"
                      placeholder="Email"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FTextFieldPasswordLogin
                      autoComplete="chrome-off"
                      name="password"
                      fullWidth
                      label="Password"
                      placeholder="Password"
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      sx={{ mb: 7 }}
                    >
                      Login
                    </Button>
                  </Grid>
                </Grid>
                {hidden ? (
                  <Box display="flex" justifyContent="center" mt={10}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color:
                          theme.palette.customColors.customizedTextWithoutTheme,
                      }}
                    >
                      {`© ${new Date().getFullYear()} All Rights Reserved`}
                    </Typography>
                  </Box>
                ) : null}
              </Form>
            </Formik>
          </BoxWrapper>
        </Box>
      </RightWrapper>
    </Box>
  );
};

export default LoginPage;
