import { createContext, useState } from "react";

export interface IModal {
  setSubstationModal: React.Dispatch<React.SetStateAction<boolean>>;
  substationModal: boolean;
  setModuleModal: React.Dispatch<React.SetStateAction<boolean>>;
  moduleModal: boolean;
  setSensorModal: React.Dispatch<React.SetStateAction<boolean>>;
  sensorModal: boolean;
}

const defaultState: IModal = {
  substationModal: false,
  setSubstationModal: () => false,
  moduleModal: false,
  setModuleModal: () => false,
  sensorModal: false,
  setSensorModal: () => false,
};

export const ModalContext = createContext<IModal>(defaultState);

type Props = {
  children?: React.ReactNode;
};

const ModalProvider = ({ children }: Props) => {
  const [substationModal, setSubstationModal] = useState(false);
  const [moduleModal, setModuleModal] = useState(false);
  const [sensorModal, setSensorModal] = useState(false);

  const state: IModal = {
    substationModal,
    setSubstationModal,
    moduleModal,
    setModuleModal,
    sensorModal,
    setSensorModal,
  };

  return (
    <ModalContext.Provider value={state}>{children}</ModalContext.Provider>
  );
};

export default ModalProvider;
