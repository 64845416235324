// ** React Imports
import * as React from "react";

// ** MUI Imports
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// ** Icon Imports
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ElectricMeterRoundedIcon from "@mui/icons-material/ElectricMeterRounded";
import SensorsRoundedIcon from "@mui/icons-material/SensorsRounded";
import DeviceHubRoundedIcon from "@mui/icons-material/DeviceHubRounded";

// ** Routing Imports
import { useNavigate } from "react-router-dom";

// ** Context Imports
import { ModalContext } from "src/context/modalContext";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 7,
    backgroundColor: theme.palette.customColors.appBar,
    marginTop: theme.spacing(2),
    minWidth: 180,
    boxShadow: "0px 0px 15px 15px rgba(0,0,0,0.1)",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function AddShortcut() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { setSubstationModal, setModuleModal, setSensorModal } =
    React.useContext(ModalContext);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onSubstationModal = () => {
    setSubstationModal(true);
    setAnchorEl(null);
    navigate("/substations");
  };

  const onModuleModal = () => {
    setModuleModal(true);
    setAnchorEl(null);
    navigate("/modules");
  };

  const onSensorModal = () => {
    setSensorModal(true);
    setAnchorEl(null);
    navigate("/sensors");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-add-shortcut-button"
        aria-controls={open ? "demo-add-shortcut-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        sx={{ mr: 2, backgroundColor: "#00888F29" }}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Create
      </Button>
      <StyledMenu
        id="demo-add-shortcut-menu"
        MenuListProps={{
          "aria-labelledby": "demo-add-shortcut-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={onSubstationModal} disableRipple>
          <ElectricMeterRoundedIcon />
          Substation
        </MenuItem>
        <MenuItem onClick={onModuleModal} disableRipple>
          <DeviceHubRoundedIcon />
          Module
        </MenuItem>
        <MenuItem onClick={onSensorModal} disableRipple>
          <SensorsRoundedIcon />
          Sensor
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
