// ** Component Imports

// ** Graphql Imports

// ** Library Imports
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import moment from "moment";

// ** MUI Imports
import {
  Box,
  Grid,
  Typography,
  useTheme,
  Card,
  CardContent,
} from "@mui/material";

// ** React Imports
import { useEffect, useState } from "react";
import Chip from "../mui/chip";
import DatasetIcon from "@mui/icons-material/Dataset";
// ** Types
type ConsolidatedLiveChartProps = {
  label: string;
  pressure: number;
  co2: number;
  gasPercentage: number;
  gasResistance: number;
  time: Date;
};

type SerieDataType = {
  x: Date;
  y: number;
};

const ConsolidatedLiveChart = (props: ConsolidatedLiveChartProps) => {
  // ** Props
  const { label, pressure, co2, gasPercentage, gasResistance, time } = props;

  // ** States
  const [serieDatasForPressure, setSerieDatasForPressure] = useState<
    SerieDataType[]
  >([]);
  const [serieDatasForCO2, setSerieDatasForCO2] = useState<SerieDataType[]>([]);
  const [serieDatasForGasPercentage, setSerieDatasForGasPercentage] = useState<
    SerieDataType[]
  >([]);
  const [serieDatasForGasResistance, setSerieDatasForGasResistance] = useState<
    SerieDataType[]
  >([]);
  const [dataSetQuantity, setDataSetQuantity] = useState<number>(0);

  // ** Context Datas
  const theme = useTheme();

  // ** Variables
  const series = [
    {
      name: "Pressure (hPa)",
      data: serieDatasForPressure,
    },
    {
      name: "CO2",
      data: serieDatasForCO2,
    },
    {
      name: "Gas Percentage (%)",
      data: serieDatasForGasPercentage,
    },
    {
      name: "Gas Resistance (Ohm)",
      data: serieDatasForGasResistance,
    },
  ];

  const options: ApexOptions = {
    chart: {
      id: "consolidated",
      type: "area",
      height: "100%",
    },
    xaxis: {
      labels: {
        formatter(value: string) {
          return moment(value).format("H:mm:ss");
        },
      },
    },
    markers: {
      size: 0,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: theme.palette.text.primary,
        fontSize: "18px",
      },
    },
    colors: [
      theme.palette.info.main,
      theme.palette.secondary.main,
      theme.palette.success.main,
      theme.palette.warning.main,
    ],
  };

  useEffect(() => {}, []);

  // ** UseEffects
  useEffect(() => {
    if (dataSetQuantity > 9) {
      setSerieDatasForPressure([
        ...serieDatasForPressure.slice(1),
        { x: time, y: Number(pressure.toFixed(2)) },
      ]);
      setSerieDatasForCO2([
        ...serieDatasForCO2.slice(1),
        { x: time, y: Number(co2.toFixed(2)) },
      ]);
      setSerieDatasForGasPercentage([
        ...serieDatasForGasPercentage.slice(1),
        { x: time, y: Number(gasPercentage.toFixed(2)) },
      ]);
      setSerieDatasForGasResistance([
        ...serieDatasForGasResistance.slice(1),
        { x: time, y: Number(gasResistance.toFixed(2)) },
      ]);
    } else {
      setSerieDatasForPressure([
        ...serieDatasForPressure,
        { x: time, y: Number(pressure.toFixed(2)) },
      ]);
      setSerieDatasForCO2([
        ...serieDatasForCO2,
        { x: time, y: Number(co2.toFixed(2)) },
      ]);
      setSerieDatasForGasPercentage([
        ...serieDatasForGasPercentage,
        { x: time, y: Number(gasPercentage.toFixed(2)) },
      ]);
      setSerieDatasForGasResistance([
        ...serieDatasForGasResistance,
        { x: time, y: Number(gasResistance.toFixed(2)) },
      ]);
    }

    setDataSetQuantity(dataSetQuantity + 1);
  }, [pressure, co2, gasPercentage, gasResistance, time]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: theme.palette.background.paper,
            px: 3,
            py: 2,
            borderRadius: 1,
          }}
        >
          <Typography variant="subtitle1">{label}</Typography>
          <Chip
            skin="light"
            label={`Dataset Quantity: ${dataSetQuantity}`}
            color={"primary"}
            icon={<DatasetIcon fontSize="small" />}
            sx={{
              py: 4,
              px: 1,
              height: 32,
              "&.MuiChip-light": {
                "&:hover": {
                  color: (theme) =>
                    theme.palette.customColors.customizedChipText,
                },
              },
              fontSize: "0.8rem",
              "& .MuiChip-label": {
                fontWeight: 600,
                lineHeight: 1.4,
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Card
          sx={{
            height: 300,
            maxHeight: 400,
          }}
        >
          <CardContent
            sx={{
              p: 0,
              width: "100%",
              height: "100%",
              "& .apexcharts-legend-text": {
                color: (theme) => `${theme.palette.text.primary} !important`,
              },
              "& .apexcharts-xcrosshairs.apexcharts-active": {
                opacity: 0,
              },
              "& .apexcharts-yaxis-label": {
                fill: (theme) => `${theme.palette.text.primary} !important`,
              },
              "& .apexcharts-xaxis-label": {
                fill: (theme) => `${theme.palette.text.primary} !important`,
              },
              "& .apexcharts-tooltip": {
                color: (theme) => `${theme.palette.text.primary} !important`,
              },
              "& .apexcharts-tooltip-title": {
                color: (theme) => `${theme.palette.text.primary} !important`,
                backgroundColor: (theme) =>
                  `${theme.palette.background.paper} !important`,
                border: 0,
              },
              "& .apexcharts-tooltip-box": {
                color: (theme) => `${theme.palette.text.primary} !important`,
                backgroundColor: (theme) =>
                  `${theme.palette.background.paper} !important`,
              },
              "& .apexcharts-tooltip .apexcharts-tooltip-series-group": {
                color: (theme) => `${theme.palette.text.primary} !important`,
                backgroundColor: (theme) =>
                  `${theme.palette.background.paper} !important`,
              },
            }}
          >
            <Chart
              options={options}
              series={series}
              type="area"
              height={"100%"}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ConsolidatedLiveChart;
