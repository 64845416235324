// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";

// ** Icons Imports

// ** Custom Components Imports
import { Avatar, Badge, Theme, useTheme } from "@mui/material";
// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import Typography from "@mui/material/Typography";
import { BoltRounded, PowerRounded, FlashOnRounded } from "@mui/icons-material";
import { Color } from "src/types/enum-types/Color";
import useBgColor, { UseBgColorType } from "src/@core/hooks/useBgColor";
import { ThemeColor } from "src/@core/layouts/types";
import { socket } from "src/socket";

// ** Icons Imports

import moment from "moment";
import Chip from "src/@core/components/mui/chip";
import { IEnergy } from "src/pages/substations/SubstationView";


const EnergyCard = () => {
  // ** Props

  // ** Hook
  const bgColors = useBgColor();

  const colors: UseBgColorType = {
    primary: { ...bgColors.primaryLight },
    secondary: { ...bgColors.secondaryLight },
    success: { ...bgColors.successLight },
    error: { ...bgColors.errorLight },
    warning: { ...bgColors.warningLight },
    info: { ...bgColors.infoLight },
  };

  // ** State

  const [sensorStatusColor, setSensorStatusColor] = useState<ThemeColor>(
    Color.success
  );
  const [isOnline, setisOnline] = useState<boolean>(true);
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const [energyValues, setEnergyValues] = useState<IEnergy>({
    activePowerL1N: 0,
    activePowerL2N: 0,
    activePowerL3N: 0,
    currentL1: 0,
    currentL2: 0,
    currentL3: 0,
    voltageL1N: 0,
    voltageL2N: 0,
    voltageL3N: 0,
    time: 0,
  });

  // ** Utils

  // ** Context Datas
  const theme = useTheme();

  // ** Functions
  useEffect(() => {
    function onEnergyDataEvent(value: IEnergy) {
      value.voltageL1N = value.voltageL1L2 || 0;
      value.voltageL2N = value.voltageL2L3 || 0;
      value.voltageL3N = value.VoltageL3L1 || 0;
      setEnergyValues(value);
    }

    socket.on("energy", onEnergyDataEvent);

    return () => {
      socket.off("energy", onEnergyDataEvent);
    };
  }, []);

  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        lg={4}
        xl={3}
        sx={{
          display: isVisible ? "block" : "none",
        }}
      >
        <Card sx={{ height: "100%" }}>
          <CardContent sx={{ p: 2, paddingBottom: "10px !important" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Chip
                skin="light"
                label={
                  energyValues?.time
                    ? moment(energyValues?.time).format("MM.DD.YYYY HH:mm:ss")
                    : "Not updated"
                }
                color="info"
                sx={{
                  py: 2,
                  px: 1,
                  mb: 1,
                  height: 18,
                  "&.MuiChip-light": {
                    "&:hover": {
                      color: (theme: Theme) =>
                        theme.palette.customColors.customizedChipText,
                    },
                  },
                  fontSize: "0.6rem",
                  "& .MuiChip-label": {
                    fontWeight: 500,
                    lineHeight: 1,
                  },
                }}
              />
            </Box>

            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                backgroundColor: colors[sensorStatusColor].backgroundColor,
                p: 3,
                pr: 2,
                borderRadius: 1,
              }}
            >
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: isOnline
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                    color: isOnline
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                    "&::after": {
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                      animation: isOnline
                        ? "ripple 1.2s infinite ease-in-out"
                        : "",
                      border: "1px solid currentColor",
                      content: '""',
                    },
                    "@keyframes ripple": {
                      "0%": {
                        transform: "scale(.8)",
                        opacity: 1,
                      },
                      "100%": {
                        transform: "scale(2.4)",
                        opacity: 0,
                      },
                    },
                  },
                }}
              >
                <Avatar alt={"Voltage"}>
                  <BoltRounded color={sensorStatusColor} />
                </Avatar>
              </Badge>
              <Typography
                variant="subtitle2"
                sx={{
                  ml: 5,
                  fontWeight: 600,
                  color: isOnline
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                }}
              >
                Voltage
              </Typography>
            </Box>
            <Grid container mt={1} spacing={1}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ mx: 3 }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors["primary"].backgroundColor,
                      py: 0.5,
                      px: 1,
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors["primary"].color,
                      }}
                    >
                      L1-2
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 1, lineHeight: 1 }}
                    >
                      {(energyValues.voltageL1N / 1000)?.toFixed(3)}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      kV
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ mx: 3 }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors["primary"].backgroundColor,
                      py: 0.5,
                      px: 1,
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors["primary"].color,
                      }}
                    >
                      L2-3
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 1, lineHeight: 1 }}
                    >
                      {(energyValues.voltageL2N / 1000)?.toFixed(3)}{" "}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      kV
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ mx: 3 }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors["primary"].backgroundColor,
                      py: 0.5,
                      px: 1,
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors["primary"].color,
                      }}
                    >
                      L3-1
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 1, lineHeight: 1 }}
                    >
                      {(energyValues.voltageL3N / 1000)?.toFixed(3)}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      kV
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        lg={4}
        xl={3}
        sx={{
          display: isVisible ? "block" : "none",
        }}
      >
        <Card sx={{ height: "100%" }}>
          <CardContent sx={{ p: 2, paddingBottom: "10px !important" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Chip
                skin="light"
                label={
                  energyValues?.time
                    ? moment(energyValues?.time).format("MM.DD.YYYY HH:mm:ss")
                    : "Not updated"
                }
                color="info"
                sx={{
                  py: 2,
                  px: 1,
                  mb: 1,
                  height: 18,
                  "&.MuiChip-light": {
                    "&:hover": {
                      color: (theme: Theme) =>
                        theme.palette.customColors.customizedChipText,
                    },
                  },
                  fontSize: "0.6rem",
                  "& .MuiChip-label": {
                    fontWeight: 500,
                    lineHeight: 1,
                  },
                }}
              />
            </Box>

            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                backgroundColor: colors[sensorStatusColor].backgroundColor,
                p: 3,
                pr: 2,
                borderRadius: 1,
              }}
            >
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: isOnline
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                    color: isOnline
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                    "&::after": {
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                      animation: isOnline
                        ? "ripple 1.2s infinite ease-in-out"
                        : "",
                      border: "1px solid currentColor",
                      content: '""',
                    },
                    "@keyframes ripple": {
                      "0%": {
                        transform: "scale(.8)",
                        opacity: 1,
                      },
                      "100%": {
                        transform: "scale(2.4)",
                        opacity: 0,
                      },
                    },
                  },
                }}
              >
                <Avatar alt={"Current"}>
                  <FlashOnRounded color={sensorStatusColor} />
                </Avatar>
              </Badge>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 600,
                  ml: 5,
                  color: isOnline
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                }}
              >
                Current
              </Typography>
            </Box>
            <Grid container mt={1} spacing={1}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ mx: 3 }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors["primary"].backgroundColor,
                      py: 0.5,
                      px: 1,
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors["primary"].color,
                      }}
                    >
                      L1
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 1, lineHeight: 1 }}
                    >
                      {energyValues.currentL1?.toFixed(3)}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      A
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ mx: 3 }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors["primary"].backgroundColor,
                      py: 0.5,
                      px: 1,
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors["primary"].color,
                      }}
                    >
                      L2
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 1, lineHeight: 1 }}
                    >
                      {energyValues.currentL2?.toFixed(3)}{" "}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      A
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ mx: 3 }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors["primary"].backgroundColor,
                      py: 0.5,
                      px: 1,
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors["primary"].color,
                      }}
                    >
                      L3
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 1, lineHeight: 1 }}
                    >
                      {energyValues.currentL3?.toFixed(3)}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      A
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        lg={4}
        xl={3}
        sx={{
          display: isVisible ? "block" : "none",
        }}
      >
        <Card sx={{ height: "100%" }}>
          <CardContent sx={{ p: 2, paddingBottom: "10px !important" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Chip
                skin="light"
                label={
                  energyValues?.time
                    ? moment(energyValues?.time).format("MM.DD.YYYY HH:mm:ss")
                    : "Not updated"
                }
                color="info"
                sx={{
                  py: 2,
                  px: 1,
                  mb: 1,
                  height: 18,
                  "&.MuiChip-light": {
                    "&:hover": {
                      color: (theme: Theme) =>
                        theme.palette.customColors.customizedChipText,
                    },
                  },
                  fontSize: "0.6rem",
                  "& .MuiChip-label": {
                    fontWeight: 500,
                    lineHeight: 1,
                  },
                }}
              />
            </Box>

            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                backgroundColor: colors[sensorStatusColor].backgroundColor,
                p: 3,
                pr: 2,
                borderRadius: 1,
              }}
            >
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: isOnline
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                    color: isOnline
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                    "&::after": {
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                      animation: isOnline
                        ? "ripple 1.2s infinite ease-in-out"
                        : "",
                      border: "1px solid currentColor",
                      content: '""',
                    },
                    "@keyframes ripple": {
                      "0%": {
                        transform: "scale(.8)",
                        opacity: 1,
                      },
                      "100%": {
                        transform: "scale(2.4)",
                        opacity: 0,
                      },
                    },
                  },
                }}
              >
                <Avatar alt="Current">
                  <PowerRounded color={sensorStatusColor} />
                </Avatar>
              </Badge>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 600,
                  ml: 5,
                  color: isOnline
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                }}
              >
                Power
              </Typography>
            </Box>
            <Grid container mt={1} spacing={1}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ mx: 3 }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors["primary"].backgroundColor,
                      py: 0.5,
                      px: 1,
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors["primary"].color,
                      }}
                    >
                      L1-2
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 1, lineHeight: 1 }}
                    >
                      {(energyValues.voltageL1N * energyValues.currentL1 / 1000)?.toFixed(3)}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      kW
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ mx: 3 }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors["primary"].backgroundColor,
                      py: 0.5,
                      px: 1,
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors["primary"].color,
                      }}
                    >
                      L2-3
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 1, lineHeight: 1 }}
                    >
                      {(energyValues.voltageL2N * energyValues.currentL2 / 1000)?.toFixed(3)}{" "}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      kW
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ mx: 3 }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors["primary"].backgroundColor,
                      py: 0.5,
                      px: 1,
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors["primary"].color,
                      }}
                    >
                      L3-1
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 1, lineHeight: 1 }}
                    >
                      {(energyValues.voltageL3N * energyValues.currentL3 / 1000)?.toFixed(3)}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      kW
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default EnergyCard;
