// ** Component Imports

// ** Graphql Imports

// ** Library Imports
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import moment from "moment";

// ** MUI Imports
import {
  Box,
  Grid,
  Typography,
  useTheme,
  Card,
  CardContent,
} from "@mui/material";

// ** React Imports
import { useEffect, useState } from "react";
import Chip from "../mui/chip";
import DatasetIcon from '@mui/icons-material/Dataset';
// ** Types
type VibrationLiveChartProps = {
  label: string;
  xRMS: number;
  yRMS: number;
  zRMS: number;
  time: Date;
};

type SerieDataType = {
  x: Date;
  y: number;
};

const VibrationLiveChart = (props: VibrationLiveChartProps) => {
  // ** Props
  const { label, xRMS, yRMS, zRMS, time } = props;

  // ** States
  const [serieDatasForXRMS, setSerieDatasForXRMS] = useState<SerieDataType[]>(
    []
  );
  const [serieDatasForYRMS, setSerieDatasForYRMS] = useState<SerieDataType[]>(
    []
  );
  const [serieDatasForZRMS, setSerieDatasForZRMS] = useState<SerieDataType[]>(
    []
  );
  const [dataSetQuantity, setDataSetQuantity] = useState<number>(0);

  // ** Context Datas
  const theme = useTheme();

  // ** Variables
  const series = [
    {
      name: "XRMS (g)",
      data: serieDatasForXRMS,
    },
    {
      name: "YRMS (g)",
      data: serieDatasForYRMS,
    },
    {
      name: "ZRMS (g)",
      data: serieDatasForZRMS,
    },
  ];

  const options: ApexOptions = {
    chart: {
      id: " temperatureAndHumidity",
      type: "area",
      height: "100%",
    },
    xaxis: {
      labels: {
        formatter(value: string) {
          return moment(value).format("H:mm:ss");
        },
      },
    },
    markers: {
      size: 0,
    },
    dataLabels: {
      enabled: false,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: theme.palette.text.primary,
        fontSize: "18px",
      },
    },
    colors: [
      theme.palette.info.main,
      theme.palette.success.main,
      theme.palette.warning.main,
    ],
  };

  useEffect(() => {}, []);

  // ** UseEffects
  useEffect(() => {
    if (dataSetQuantity > 19) {
      setSerieDatasForXRMS([
        ...serieDatasForXRMS.slice(1),
        { x: time, y: Number(xRMS.toFixed(3)) },
      ]);
      setSerieDatasForYRMS([
        ...serieDatasForYRMS.slice(1),
        { x: time, y: Number(yRMS.toFixed(3)) },
      ]);
      setSerieDatasForZRMS([
        ...serieDatasForZRMS.slice(1),
        { x: time, y: Number(zRMS.toFixed(3)) },
      ]);

    } else {
      setSerieDatasForXRMS([
        ...serieDatasForXRMS,
        { x: time, y: Number(xRMS.toFixed(3)) },
      ]);
      setSerieDatasForYRMS([
        ...serieDatasForYRMS,
        { x: time, y: Number(yRMS.toFixed(3)) },
      ]);
      setSerieDatasForZRMS([
        ...serieDatasForZRMS,
        { x: time, y: Number(zRMS.toFixed(3)) },
      ]);
    }
    
    setDataSetQuantity(dataSetQuantity + 1);
  }, [xRMS, yRMS, zRMS, time]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: theme.palette.background.paper,
            px: 3,
            py: 2,
            borderRadius: 1,
          }}
        >
          <Typography variant="subtitle1">{label}</Typography>
          <Chip
          skin="light"
          label={`Dataset Quantity: ${dataSetQuantity}`}
          color={'primary'}
          icon={<DatasetIcon fontSize="small" />}
          sx={{
            py: 4,
            px: 1,
            height: 32,
            '&.MuiChip-light': {
              '&:hover': {
                color: (theme) => theme.palette.customColors.customizedChipText,
              }
            },
            fontSize: "0.8rem",
            "& .MuiChip-label": {
              fontWeight: 600,
              lineHeight: 1.4,
            },
          }}
        />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Card
          sx={{
            height: 300,
            maxHeight: 400,
          }}
        >
          <CardContent
            sx={{
              p: 0,
              width: "100%",
              height: "100%",
              "& .apexcharts-legend-text": {
                color: (theme) => `${theme.palette.text.primary} !important`,
              },
              "& .apexcharts-xcrosshairs.apexcharts-active": {
                opacity: 0,
              },
              "& .apexcharts-yaxis-label": {
                fill: (theme) => `${theme.palette.text.primary} !important`,
              },
              "& .apexcharts-xaxis-label": {
                fill: (theme) => `${theme.palette.text.primary} !important`,
              },
              "& .apexcharts-tooltip": {
                color: (theme) => `${theme.palette.text.primary} !important`,
              },
              "& .apexcharts-tooltip-title": {
                color: (theme) => `${theme.palette.text.primary} !important`,
                backgroundColor: (theme) =>
                  `${theme.palette.background.paper} !important`,
                border: 0,
              },
              "& .apexcharts-tooltip-box": {
                color: (theme) => `${theme.palette.text.primary} !important`,
                backgroundColor: (theme) =>
                  `${theme.palette.background.paper} !important`,
              },
              "& .apexcharts-tooltip .apexcharts-tooltip-series-group": {
                color: (theme) => `${theme.palette.text.primary} !important`,
                backgroundColor: (theme) =>
                  `${theme.palette.background.paper} !important`,
              },
            }}
          >
            <Chart
              options={options}
              series={series}
              type="area"
              height={"100%"}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default VibrationLiveChart;
