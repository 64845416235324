// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { ThemeColor } from "src/@core/layouts/types";
import { useNavigate } from "react-router-dom";
import DeviceHubRoundedIcon from "@mui/icons-material/DeviceHubRounded";
import SensorsRoundedIcon from "@mui/icons-material/SensorsRounded";
import {
  BoltRounded,
  PowerRounded,
  FlashOnRounded,
} from "@mui/icons-material";
import { IEnergy } from "src/pages/substations/SubstationView";
import { Grid, Theme, useTheme } from "@mui/material";
import useBgColor, { UseBgColorType } from "src/@core/hooks/useBgColor";
import { Color } from "src/types/enum-types/Color";
import moment from "moment";

type ModuleInfoCardProps = {
  id: string;
  title: string;
  sensorCount: number;
  description: string;
  color: ThemeColor;
  energyValues: IEnergy;
};

const ModuleInfoCard = (props: ModuleInfoCardProps) => {
  // ** Props
  const { id, title, sensorCount, energyValues } = props;

  const bgColors = useBgColor();
  const theme = useTheme();

  const colors: UseBgColorType = {
    primary: { ...bgColors.primaryLight },
    secondary: { ...bgColors.secondaryLight },
    success: { ...bgColors.successLight },
    error: { ...bgColors.errorLight },
    warning: { ...bgColors.warningLight },
    info: { ...bgColors.infoLight },
  };

  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/module/view/${id}`);
  };

  const rows = [];
  for (let i = 0; i < sensorCount; i++) {
    rows.push(
      <>
        <Avatar
          key={i}
          alt="Sensor"
          sx={{
            bgcolor: (theme: Theme) => `${theme.palette.primary.main}cc`,
          }}
        >
          <SensorsRoundedIcon
            sx={{
              color: (theme: Theme) =>
                theme.palette.customColors.customizedTextWithoutTheme,
            }}
          />
        </Avatar>
      </>
    );
  }

  return (
    <Card sx={{ position: "relative" }}>
      <CardMedia
        component="img"
        sx={{ height: "7em" }}
        image="/images/module-front-1080p.jpg"
      />
      <Box
        sx={{
          borderRadius: 1,
          bgcolor: (theme: Theme) => `${theme.palette.info.main}df`,
          position: "absolute",
          pt: 0.5,
          pb: 1,
          px: 2,
          left: "33%",
          top: 3,
        }}
      >
        <Typography
          variant="caption"
          sx={{ whiteSpace: "nowrap", color: "text.primary", fontWeight: 600 }}
        >
          {energyValues?.time
            ? moment(energyValues?.time * 1000).format("MM.DD.YYYY HH:mm:ss")
            : "Not updated"}
        </Typography>
      </Box>
      <Avatar
        alt={title}
        sx={{
          width: 64,
          height: 64,
          left: "1.3rem",
          top: "5rem",
          position: "absolute",
          bgcolor: (theme: Theme) => `${theme.palette.primary.main}cc`,
        }}
      >
        <DeviceHubRoundedIcon
          sx={{
            fontSize: 42,
            color: (theme: Theme) =>
              theme.palette.customColors.customizedTextWithoutTheme,
          }}
        />
      </Avatar>
      <Box
        sx={{
          borderRadius: 3,
          bgcolor: (theme: Theme) => `${theme.palette.primary.main}cc`,
          position: "absolute",
          gap: 2,
          pl: 3,
          py: 1,
          pr: 1,
          right: "1.3rem",
          top: "5.5rem",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ whiteSpace: "nowrap", color: "text.primary" }}
        >
          {sensorCount} sensors
        </Typography>
        <Avatar
          alt={title}
          sx={{
            width: 32,
            height: 32,
            bgcolor: (theme: Theme) =>
              theme.palette.customColors.customizedTextWithoutTheme,
          }}
        >
          <SensorsRoundedIcon
            sx={{
              fontSize: 18,
              color: (theme: Theme) => theme.palette.primary.main,
            }}
          />
        </Avatar>
      </Box>
      <CardContent>
        <Box
          sx={{
            mt: 5.75,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ mr: 2, display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1" sx={{fontWeight: 600}}>{title}</Typography>
          </Box>
          <Button onClick={onClick} variant="outlined">
            Details
          </Button>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} display="flex" alignItems="center">
            <Box
              mt={3}
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              width="100%"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                  borderRadius: 1,
                  width: "100%",
                  backgroundColor: colors[Color.primary].backgroundColor,
                }}
              >
                <BoltRounded color="primary" />
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    ml: 2,
                  }}
                >
                  Voltage
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  mt: 1,
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors["primary"].backgroundColor,
                      py: 0.5,
                      px: 1,
                      mb: 1,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors["primary"].color,
                      }}
                    >
                      L1-2
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 1, lineHeight: 1 }}
                    >
                      {energyValues?.voltageL1N
                        ? (energyValues?.voltageL1N / 1000).toFixed(3)
                        : 0}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      kV
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors["primary"].backgroundColor,
                      py: 0.5,
                      px: 1,
                      mb: 1,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors["primary"].color,
                      }}
                    >
                      L2-3
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 1, lineHeight: 1 }}
                    >
                      {energyValues?.voltageL2N
                        ? (energyValues?.voltageL2N / 1000).toFixed(3)
                        : 0}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      kV
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors["primary"].backgroundColor,
                      py: 0.5,
                      px: 1,
                      mb: 1,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors["primary"].color,
                      }}
                    >
                      L3-1
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 1, lineHeight: 1 }}
                    >
                      {energyValues?.voltageL3N
                        ? (energyValues?.voltageL3N / 1000).toFixed(3)
                        : 0}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      kV
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <Box
              mt={3}
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              width="100%"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                  borderRadius: 1,
                  width: "100%",
                  backgroundColor: colors[Color.primary].backgroundColor,
                }}
              >
                <FlashOnRounded color="primary" />
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    ml: 2,
                  }}
                >
                  Current
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  mt: 1,
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors["primary"].backgroundColor,
                      py: 0.5,
                      px: 1,
                      mb: 1,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors["primary"].color,
                      }}
                    >
                      L1
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 1, lineHeight: 1 }}
                    >
                      {energyValues?.currentL1
                        ? (energyValues?.currentL1).toFixed(3)
                        : 0}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      A
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors["primary"].backgroundColor,
                      py: 0.5,
                      px: 1,
                      mb: 1,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors["primary"].color,
                      }}
                    >
                      L2
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 1, lineHeight: 1 }}
                    >
                      {energyValues?.currentL2
                        ? (energyValues?.currentL2).toFixed(3)
                        : 0}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      A
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors["primary"].backgroundColor,
                      py: 0.5,
                      px: 1,
                      mb: 1,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors["primary"].color,
                      }}
                    >
                      L3
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 1, lineHeight: 1 }}
                    >
                      {energyValues?.currentL3
                        ? (energyValues?.currentL3).toFixed(3)
                        : 0}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      A
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <Box
              mt={3}
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              width="100%"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                  borderRadius: 1,
                  width: "100%",
                  backgroundColor: colors[Color.primary].backgroundColor,
                }}
              >
                <PowerRounded color="primary" />
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    ml: 2,
                  }}
                >
                  Power
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  mt: 1,
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors["primary"].backgroundColor,
                      py: 0.5,
                      px: 1,
                      mb: 1,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors["primary"].color,
                      }}
                    >
                      L1-2
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 1, lineHeight: 1 }}
                    >
                      {energyValues?.voltageL1N
                        ? (energyValues.voltageL1N * energyValues.currentL1 / 1000)?.toFixed(3)
                        : 0}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      kW
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors["primary"].backgroundColor,
                      py: 0.5,
                      px: 1,
                      mb: 1,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors["primary"].color,
                      }}
                    >
                      L2-3
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 1, lineHeight: 1 }}
                    >
                      {energyValues?.voltageL2N
                        ? (energyValues.voltageL2N * energyValues.currentL2 / 1000)?.toFixed(3)
                        : 0}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      kW
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{ mb: 1 }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors["primary"].backgroundColor,
                      py: 0.5,
                      px: 1,
                      mb: 1,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: colors["primary"].color,
                      }}
                    >
                      L3-1
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 1, lineHeight: 1 }}
                    >
                      {energyValues?.voltageL3N
                        ? (energyValues.voltageL3N * energyValues.currentL3 / 1000)?.toFixed(3)
                        : 0}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      kW
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ModuleInfoCard;
ModuleInfoCard.defaultProps = {
  color: "primary",
};
