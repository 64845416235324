// ** Component Imports
import FButtonToggleChart from "../form-field/button-toggle/FButtonToggleChart";

// ** Graphql Imports
import {
  ChartDataGroupType,
  useGetDatasForVibrationChartLazyQuery,
} from "src/generated/graphql";

// ** Library Imports
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import moment from "moment";

// ** MUI Imports
import {
  Box,
  Grid,
  Typography,
  useTheme,
  Card,
  CardContent,
} from "@mui/material";

// ** React Imports
import { useEffect, useState } from "react";

// ** Types
type VibrationChartProps = {
  sensorId: string;
  label: string;
  title: string;
};

type SerieDataType = {
  x: string;
  y: number;
};

const VibrationChart = (props: VibrationChartProps) => {
  // ** Props
  const { sensorId, label, title } = props;

  // ** Context Datas
  const theme = useTheme();

  // ** States
  const [serieDatasForXRMS, setSerieDatasForXRMS] = useState<SerieDataType[]>(
    []
  );
  const [serieDatasForYRMS, setSerieDatasForYRMS] = useState<SerieDataType[]>(
    []
  );
  const [serieDatasForZRMS, setSerieDatasForZRMS] = useState<SerieDataType[]>(
    []
  );
  
  const options: ApexOptions = {
    title: {
      text: title,
      margin: 15,
      align: 'center',
      style: {
        color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.text.secondary
      }
    },
    chart: {
      id: " vibration",
      type: "area",
      height: "100%",
      background: theme.palette.background.paper
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
        datetimeFormatter: {
          year: 'yyyy',
          month: 'MMM \'yy',
          day: 'dd MMM',
          hour: 'HH:mm',
          minute: 'HH:mm',
        }
      }
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: theme.palette.text.primary,
        fontSize: "18px",
      },
    },
    tooltip: {
      x: {
        formatter(value: number) {
          return moment(value).format("L, H:mm");
        },
      }
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      theme.palette.info.main,
      theme.palette.success.main,
      theme.palette.warning.main,
    ],
  };

  const [toggleValue, setToggleValue] = useState<ChartDataGroupType>(
    ChartDataGroupType.OneDay
  );

  // ** Utils
  const [GetDatasForVibrationChart] = useGetDatasForVibrationChartLazyQuery();

  // ** Variables
  const series = [
    {
      name: "xRMS (g)",
      data: serieDatasForXRMS,
    },
    {
      name: "yRMS (g)",
      data: serieDatasForYRMS,
    },
    {
      name: "zRMS (g)",
      data: serieDatasForZRMS,
    },
  ];

  // ** UseEffects
  useEffect(() => {

    GetDatasForVibrationChart({
      variables: {
        sensorId: sensorId,
        chartDataGroup: toggleValue,
      },
      fetchPolicy: "network-only",
    }).then((item) => {
      setSerieDatasForXRMS(
        item.data?.getDatasForVibrationChart.xRMSData as SerieDataType[]
      );
      setSerieDatasForYRMS(
        item.data?.getDatasForVibrationChart.yRMSData as SerieDataType[]
      );
      setSerieDatasForZRMS(
        item.data?.getDatasForVibrationChart.zRMSData as SerieDataType[]
      );
    });
  }, [toggleValue, setToggleValue, GetDatasForVibrationChart, sensorId]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 3,
            py: 1,
            borderRadius: 1,
          }}
        >
          <Typography variant="subtitle1">{label}</Typography>
          <FButtonToggleChart
            toggleValue={toggleValue}
            setToggleValue={setToggleValue}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Card
          sx={{
            height: 300,
            maxHeight: 400,
          }}
        >
          <CardContent
            sx={{
              p: 0,
              width: "100%",
              height: "100%",
              "& .apexcharts-legend-text": {
                color: (theme) => `${theme.palette.text.primary} !important`,
              },
              "& .apexcharts-xcrosshairs.apexcharts-active": {
                opacity: 0,
              },
              "& .apexcharts-yaxis-label": {
                fill: (theme) => `${theme.palette.text.primary} !important`,
              },
              "& .apexcharts-xaxis-label": {
                fill: (theme) => `${theme.palette.text.primary} !important`,
              },
              "& .apexcharts-tooltip": {
                color: (theme) => `${theme.palette.text.primary} !important`,
              },
              "& .apexcharts-tooltip-title": {
                color: (theme) => `${theme.palette.text.primary} !important`,
                backgroundColor: (theme) =>
                  `${theme.palette.background.paper} !important`,
                border: 0,
              },
              "& .apexcharts-tooltip-box": {
                color: (theme) => `${theme.palette.text.primary} !important`,
                backgroundColor: (theme) =>
                  `${theme.palette.background.paper} !important`,
              },
              "& .apexcharts-tooltip .apexcharts-tooltip-series-group": {
                color: (theme) => `${theme.palette.text.primary} !important`,
                backgroundColor: (theme) =>
                  `${theme.palette.background.paper} !important`,
              },
              "& .apexcharts-menu": {
                color: (theme) => `${theme.palette.text.primary} !important`,
                backgroundColor: (theme) =>
                  `${theme.palette.background.paper} !important`,
                border: 0,
              },
              "& .apexcharts-menu-item:hover": {
                backgroundColor: (theme) =>
                  `${theme.palette.text.secondary} !important`,
                color: (theme) => `${theme.palette.text.primary} !important`,
              }
            }}
          >
            <Chart
              options={options}
              series={series}
              type="area"
              height={"100%"}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default VibrationChart;
