import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import Chip from "../mui/chip";
import { Color } from "src/types/enum-types/Color";
import ChangeCircleRoundedIcon from '@mui/icons-material/ChangeCircleRounded';

interface Props {
  label: string;
  text: string;
  color: Color;
  onChange: any;
}

export const FToggleChip = (props: Props) => {
  const { onChange, label, text, color } = props;

  return (
    <Grid container alignItems="center" justifyContent="space-between" sx={{ maxWidth: "600px" }}>
      <Grid item xs={6}>
        <Typography variant="subtitle2">{text}</Typography>
      </Grid>
      <Grid item xs={6} display="flex" justifyContent="right">
        <Chip
          onClick={onChange}
          skin="light"
          label={label}
          color={color}
          icon={<ChangeCircleRoundedIcon fontSize="small" />}
          sx={{
            py: 4,
            px: 1,
            height: 32,
            '&.MuiChip-light': {
              '&:hover': {
                color: (theme) => theme.palette.customColors.customizedChipText,
              }
            },
            fontSize: "0.8rem",
            "& .MuiChip-label": {
              fontWeight: 600,
              lineHeight: 1.4,
            },
          }}
        />
      </Grid>
    </Grid>
  );
};
