// ** React Imports
import React, { SyntheticEvent, useEffect, useState, useCallback } from "react";

// ** MUI Imports
import Grid from "@mui/material/Grid";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  styled,
  Chip,
  Stack,
  IconButton,
  Tooltip,
  AvatarGroup,
  Card,
  CardContent,
} from "@mui/material";
import MuiTab, { TabProps } from "@mui/material/Tab";

// ** Utils Import
import Image from "mui-image";
import ElectricMeterRoundedIcon from "@mui/icons-material/ElectricMeterRounded";

// ** Component Imports
import CustomAvatar from "src/@core/components/mui/avatar";

// ** Icon Imports
import GpsFixedRoundedIcon from "@mui/icons-material/GpsFixedRounded";

// ** Library Imports
import { useModuleLazyQuery, useSensorsLazyQuery } from "src/generated/graphql";
import { SensorType } from "src/types/graphql/sensorType";
import GoogleMapReact from "google-map-react";
import {
  DeviceHubRounded,
  ViewSidebarRounded,
  ViewWeekRounded,
} from "@mui/icons-material";
import { socket } from "src/socket";
import SensorCard from "src/@core/components/card/SensorCard";
import SensorBadge from "src/@core/components/badge/SensorBadge";
import { useParams } from "react-router-dom";
import { ModuleType } from "src/types/graphql/moduleType";
import EnergyCard from "src/@core/components/card/EnergyCard";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";

export enum IColorsType {
  active = "success",
  pending = "warning",
  inactive = "secondary",
}

const MapMark = (props: any) => (
  <Box sx={{ position: "relative" }}>
    <Card
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        cursor: "pointer",
        backgroundColor: "#1B2441CC",
        "&:hover": {
          backgroundColor: "#1B2441",
        },
      }}
    >
      <CardContent
        sx={{
          width: 40,
          height: 40,
          p: 0,
          m: 0,
        }}
      >
        <Tooltip title={props.title}>
          <CustomAvatar
            skin="light"
            color="primary"
            sx={{
              width: 40,
              height: 40,
              borderRadius: "7px !important",
            }}
          >
            <DeviceHubRounded />
          </CustomAvatar>
        </Tooltip>
      </CardContent>
    </Card>
  </Box>
);

const Tab = styled(MuiTab)<TabProps>(({ theme }) => ({
  "& svg": {
    marginBottom: "0 !important",
    marginRight: theme.spacing(3),
  },
}));

const ModuleViewPage = () => {
  const defaultProps = {
    center: {
      lat: 44.333080173284976,
      lng: 26.838590382254353,
    },
    zoom: 9,
  };

  // ** States
  const [Sensors] = useSensorsLazyQuery();
  const [Module] = useModuleLazyQuery();

  const params = useParams();

  // ** States
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [sensors, setSensors] = useState<SensorType[]>([]);
  const [module, setModule] = useState<ModuleType>({
    id: "",
    name: "",
    latitude: "",
    longitude: "",
  });

  const customMarkerIcon = new Icon({
    iconUrl: '/module.png',
    iconSize: [36, 36]
  });

  const [onlineSensors, setOnlineSensors] = useState<string[]>([]);

  const [value, setValue] = useState<string>("module-front");
  const [visibityOfModule, setVisibilityOfModule] = useState<boolean>(true);

  // ** Utils Functions

  // ** Functions

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const changeVisibilityOfModule = () => {
    setVisibilityOfModule(!visibityOfModule);
  };

  const fetchModule = () => {
    Module({
      variables: {
        id: params.moduleId as string,
      },
      fetchPolicy: "network-only",
    }).then((res) => {
      const module = res.data?.module;

      if (module) {
        setModule({
          id: module.id,
          name: module.name,
          latitude: module.latitude,
          longitude: module.longitude,
        });
      }
    });
  };

  const fetchTableData = useCallback(() => {
    Sensors({
      variables: {
        moduleId: params.moduleId,
      },
      fetchPolicy: "cache-and-network",
    }).then((res) => {
      setTotalRowCount(res.data?.sensors.count || 0);
      setSensors(res.data?.sensors.items || ([] as SensorType[]));
    });
  }, [Sensors]);

  useEffect(() => {
    fetchModule();
  }, []);

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  useEffect(() => {
    function onSensorDataEvent(value: string[]) {
      console.log(value);
      setOnlineSensors(value);
    }

    socket.on("online-sensors", onSensorDataEvent);
    socket.emit("action", "get-online-sensors");

    return () => {
      socket.off("online-sensors", onSensorDataEvent);
    };
  }, []);

  return (
    <>
      <TabContext value={value}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              sx={{
                width: "100%",
                display: visibityOfModule ? "flex" : "none",
              }}
            >
              <Tab
                value="module-front"
                label="Module Front"
                iconPosition="start"
                icon={<ElectricMeterRoundedIcon />}
              />
              <Tab
                value="module-back"
                label="Module Back"
                iconPosition="start"
                icon={<ElectricMeterRoundedIcon />}
              />
              <Tab
                value="location"
                label="Location"
                iconPosition="start"
                icon={<GpsFixedRoundedIcon />}
              />
            </TabList>
            <Box p={3}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
              >
                <Chip
                  variant="outlined"
                  color="primary"
                  sx={{
                    fontWeight: 600,
                  }}
                  label={`Total number of sensors: ${totalRowCount}`}
                />
                <Tooltip
                  title={visibityOfModule ? "Hide Module" : "Show Module"}
                >
                  <IconButton onClick={changeVisibilityOfModule}>
                    {visibityOfModule ? (
                      <ViewWeekRounded />
                    ) : (
                      <ViewSidebarRounded />
                    )}
                  </IconButton>
                </Tooltip>
              </Stack>
            </Box>
          </Grid>
          <Grid
            sx={{ display: visibityOfModule ? "block" : "none" }}
            item
            xs={12}
            md={6}
          >
            <Box sx={{ mt: 0 }}>
              <TabPanel sx={{ p: 0 }} value="module-front">
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      zIndex: 10,
                    }}
                  >
                    <AvatarGroup max={10}>
                      {sensors.map((item, index) => (
                        <SensorBadge
                          key={index}
                          id={item.id}
                          name={item.name}
                          serialNumber={item.serialNumber}
                          onlineSensors={onlineSensors}
                        />
                      ))}
                    </AvatarGroup>
                  </Box>
                  <Image
                    src="/images/transformator-front.jpeg"
                    height="calc(100vh - 185px)"
                  />
                </Box>
              </TabPanel>
              <TabPanel sx={{ p: 0 }} value="module-back">
                <Box sx={{ position: "relative" }}>
                  <Image
                    src="/images/transformator-back.jpeg"
                    height="calc(100vh - 185px)"
                  />
                </Box>
              </TabPanel>
              <TabPanel sx={{ p: 0 }} value="location">
                <Box width={"100%"}>
                  <div
                    style={{
                      margin: 0,
                      padding: 0,
                      height: "calc(100vh - 190px)",
                      width: "100%",
                    }}
                  >
                    {module && 
                      <MapContainer center={[Number(module?.latitude) || 0, Number(module?.longitude) || 0]} zoom={15} scrollWheelZoom={false}>
                        <TileLayer
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                          <Marker icon={customMarkerIcon} position={[Number(module?.latitude) || 0, Number(module?.longitude) || 0]} >
                            <Popup>
                              {module.name}
                            </Popup>
                          </Marker>
                      </MapContainer> }
                    {/* <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyDAQIns994YxmiHuh1S2XVFDtg53mOCEX8",
                      }}
                      defaultCenter={defaultProps.center}
                      defaultZoom={defaultProps.zoom}
                    >
                      <MapMark
                        lat={module.latitude}
                        lng={module.longitude}
                        text={module.name}
                      />
                    </GoogleMapReact> */}
                  </div>
                </Box>
              </TabPanel>
            </Box>
          </Grid>

          <Grid item xs={12} md={visibityOfModule ? 6 : 12}>
            <Box
              sx={{
                height: "calc(100vh - 175px)",
                maxHeight: "calc(100vh - 175px)",
                overflow: "scroll",
              }}
            >
              <Grid container spacing={2} sx={{ paddingTop: 0 }}>
                <EnergyCard />
                {sensors.map((item, index) => (
                  <SensorCard
                    key={index}
                    id={item.id}
                    name={item.name}
                    onlineSensors={onlineSensors}
                    serialNumber={item.serialNumber}
                    visibityOfModule={visibityOfModule}
                  />
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </TabContext>
    </>
  );
};

export default ModuleViewPage;
