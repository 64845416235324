


// ** Icon Imports
import { ChartDataGroupType } from "src/generated/graphql";

// ** MUI Imports
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MuiToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";

// ** React Imports
import { MouseEvent } from "react";


// Styled Components
const ToggleButton = styled(MuiToggleButton)<ToggleButtonProps>(
  ({ theme }) => ({
    margin: theme.spacing(1),
    border: "none !important",
    "&:not(:first-of-type)": {
      borderRadius: `${theme.shape.borderRadius}px !important`,
    },
    "&:first-of-type": {
      borderRadius: `${theme.shape.borderRadius}px !important`,
    },
  })
);

type FButtonToggleChartProps = {
  toggleValue: string;
  setToggleValue: any;
};

const FButtonToggleChart = (props: FButtonToggleChartProps) => {
  // ** States
  const { toggleValue, setToggleValue } = props;

  // ** Functions
  const handleAlignment = (
    event: MouseEvent<HTMLElement>,
    pValue: string | null
  ) => {
    if (pValue === null) {
      setToggleValue(pValue);
    } else {
      setToggleValue(pValue);
    }
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={toggleValue}
        onChange={handleAlignment}
        aria-label="charts-button-toggle"
        sx={{
          height: 30,
        }}
      >
        <ToggleButton value={ChartDataGroupType.OneDay} aria-label="one-day">
          1D
        </ToggleButton>
        <ToggleButton value={ChartDataGroupType.OneWeek} aria-label="one-week">
          7D
        </ToggleButton>
        <ToggleButton
          value={ChartDataGroupType.OneMonth}
          aria-label="one-month"
        >
          1M
        </ToggleButton>
        <ToggleButton value={ChartDataGroupType.OneYear} aria-label="one-year">
          1Y
        </ToggleButton>
        <ToggleButton value={ChartDataGroupType.All} aria-label="all">
          All
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default FButtonToggleChart;
