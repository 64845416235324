// ** Icon Imports
import ElectricMeterRoundedIcon from "@mui/icons-material/ElectricMeterRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import SensorsRoundedIcon from "@mui/icons-material/SensorsRounded";
import AirplayRoundedIcon from "@mui/icons-material/AirplayRounded";
import BuildRoundedIcon from "@mui/icons-material/BuildRounded";
import DeviceHubRoundedIcon from "@mui/icons-material/DeviceHubRounded";
import EqualizerRoundedIcon from "@mui/icons-material/EqualizerRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";

// ** Types Imports
import { VerticalNavItemsType } from "src/@core/layouts/types";

const navigation = (): VerticalNavItemsType => {
  const sectionTitleForOverviewPage = {
    sectionTitle: "Overview",
  };

  const overviewPage = {
    title: "Dashboard",
    icon: AirplayRoundedIcon,
    path: "/dashboard",
  };

  const sectionTitleForDetail = {
    sectionTitle: "Detail",
  };

  const substations = {
    title: "Substations",
    icon: ElectricMeterRoundedIcon,
    path: "/substations",
  };

  const modules = {
    title: "Modules",
    path: "/modules",
    icon: DeviceHubRoundedIcon,
  };

  const sensors = {
    title: "Sensors",
    path: "/sensors",
    icon: SensorsRoundedIcon,
  };

  const sectionTitleForMaintenance = {
    sectionTitle: "Maintenance",
  };

  const maintenanceDepartment = {
    title: "Maintenance",
    path: "/maintenance",
    icon: BuildRoundedIcon,
  };

  const sectionTitleForReport = {
    sectionTitle: "Report",
  };

  const reports = {
    title: "Reports",
    path: "/reports",
    icon: EqualizerRoundedIcon,
  };

  const sectionTitleForManagement = {
    sectionTitle: "Management",
  };

  const users = {
    title: "Users",
    path: "/users",
    icon: PeopleAltRoundedIcon,
  };

  const sectionTitleForHelp = {
    sectionTitle: "Help",
  };

  const help = {
    title: "Help",
    icon: QuestionMarkRoundedIcon,
    path: "/help",
  };

  return [
    sectionTitleForOverviewPage,
    overviewPage,
    sectionTitleForDetail,
    substations,
    modules,
    sensors,
    sectionTitleForMaintenance,
    maintenanceDepartment,
    sectionTitleForReport,
    reports,
    sectionTitleForManagement,
    users,
    sectionTitleForHelp,
    help,
  ];
};

export default navigation;
