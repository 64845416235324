import React, { forwardRef, VFC } from "react";
import ToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";

type TooltipToggleButtonProps = ToggleButtonProps & {
  TooltipProps: Omit<TooltipProps, "children">;
};

// Catch props and forward to ToggleButton
// eslint-disable-next-line react/display-name
const FTooltipToggleButton: VFC<TooltipToggleButtonProps> = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-shadow, react/prop-types
  ({ TooltipProps, ...props }, ref) => {
    return (
      <Tooltip {...TooltipProps}>
        <ToggleButton ref={ref} {...props} />
      </Tooltip>
    );
  }
);

export default FTooltipToggleButton;
