import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import { TextField } from '@mui/material';
import DatePicker, { registerLocale } from 'react-datepicker'
import { DateType } from 'src/types/forms/reactDatepickerTypes'
import DatePickerWrapper from 'src/@core/styles/libs/react-datepicker';
import { tr } from 'date-fns/locale'
import { useTranslation } from 'react-i18next';

// ** Custom Component Imports

interface Props {
    name: string;
    type?: string;
    placeholder?: string;
    label: string;
    active?: string
    dateFormat?: string
    setActive?: (value: React.SetStateAction<string>) => void
    [x: string]: unknown
}

const FDatePicker = (props: Props) => {
    const [field, meta, helpers] = useField(props);
    const [value, setValue] = useState<DateType>()

    const {i18n} = useTranslation();

    registerLocale(i18n.language, tr)

    useEffect(() => {
        setValue(new Date())
    }, [])

    const onFocus = () => {
        if (props.setActive)
            props.setActive(props.name)
    }

    
    return (
        <>
            <DatePickerWrapper>
                <DatePicker
                    {...field}
                    {...props}
                    showTimeSelect
                    selected={value}
                    locale={i18n.language}
                    dateFormat={props.dateFormat}
                    timeFormat="HH:mm"
                    onChange={(date: Date) => {
                        setValue(date)
                        helpers.setValue(date)
                    }}
                    customInput={<TextField
                        fullWidth
                        onFocus={onFocus}
                        error={meta.touched && Boolean(meta.error)}
                        helperText={meta.touched && meta.error}
                        label={props.label} />}
                />
            </DatePickerWrapper>
        </>

    )
}

FDatePicker.defaultProps = {
    dateFormat: 'EEEE, dd MMMM yyyy - HH:mm',
};

export default FDatePicker
