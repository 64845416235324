// ** Type Imports
import { PaletteMode } from '@mui/material'
import { Skin, ThemeColor } from 'src/@core/layouts/types'

const DefaultPalette = (mode: PaletteMode, skin?: Skin, themeColor?: ThemeColor) => {
  // ** Vars
  const lightColor = '0, 0, 0'
  const darkColor = '255,255,255'
  const mainColor = mode === 'light' ? lightColor : darkColor

  const primaryGradient = () => {
    if (themeColor === 'primary') {
      return '#00888F'
    } else if (themeColor === 'secondary') {
      return '#9C9FA4'
    } else if (themeColor === 'success') {
      return '#93DD5C'
    } else if (themeColor === 'error') {
      return '#FF8C90'
    } else if (themeColor === 'warning') {
      return '#FFCF5C'
    } else {
      return '#00888F'
    }
  }

  const defaultBgColor = () => {
    if (mode === 'light') {
      return '#F7F7F7'
    } else return '#161A2E'
  }

  return {
    customColors: {
      dark: darkColor,
      main: mainColor,
      light: lightColor,
      darkBg: '#1B2441',
      lightBg: '#F4F5FA',
      appBar: mode === 'light' ? '#fff' : '#1B2441',
      sidebar: mode === 'light' ? '#fff' : '#1B2441',
      primaryGradient: primaryGradient(),
      bodyBg: mode === 'light' ? '#F4F5FA' : '#1B2441', // Same as palette.background.default but doesn't consider bordered skin
      tableHeaderBg: mode === 'light' ? '#f1f2f4' : '#1B2441',
      cardHeaderBg: mode === 'light' ? '#33a0a5' : '#33a0a5',
      dialogHeaderText: '#FFFFFF',
      CurrentText: mode === 'light' ? '#000000' : '#FFFFFF',
      customizedChipText: '#eefae6',
      customizedTextWithoutTheme: 'rgba(255,255,255, 0.85)',
      customizedComponentWithoutTheme: 'rgba(255,255,255, 0.54)',
      customizedPrimaryLightBackground: 'rgba(255, 41, 56, 0.12)'

    },
    common: {
      black: '#000',
      white: '#FFF',
    },
    mode: mode,
    primary: {
      light: '#00888F',
      main: '#00888F',
      dark: '#00768F',
      contrastText: '#FFF'
    },
    secondary: {
      light: '#9C9FA4',
      main: '#8A8D93',
      dark: '#777B82',
      contrastText: '#FFF'
    },
    success: {
      light: '#6AD01F',
      main: '#56CA00',
      dark: '#4CB200',
      contrastText: '#FFF'
    },
    error: {
      light: '#FF6166',
      main: '#FF4C51',
      dark: '#E04347',
      contrastText: '#FFF'
    },
    warning: {
      light: '#FFCA64',
      main: '#FFB400',
      dark: '#E09E00',
      contrastText: '#FFF'
    },
    info: {
      light: '#32BAFF',
      main: '#16B1FF',
      dark: '#139CE0',
      contrastText: '#FFF'
    },
    text: {
      primary: `rgba(${mainColor}, 0.87)`,
      secondary: `rgba(${mainColor}, 0.68)`,
      disabled: `rgba(${mainColor}, 0.38)`
    },
    divider: `rgba(${mainColor}, 0.12)`,
    background: {
      paper: mode === 'light' ? '#FFF' : '#1B2441',
      default: defaultBgColor()
    },
    action: {
      active: `rgba(${mainColor}, 0.54)`,
      hover: `rgba(${mainColor}, 0.04)`,
      selected: `rgba(${mainColor}, 0.08)`,
      disabled: `rgba(${mainColor}, 0.3)`,
      disabledBackground: `rgba(${mainColor}, 0.18)`,
      focus: `rgba(${mainColor}, 0.12)`
    }
  }
}

export default DefaultPalette
