// ** React Import
import { useState, MouseEvent, useEffect } from "react";

// ** MUI Imports
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

interface Props {
  columns: GridColDef[];
  rows: any;
  onSelectedRow: any;
  onUpdate?: any;
  onDelete?: any;
  onDetail?: any;
  resetSelectedRow: boolean;
  setResetRowSelected: any;
  rowCountState: number;
  paginationModel: any;
  onPaginationModelChange: any;
  loading: any;
  onCellClick?: any;
}

export default function DataGridSpeacial(props: Props) {
  const {
    columns,
    rows,
    onSelectedRow,
    onUpdate,
    onDelete,
    onDetail,
    resetSelectedRow,
    setResetRowSelected,
    rowCountState,
    paginationModel,
    onPaginationModelChange,
    loading,
    onCellClick
  } = props;

  const [selectedRowId, setSelectedRowId] = useState<string>();
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: MouseEvent) => {
    event.preventDefault();
    setSelectedRowId(String(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  useEffect(() => {
    if (resetSelectedRow) {
      setRowSelectionModel([]);
      setResetRowSelected(false);
    }
  }, [resetSelectedRow, setResetRowSelected]);

  const onUpdateHandle = () => {
    onUpdate(selectedRowId);
    handleClose();
  };

  const onDeleteHandle = () => {
    onDelete(selectedRowId);
    handleClose();
  };

  const onDetailHandle = () => {
    onDetail(selectedRowId);
    handleClose();
  };

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        autoHeight
        onCellClick={onCellClick}
        checkboxSelection
        loading={loading}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationMode="server"
        rowCount={rowCountState}
        rows={rows}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
          onSelectedRow(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
          sorting: {
            sortModel: [
              {
                field: "updatedAt",
                sort: "desc",
              },
            ],
          },
        }}
        columns={columns}
        onRowDoubleClick={(event) => {
          onDetail(event.row.id);
        }}
        slotProps={{
          row: {
            onContextMenu: handleContextMenu,
            style: { cursor: "context-menu" },
          },
        }}
      />
      <Menu
        sx={{}}
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        slotProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
          },
        }}
      >
        <MenuItem sx={{p:3}} onClick={onUpdateHandle} disableRipple>
          <EditRoundedIcon sx={{mr: 3}} />
          Update
        </MenuItem>
        <MenuItem sx={{p:3}} onClick={onDeleteHandle} disableRipple>
          <DeleteRoundedIcon sx={{mr: 3}} />
          Delete
        </MenuItem>
        <MenuItem sx={{p:3}} onClick={onDetailHandle} disableRipple>
          <InfoRoundedIcon sx={{mr: 3}} />
          Show Detail
        </MenuItem>
      </Menu>
    </div>
  );
}
