import { DownloadRounded } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { string, number } from "yup";
import { hexToRGBA } from "src/@core/utils/hex-to-rgba";
import { SelectedSensorType } from "src/pages/sensors/Sensors";
import FDatePicker from "../form-field/FDatePicker";
import IntervalSelectField from "../form-field/IntervalSelectField";
import axios from "axios";

type SensorDownloadDetailProps = {
  modelInfo: SelectedSensorType;
  openSensorDownloadDetailState: boolean;
  setOpenSensorDownloadDetailState: (
    value: React.SetStateAction<boolean>
  ) => void;
};

type ISensorDownload = {
  startingDate: Date;
  endingDate: Date;
  interval: number;
};

// ** Validation Schema
const validationSchema = yup.object().shape({
  startingDate: string().required("Starting date required"),
  endingDate: string().required("Ending date required"),
  interval: number().required("interval required"),
});

// ** Styled Components
const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: hexToRGBA(theme.palette.customColors.dialogHeaderText, 0.85),
}));

const SensorDownloadDetail = (props: SensorDownloadDetailProps) => {
  const {
    modelInfo,
    openSensorDownloadDetailState,
    setOpenSensorDownloadDetailState,
  } = props;

  const [initialValues, setInitialValues] = useState<ISensorDownload>({
    startingDate: new Date(),
    endingDate: new Date(),
    interval: 60,
  });

  const [firstDateISODate, setFirstDateISODate] = useState<string>(
    new Date().toLocaleString()
  );

  const [lastDateISODate, setLastDateISODate] = useState<string>(
    new Date().toISOString()
  );

  const onSubmit = (values: ISensorDownload) => {
    window.open(
      `${process.env.REACT_APP_API_DOMAIN}/api/sensor/getSensorExportCSV?sensorId=${modelInfo.id}&startingDate=${values.startingDate}&endingDate=${values.endingDate}&interval=${values.interval}`
    );

    setOpenSensorDownloadDetailState(false);
  };

  return (
    <Dialog
      fullWidth
      open={openSensorDownloadDetailState}
      maxWidth="xs"
      scroll="body"
      onClose={() => setOpenSensorDownloadDetailState(false)}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <DialogTitle sx={{ p: 0, mb: 5 }}>
            <Title p={5}>Sensor Datas Download</Title>
          </DialogTitle>
          <DialogContent sx={{ paddingTop: "10px !important" }}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography>{modelInfo.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <FDatePicker
                  name="startingDate"
                  label="starting Date"
                  setISODate={setFirstDateISODate}
                />
              </Grid>
              <Grid item xs={12}>
                <FDatePicker
                  name="endingDate"
                  label="Ending Date"
                  setISODate={setLastDateISODate}
                />
              </Grid>
              <Grid item xs={12}>
                <IntervalSelectField
                  name="interval"
                  value={{
                    id: 60,
                    name: "1 Minute",
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "end",
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setOpenSensorDownloadDetailState(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              startIcon={<DownloadRounded />}
            >
              Download
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default SensorDownloadDetail;
