// ** React Imports
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useReducer,
  useContext,
} from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import {
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  styled,
} from "@mui/material";

// ** Icon Imports
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

// ** Graphql Imports
import {
  SensorUncheckedCreateInput,
  SensorUncheckedUpdateInput,
  SensorsDocument,
  useCreateSensorMutation,
  useChangeSensorDeletedStateMutation,
  useUpdateSensorMutation,
  useSensorLazyQuery,
  useSensorsLazyQuery,
  useChangeSensorDisabledStateMutation,
} from "src/generated/graphql";

// ** Util Imports
import { hexToRGBA } from "src/@core/utils/hex-to-rgba";

// ** Context Imports
import { GlobalContext } from "src/context/GlobalContext";
import { ModalContext } from "src/context/modalContext";

// ** Type Imports
import { Color } from "src/types/enum-types/Color";
import { SensorType } from "src/types/graphql/sensorType";
import { StateDelete } from "src/types/enum-types/StateDelete";

// Component Imports
import { FTextField } from "src/@core/components/form-field/FTextField";
import PendingToast from "src/@core/components/toast/PendingToast";
import SuccessToast from "src/@core/components/toast/SuccessToast";
import ErrorToast from "src/@core/components/toast/ErrorToast";
import DataGridSpeacial from "src/@core/components/data-grid/DataGridSpeacial";
import DeleteToast from "src/@core/components/toast/DeleteToast";
import ModuleAutocompleteField from "src/@core/components/form-field/AutoComplete/ModuleAutocompleteField";

// ** Library Imports
import moment from "moment";
import * as yup from "yup";
import { string } from "yup";
import { Field, Form, Formik, useFormikContext } from "formik";
import { toast } from "react-toastify";
import Chip from "src/@core/components/mui/chip";
import { useNavigate } from "react-router-dom";

// ** Validation Schema
const validationSchema = yup.object().shape({
  name: string().required("Sensor name required"),
  serialNumber: string().required("Serial number name required"),

});

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: hexToRGBA(theme.palette.customColors.dialogHeaderText, 0.85),
}));

const AutoChange = () => {
  const { values, setFieldValue } = useFormikContext<
    SensorUncheckedCreateInput | SensorUncheckedUpdateInput
  >();
  useEffect(() => {
    setFieldValue("name", values.name);
  }, [values.name]);

  return null;
};

const ReportsPage = () => {
  // ** Context Datas
  const { setSensorModal, sensorModal } = useContext(ModalContext);
  const { setToastDesignColor } = useContext(GlobalContext);
  const navigate = useNavigate();

  // ** States
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [rowCountState, setRowCountState] = useState(totalRowCount || 0);
  const [rows, setRows] = useState<SensorType[]>([]);
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [selectedRowClone, setSelectedRowClone] = useState<string[]>([]);
  const [resetRowSelected, setResetRowSelected] = useState(false);
  const [isNew, setIsNew] = useState<boolean>(true);
  const [oneWillBeDeleted, setOneWillBeDeleted] = useState<boolean>(false);
  const [sensorId, setSensorId] = useState<string>("new");
  const [initialValues, setInitialValues] = useState<
    SensorUncheckedCreateInput | SensorUncheckedUpdateInput
  >({
    name: "",
    serialNumber: "",
    description: "",
  } as SensorUncheckedCreateInput);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  // ** Utils Functions
  const [Sensors, {loading}] = useSensorsLazyQuery();
  const [Sensor] = useSensorLazyQuery();
  const [CreateSensor] = useCreateSensorMutation();
  const [UpdateSensor] = useUpdateSensorMutation();
  const [ChangeDisableStateSensor] = useChangeSensorDisabledStateMutation();
  const [DeleteSensor] = useChangeSensorDeletedStateMutation();

  // ** DataGrid Column Design
  const columns = useMemo<GridColDef[]>(
    () => [
      {
        flex: 0.11,
        minWidth: 100,
        field: "name",
        headerName: "Sensor Name",
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              variant="body2"
              sx={{ color: "text.primary", fontWeight: 600 }}
            >
              {row.name}
            </Typography>
          );
        },
      },
      {
        flex: 0.11,
        minWidth: 100,
        field: "serialNumber",
        headerName: "Serial Number",
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              variant="body2"
              sx={{ color: "text.primary", fontWeight: 600 }}
            >
              {row.serialNumber}
            </Typography>
          );
        },
      },
      {
        flex: 0.11,
        minWidth: 100,
        field: "module",
        headerName: "Module",
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              variant="body2"
              sx={{ color: "text.primary", fontWeight: 600 }}
            >
              {row.module.name}
            </Typography>
          );
        },
      },
      {
        flex: 0.175,
        minWidth: 110,
        field: "updatedAt",
        headerName: "Last Updated Date",
        align: "right",
        headerAlign: "right",
        renderCell: (params: GridRenderCellParams) => (
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            {moment(params.row.updatedAt).fromNow()}
          </Typography>
        ),
      },
      {
        flex: 0.05,
        minWidth: 100,
        field: "action",
        headerName: "Disabled",
        align: "center",
        headerAlign: "center",
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const onChangeDisableState = (e: any) => {
            e.stopPropagation(); // don't select this row after clicking
            changeDisableStateOne(params.row.id, params.row.isDisabled);
          };

          return (
            <Chip
              onClick={onChangeDisableState}
              skin="light"
              label={params.row.isDisabled ? "Inactive" : "Active"}
              color={params.row.isDisabled ? "secondary" : "success"}
              sx={{
                height: 24,
                "& .MuiChip-label:hover": {
                  color: (theme) =>
                    theme.palette.customColors.customizedChipText,
                },
                fontSize: "0.75rem",
                "& .MuiChip-label": {
                  fontWeight: 600,
                  lineHeight: 1.4,
                },
              }}
            />
          );
        },
      },
    ],
    []
  );

  // ** Functions
  const fillFields = async (id: string) => {
    await Sensor({
      variables: {
        id: id,
      },
      fetchPolicy: "cache-and-network",
    }).then((res) => {
      setInitialValues({
        id: res.data?.sensor.id,
        moduleId: res.data?.sensor.module.id,
        serialNumber: res.data?.sensor.serialNumber,
        name: res.data?.sensor?.name,
        description: res.data?.sensor?.description,
      } as SensorUncheckedUpdateInput);
      setSensorId(id);
    });
  };

  const openBrandDialog = async (id = "new") => {
    if (id === "new") {
      setInitialValues({
        name: "",
        description: "",
      } as SensorUncheckedCreateInput);
      setIsNew(true);
    } else {
      await fillFields(id);
      setIsNew(false);
    }

    setSensorModal(true);
  };

  const fetchTableData = useCallback(async () => {
    await Sensors({
      fetchPolicy: "network-only",
    }).then((res) => {
      setTotalRowCount(res.data?.sensors.count || 0);
      setRows(res.data?.sensors.items || ([] as SensorType[]));
    });
  }, [Sensors]);

  const onCreateSensor = () => {
    openBrandDialog();
  };

  const checkCollection = () => {
    if (oneWillBeDeleted) {
      deleteOne(sensorId);
      setOneWillBeDeleted(false);
    } else {
      if (selectedRowClone.length > 0) {
        selectedRowClone.forEach(async (id) => {
          console.log(id);
          deleteOne(id);
        });
      }
    }
    resetDataGrid();
  };

  function reducer(state: any, action: any) {
    switch (action.type) {
      case "QUEUE_FOR_REMOVAL":
        return {
          stateDelete: StateDelete.select,
        };
      case "REMOVE":
        return {
          ...state,
          stateDelete: StateDelete.delete,
        };
      case "UNDO":
        return {
          undo: true,
          stateDelete: StateDelete.cancel,
        };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    stateDelete: StateDelete.select,
    undo: false,
  });

  const notify = () => {
    dispatch({
      type: "QUEUE_FOR_REMOVAL",
    });

    setToastDesignColor(Color.primary);

    let title = "1 Sensor";

    if (selectedRow.length > 1) {
      if (!oneWillBeDeleted) title = `${selectedRow.length} Substations`;
    }

    toast(
      <DeleteToast
        title={title}
        onUndo={() => {
          dispatch({
            type: "UNDO",
          });
        }}
      />,
      {
        onClose: () => {
          dispatch({ type: "REMOVE" });
        },
      }
    );
  };

  const resetDataGrid = () => {
    setResetRowSelected(true);
    setSelectedRow([]);
    setSelectedRowClone([]);
  };

  const deleteOne = async (id: string) => {
    await DeleteSensor({
      variables: {
        id,
        isDeleted: true,
      },
    });
  };

  const changeDisableStateOne = async (pId: string, pIsDisabled: boolean) => {
    await ChangeDisableStateSensor({
      variables: {
        id: pId,
        isDisabled: !pIsDisabled,
      },
    });

    fetchTableData();
  };

  const onDeleteOne = (id: string) => {
    setSensorId(id);
    setOneWillBeDeleted(true);
    setRows(rows.filter((item) => !(item.id === id)));
    notify();
  };

  const onDeleteMany = () => {
    setSelectedRowClone(selectedRow);
    setRows(rows.filter((item) => !selectedRow.includes(item.id)));
    notify();
  };

  const onUpdate = (id: string) => {
    openBrandDialog(id);
  };

  const onDetail = (id: string) => {
    navigate(`/report/view/${id}`);
  };

  const onSubmit = async (
    values: SensorUncheckedCreateInput | SensorUncheckedUpdateInput
  ) => {
    const promiseToastData = new Promise(async (resolve, reject) => {
      try {
        let returnedName;

        if (isNew) {
          const returnedData = await CreateSensor({
            variables: {
              createSensorInput: {
                moduleId: values.moduleId,
                name: values.name,
                serialNumber: values.serialNumber,
                description: values.description,
              } as SensorUncheckedCreateInput,
            },
            refetchQueries: [SensorsDocument],
          });

          returnedName = returnedData.data?.createSensor.name;
        } else {
          const updatedData = values as SensorUncheckedUpdateInput;
          const returnedData = await UpdateSensor({
            variables: {
              updateSensorInput: {
                moduleId: updatedData.moduleId,
                name: updatedData.name,
                serialNumber: updatedData.serialNumber,
                description: updatedData.description,
              } as SensorUncheckedUpdateInput,
              id: sensorId,
            },
          });

          returnedName = returnedData.data?.updateSensor.name;
        }

        resolve(returnedName);
        setSensorModal(false);
        fetchTableData();
      } catch (error) {
        const errorResult = (error as Error).message;
        reject(errorResult);
      }
    });

    toast.promise(promiseToastData, {
      pending: {
        render() {
          const description = isNew ? "is creating" : "is Updating";
          return (
            <PendingToast
              title="Sensor"
              description={description}
              color="primary"
            />
          );
        },
        icon: false,
      },
      success: {
        render({ data }) {
          let color = Color.success;
          let description = "Has been successfully created";

          if (!isNew) {
            color = Color.warning;
            description = "Has been successfully updated";
          }
          setToastDesignColor(color);
          return (
            <SuccessToast
              title={data as string}
              description={description}
              color={color}
            />
          );
        },
      },
      error: {
        render({ data }) {
          setToastDesignColor(Color.error);
          return (
            <ErrorToast title={"Error Occurred"} description={data as string} />
          );
        },
      },
    });
  };

  // ** UseEffects
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRowCount !== undefined ? totalRowCount : prevRowCountState
    );
  }, [totalRowCount, setRowCountState]);

  useEffect(() => {
    if (state.stateDelete === StateDelete.delete && !state.undo) {
      checkCollection();
    } else if (state.undo) {
      resetDataGrid();
    }
  }, [state.stateDelete, state.undo]);

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  // ** Components
  const SensorModal = React.memo(() => {
    return (
      <Dialog
        fullWidth
        open={sensorModal}
        maxWidth="xs"
        scroll="body"
        onClose={() => setSensorModal(false)}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <DialogTitle sx={{ p: 0, mb: 5 }}>
              <Title p={5}>
              {isNew ? "Create New Sensor" : "Update Sensor"}
              </Title>
            </DialogTitle>
            <DialogContent sx={{ paddingTop: "10px !important" }}>
              <AutoChange />
              <Grid container spacing={5}>
                {!isNew && <Field name="id" type="hidden" />}
                <Grid item xs={12}>
                  <ModuleAutocompleteField name="moduleId" label="Module" />
                </Grid>
                <Grid item xs={12}>
                  <FTextField
                    name="name"
                    fullWidth
                    label="Sensor Name"
                    placeholder="Sensor - 12451"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FTextField
                    name="serialNumber"
                    fullWidth
                    label="Serial Number"
                    placeholder=""
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FTextField
                    name="description"
                    multiline
                    rows={4}
                    fullWidth
                    label="Description"
                    placeholder="(Optional) Description for Substation"
                    type="text"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "end",
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setSensorModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                startIcon={isNew ? <AddRoundedIcon /> : <CheckRoundedIcon />}
              >
                {isNew ? "Create" : "Update"}
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    );
  });

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
        mb={5}
      >
        <IconButton
          sx={{ visibility: selectedRow.length > 0 ? "visible" : "hidden" }}
          onClick={onDeleteMany}
        >
          <Badge badgeContent={selectedRow.length} color="primary">
            <DeleteOutlineOutlinedIcon />
          </Badge>
        </IconButton>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddRoundedIcon />}
          onClick={onCreateSensor}
        >
          Create Sensor
        </Button>
      </Stack>
      <Card>
        <DataGridSpeacial
          loading={loading}
          columns={columns}
          rows={rows}
          onSelectedRow={setSelectedRow}
          onUpdate={onUpdate}
          onDetail={onDetail}
          resetSelectedRow={resetRowSelected}
          setResetRowSelected={setResetRowSelected}
          rowCountState={rowCountState}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </Card>
      <SensorModal />
    </Box>
  );
};

export default ReportsPage;
