import React from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

// ** Icons Imports

// ** Custom Components Imports

// ** Types Imports
import { Button, Grid } from "@mui/material";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import Image from "mui-image";
import { ThemeColor } from "src/@core/layouts/types";
import { useNavigate } from "react-router-dom";
import Chip from "../mui/chip";


type SubstationInfoCardProps = {
  id: string;
  title: string;
  latitude: string;
  longitude: string;
  color: ThemeColor;
};

const SubstationInfoCard = (props: SubstationInfoCardProps) => {
  // ** Props
  const { id, title, latitude, longitude } = props;

  const navigate = useNavigate();

  const onClick = (pId: string) => {
    navigate(`/substation/view/${pId}`);
  };

  return (
    <Card sx={{ borderRadius: 1 }}>
      <CardContent
        sx={{
          py: (theme) => `${theme.spacing(0)} !important`,
          px: (theme) => `${theme.spacing(0)} !important`,
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="stretch"
          spacing={1}
        >
          <Grid item xs={6}>
            <Image height={190} src="/images/substation.jpg"></Image>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
            }}
          >
            <Typography m={1} variant="subtitle1" sx={{ lineHeight: 1 }}>
              {title}
            </Typography>
            <Typography m={1} variant="caption" sx={{ lineHeight: 1 }}>
              Latitude
            </Typography>
            <Chip
              skin="light"
              label={latitude}
              color="info"
              sx={{
                py: 2,
                px: 1,
                height: 24,
                "&.MuiChip-light": {
                  "&:hover": {
                    color: (theme) =>
                      theme.palette.customColors.customizedChipText,
                  },
                },
                fontSize: "0.8rem",
                "& .MuiChip-label": {
                  fontWeight: 500,
                  lineHeight: 1,
                },
              }}
            />
            <Typography m={1} variant="caption" sx={{ lineHeight: 1 }}>
              Longitude
            </Typography>
            <Chip
              skin="light"
              label={longitude}
              color="info"
              sx={{
                py: 2,
                px: 1,
                height: 24,
                "&.MuiChip-light": {
                  "&:hover": {
                    color: (theme) =>
                      theme.palette.customColors.customizedChipText,
                  },
                },
                fontSize: "0.8rem",
                "& .MuiChip-label": {
                  fontWeight: 500,
                  lineHeight: 1,
                },
              }}
            />
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Button
                sx={{ mt: 3 }}
                variant="outlined"
                fullWidth
                onClick={() => onClick(id)}
                color="primary"
                size="small"
                startIcon={<RemoveRedEyeRoundedIcon />}
              >
                Details
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SubstationInfoCard;

SubstationInfoCard.defaultProps = {
  color: "primary",
};
