// ** React Imports
import { useEffect, useState } from "react";

// ** Library Imports
import ReactSpeedometer from "react-d3-speedometer";
import { socket } from "src/socket";

// ** MUI Imports
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";

// ** Component Imports
import TemperatureAndHumidityLiveChart from "src/@core/components/charts/TemperatureAndHumidityLiveChart";
import VibrationLiveChart from "src/@core/components/charts/VibrationLiveChart";
import ConsolidatedLiveChart from "src/@core/components/charts/ConsolidatedLiveChart";

// ** Hook Imports
import { useParams } from "react-router-dom";

// ** Type Import
import { SensorDataType } from "src/types/graphql/sensorDataType";

// ** Types
export enum IColorsType {
  active = "success",
  pending = "warning",
  inactive = "secondary",
}

const SensorViewPage = () => {
  const params = useParams();

  // ** States
  const [temperature, setTemperature] = useState<number>(0);
  const [humidity, setHumidity] = useState<number>(0);
  const [xRMS, setXRMS] = useState<number>(0);
  const [yRMS, setYRMS] = useState<number>(0);
  const [zRMS, setZRMS] = useState<number>(0);
  const [pressure, setPressure] = useState<number>(0);
  const [co2, setCO2] = useState<number>(0);
  const [gasPercentage, setGasPercentage] = useState<number>(0);
  const [gasResistance, setGasResistance] = useState<number>(0);
  const [airQuality, setAirQuality] = useState<number>(0);
  const [time, setTime] = useState<Date>(new Date());

  useEffect(() => {});

  // ** Functions
  useEffect(() => {
    function onSensorDataEvent(value: any) {
      console.log(value);
      setTemperature(value.temperature as number);
      setHumidity(value.humidity as number);

      setXRMS(value.xRMS as number);
      setYRMS(value.yRMS as number);
      setZRMS(value.zRMS as number);

      setPressure(value.pressure as number);
      setCO2(value.co2 as number);
      setGasPercentage(value.gasPercentage as number);
      setGasResistance(value.gasResistance as number);

      setAirQuality(Number(value.airQuality));

      //setTime(value.time as Date);
    }

    socket.on(`data/${params.sensorId}`, onSensorDataEvent);

    return () => {
      socket.off(`data/${params.sensorId}`, onSensorDataEvent);
    };
  }, [params.sensorId]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TemperatureAndHumidityLiveChart
          label={"Temperature And Humidity Live"}
          temperature={temperature}
          humidity={humidity}
          time={time}
        />
      </Grid>
      <Grid item xs={12}>
        <VibrationLiveChart
          label={"Vibration Live"}
          xRMS={xRMS}
          yRMS={yRMS}
          zRMS={zRMS}
          time={time}
        />
      </Grid>
      <Grid item xs={12}>
        <ConsolidatedLiveChart
          label={"Consolidated Live"}
          pressure={pressure}
          co2={co2}
          gasPercentage={gasPercentage}
          gasResistance={gasResistance}
          time={time}
        />
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Box sx={{ m: 5 }}>
          <ReactSpeedometer
            width={400}
            value={airQuality}
            minValue={0}
            maxValue={500}
            currentValueText="Air Quality"
            customSegmentStops={[0, 50, 100, 150, 200, 250, 300, 350, 500]}
            segmentColors={[
              "#00E400",
              "#00E400",
              "#91CF50",
              "#FFFF00",
              "#FF7E00",
              "#FF0000",
              "#99004B",
              "#653301",
            ]}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default SensorViewPage;
