// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";

// ** Icons Imports
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";

// ** Custom Components Imports
import CustomChip from "src/@core/components/mui/chip";
import Divider from "@mui/material/Divider";
import {
  Avatar,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  useTheme,
} from "@mui/material";
import SensorsRoundedIcon from "@mui/icons-material/SensorsRounded";
// ** React Imports
import { useContext, useEffect, useReducer, useState } from "react";
import Fade from "@mui/material/Fade";

// ** MUI Imports
import { styled } from "@mui/material/styles";
import { MenuProps } from "@mui/material/Menu";
import { MenuItemProps } from "@mui/material/MenuItem";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import Typography from "@mui/material/Typography";
import React from "react";
import { GlobalContext } from "src/context/GlobalContext";
import { StateDelete } from "src/types/enum-types/StateDelete";
import { Color } from "src/types/enum-types/Color";
import DeleteToast from "../toast/DeleteToast";
import useBgColor, { UseBgColorType } from "src/@core/hooks/useBgColor";
import { useNavigate } from "react-router-dom";
import { ThemeColor } from "src/@core/layouts/types";
import { socket } from "src/socket";
import { SensorDataType } from "src/types/graphql/sensorDataType";

// ** Icons Imports
import BubbleChartRoundedIcon from "@mui/icons-material/BubbleChartRounded";
import OpacityRoundedIcon from "@mui/icons-material/OpacityRounded";
import PercentRoundedIcon from "@mui/icons-material/PercentRounded";
import CompressRoundedIcon from "@mui/icons-material/CompressRounded";
import Co2RoundedIcon from "@mui/icons-material/Co2Rounded";
import DeviceThermostatRoundedIcon from "@mui/icons-material/DeviceThermostatRounded";
import GraphicEqRounded from "@mui/icons-material/GraphicEqRounded";

import moment from "moment";
import Chip from "src/@core/components/mui/chip";

type SensorCardProps = {
  id: string;
  name: string;
  serialNumber: string;
  onlineSensors: string[];
  visibityOfModule: boolean;
};

// Styled Menu component
const StyledMenu = styled(Menu)<MenuProps>(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: 0,
    background: theme.palette.customColors.darkBg,
  },
}));

// Styled MenuItem component
const StyledMenuItem = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  marginLeft: 7,
  marginRight: 7,
  borderRadius: 3,
  color: theme.palette.customColors.customizedChipText,
  "&.MuiMenuItem-root:hover": {
    color: theme.palette.primary.light,
    background: theme.palette.customColors.customizedPrimaryLightBackground,
  },
}));

const SensorCard = (props: SensorCardProps) => {
  // ** Props
  const { id, name, serialNumber, onlineSensors, visibityOfModule } = props;

  // ** Hook
  const bgColors = useBgColor();

  const colors: UseBgColorType = {
    primary: { ...bgColors.primaryLight },
    secondary: { ...bgColors.secondaryLight },
    success: { ...bgColors.successLight },
    error: { ...bgColors.errorLight },
    warning: { ...bgColors.warningLight },
    info: { ...bgColors.infoLight },
  };

  // ** State
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [sensorStatusColor, setSensorStatusColor] = useState<ThemeColor>(
    Color.error
  );
  const [isOnline, setisOnline] = useState<boolean>(true);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [airQualityColor, setAirQualityColor] = useState<ThemeColor>(
    Color.primary
  );
  const [airQualityLabel, setAirQualityLabel] = useState<string>("Excellent");
  const [airQualityTooltipLabel, setAirQualityTooltipLabel] = useState<string>(
    "0 – 50 Excellent: Pure air; best for well-being No measures needed"
  );

  const [sensorValues, setSensorValues] = useState<SensorDataType>({
    id: "",
    sensorId: "",
    airQuality: 0,
    co2: 0,
    gasPercentage: 0,
    gasResistance: 0,
    humidity: 0,
    pressure: 0,
    temperature: 0,
    isDeleted: false,
    isDisabled: false,
    time: "",
    createdAt: "",
    updatedAt: "",
    xRMS: 0,
    yRMS: 0,
    zRMS: 0,
  });

  // ** Utils

  // ** Context Datas
  const { visibilityDisabledSensors, setVisibilityDisabledSensors } =
    useContext(GlobalContext);
  const navigate = useNavigate();
  const theme = useTheme();

  // ** Functions
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onNavigateUpdatePage = () => {
    navigate("/sensors/sensor/" + "id");
    handleClose();
  };

  const onNavigateDetailPage = () => {
    navigate("/sensors/sensor/view/" + "role");
    handleClose();
  };

  const onHide = () => {
    handleClose();
  };

  const returnedAirQualityColor = (airQuality: number) => {
    if (airQuality <= 50) {
      setAirQualityColor(Color.primary);
      setAirQualityLabel("Excellent");
      setAirQualityTooltipLabel(
        "0 – 50 Excellent: Pure air; best for well-being No measures needed"
      );
    } else if (airQuality <= 100) {
      setAirQualityColor(Color.primary);
      setAirQualityLabel("Good");
      setAirQualityTooltipLabel(
        "51 – 100 Good: No irritation or impact on well-being No measures needed"
      );
    } else if (airQuality <= 150) {
      setAirQualityColor(Color.warning);
      setAirQualityLabel("Lightly Polluted");
      setAirQualityTooltipLabel(
        "101 – 150 Lightly Polluted: Reduction of well-being possible Ventilation suggested"
      );
    } else if (airQuality <= 200) {
      setAirQualityColor(Color.warning);
      setAirQualityLabel("Moderately Polluted");
      setAirQualityTooltipLabel(
        "151 – 200 Moderately Polluted: More significant irritation possible Increase ventilation with clean air"
      );
    } else if (airQuality <= 250) {
      setAirQualityColor(Color.error);
      setAirQualityLabel("Heavily Polluted");
      setAirQualityTooltipLabel(
        "201 – 250 Heavily Polluted: Exposition might lead to effects like headache depending on type of VOCs optimize ventilation"
      );
    } else if (airQuality <= 350) {
      setAirQualityColor(Color.error);
      setAirQualityLabel("Severely polluted");
      setAirQualityTooltipLabel(
        "251 – 350 Severely Polluted: More severe health issue possible if harmful VOC present Contamination should be identified if level is reached even w/o presence of people; maximize ventilation & reduce attendance"
      );
    } else {
      setAirQualityColor(Color.error);
      setAirQualityLabel("Extremely Polluted");
      setAirQualityTooltipLabel(
        "> 351 Extremely Polluted: Headaches, additional neurotoxic effects possible Contamination needs to be identified; avoid presence in room and maximize ventilation"
      );
    }
  };

  useEffect(() => {
    if (onlineSensors.includes(serialNumber)) {
      setSensorStatusColor(Color.success);
      setisOnline(true);
    } else {
      setSensorStatusColor(Color.error);
      setisOnline(false);
    }
  }, [onlineSensors, serialNumber]);

  useEffect(() => {
    function onSensorDataEvent(value: SensorDataType) {
      //console.log(JSON.parse(value));
      setSensorValues(value);

      returnedAirQualityColor(value.airQuality as number);
    }

    socket.on(`data/${id}`, onSensorDataEvent);

    return () => {
      socket.off(`data/${id}`, onSensorDataEvent);
    };
  }, []);

  useEffect(() => {
    if (visibilityDisabledSensors.includes(serialNumber)) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [visibilityDisabledSensors, setVisibilityDisabledSensors, serialNumber]);

  return (
    <Grid
      item
      xs={12}
      sm={6}
      lg={4}
      xl={3}
      sx={{
        display: isVisible ? "block" : "none",
      }}
    >
      <Card sx={{ height: "100%" }}>
        <CardContent sx={{ p: 2, paddingBottom: "10px !important" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Chip
              skin="light"
              label={
                sensorValues?.updatedAt
                  ? moment(sensorValues?.updatedAt as string).format(
                      "MM.DD.YYYY HH:mm:ss"
                    )
                  : "Not updated"
              }
              color="info"
              sx={{
                py: 2,
                px: 1,
                mb: 1,
                height: 18,
                "&.MuiChip-light": {
                  "&:hover": {
                    color: (theme: Theme) =>
                      theme.palette.customColors.customizedChipText,
                  },
                },
                fontSize: "0.6rem",
                "& .MuiChip-label": {
                  fontWeight: 500,
                  lineHeight: 1,
                },
              }}
            />
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: colors[sensorStatusColor].backgroundColor,
              p: 3,
              pr: 2,
              borderRadius: 1,
            }}
          >
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: isOnline
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                  color: isOnline
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                  "&::after": {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    animation: isOnline
                      ? "ripple 1.2s infinite ease-in-out"
                      : "",
                    border: "1px solid currentColor",
                    content: '""',
                  },
                  "@keyframes ripple": {
                    "0%": {
                      transform: "scale(.8)",
                      opacity: 1,
                    },
                    "100%": {
                      transform: "scale(2.4)",
                      opacity: 0,
                    },
                  },
                },
              }}
            >
              <Avatar alt={name}>
                <SensorsRoundedIcon color={sensorStatusColor} />
              </Avatar>
            </Badge>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 600,
                color: isOnline
                  ? theme.palette.success.main
                  : theme.palette.error.main,
              }}
            >
              {name}
            </Typography>
            <IconButton
              onClick={handleClick}
              size="small"
              aria-label="settings"
            >
              <MoreVertRoundedIcon />
            </IconButton>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={visibityOfModule ? 12 : 6}>
              <Box mt={3} display="flex" justifyContent="flex-start">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 2,
                    borderRadius: 1,
                    backgroundColor: colors[Color.primary].backgroundColor,
                  }}
                >
                  <DeviceThermostatRoundedIcon color="primary" />
                </Box>
                <Box
                  sx={{
                    ml: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography variant="h6" sx={{ lineHeight: 1 }}>
                      {sensorValues.temperature?.toFixed(2)}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      °C
                    </Typography>
                  </Box>

                  <Typography variant="subtitle2" sx={{ mt: 1, lineHeight: 1 }}>
                    Temprature
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={visibityOfModule ? 12 : 6}>
              <Box mt={3} display="flex" justifyContent="flex-start">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 2,
                    borderRadius: 1,
                    backgroundColor: colors[Color.primary].backgroundColor,
                  }}
                >
                  <OpacityRoundedIcon color="primary" />
                </Box>
                <Box
                  sx={{
                    ml: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography variant="h6" sx={{ lineHeight: 1 }}>
                      {sensorValues.humidity?.toFixed(2)}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      %
                    </Typography>
                  </Box>
                  <Typography variant="subtitle2" sx={{ mt: 1, lineHeight: 1 }}>
                    Humidity
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={visibityOfModule ? 12 : 6}
              display="flex"
              alignItems="center"
            >
              <Box
                mt={3}
                display="flex"
                justifyContent="flex-start"
                alignItems="stretch"
                height="100%"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 2,
                    borderRadius: 1,
                    backgroundColor: colors[Color.primary].backgroundColor,
                  }}
                >
                  <GraphicEqRounded color="primary" />
                </Box>
                <Box
                  sx={{
                    ml: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Box
                      sx={{
                        backgroundColor: colors["primary"].backgroundColor,
                        py: 0.5,
                        px: 1,
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          color: colors["primary"].color,
                        }}
                      >
                        xRMS
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ ml: 1, lineHeight: 1 }}
                      >
                        {sensorValues.xRMS?.toFixed(3)}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          ml: 1,
                          lineHeight: 1,
                          color: theme.palette.primary.main,
                        }}
                      >
                        g
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Box
                      sx={{
                        backgroundColor: colors["primary"].backgroundColor,
                        py: 0.5,
                        px: 1,
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          color: colors["primary"].color,
                        }}
                      >
                        yRMS
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ ml: 1, lineHeight: 1 }}
                      >
                        {sensorValues.yRMS?.toFixed(3)}{" "}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          ml: 1,
                          lineHeight: 1,
                          color: theme.palette.primary.main,
                        }}
                      >
                        g
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    sx={{ mb: 1 }}
                  >
                    <Box
                      sx={{
                        backgroundColor: colors["primary"].backgroundColor,
                        py: 0.5,
                        px: 1,
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          color: colors["primary"].color,
                        }}
                      >
                        zRMS
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ ml: 1, lineHeight: 1 }}
                      >
                        {sensorValues.zRMS?.toFixed(3)}{" "}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          ml: 1,
                          lineHeight: 1,
                          color: theme.palette.primary.main,
                        }}
                      >
                        g
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="subtitle2" sx={{ mt: 1, lineHeight: 1 }}>
                    Vibration
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={visibityOfModule ? 12 : 6}
              display="flex"
              alignItems="center"
            >
              <Box
                mt={3}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                height="100%"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 2,
                    borderRadius: 1,
                    height: "100%",
                    backgroundColor: colors[Color.primary].backgroundColor,
                  }}
                >
                  <BubbleChartRoundedIcon color="primary" />
                </Box>
                <Box
                  sx={{
                    ml: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h6" sx={{ lineHeight: 1 }}>
                    {sensorValues.airQuality?.toFixed(2)}
                  </Typography>
                  <Tooltip title={airQualityTooltipLabel}>
                    <Box
                      sx={{
                        backgroundColor:
                          colors[airQualityColor].backgroundColor,
                        py: 0.5,
                        px: 1,
                        my: 1,
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          color: colors[airQualityColor].color,
                        }}
                      >
                        {airQualityLabel}
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Typography variant="subtitle2" sx={{ mt: 1, lineHeight: 1 }}>
                    Air Quality
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={visibityOfModule ? 12 : 6}>
              <Box mt={3} display="flex" justifyContent="flex-start">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 2,
                    borderRadius: 1,
                    backgroundColor: colors[Color.primary].backgroundColor,
                  }}
                >
                  <CompressRoundedIcon color="primary" />
                </Box>
                <Box
                  sx={{
                    ml: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography variant="h6" sx={{ lineHeight: 1 }}>
                      {sensorValues.pressure?.toFixed(2)}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      hPa
                    </Typography>
                  </Box>
                  <Typography variant="subtitle2" sx={{ mt: 1, lineHeight: 1 }}>
                    Pressure
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={visibityOfModule ? 12 : 6}>
              <Box mt={3} display="flex" justifyContent="flex-start">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 2,
                    borderRadius: 1,
                    backgroundColor: colors[Color.primary].backgroundColor,
                  }}
                >
                  <PercentRoundedIcon color="primary" />
                </Box>
                <Box
                  sx={{
                    ml: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography variant="h6" sx={{ lineHeight: 1 }}>
                      {sensorValues.gasResistance?.toFixed(2)}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      Ohm
                    </Typography>
                  </Box>
                  <Typography variant="subtitle2" sx={{ mt: 1, lineHeight: 1 }}>
                    Gas Resistance
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={visibityOfModule ? 12 : 6}>
              <Box mt={3} display="flex" justifyContent="flex-start">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 2,
                    borderRadius: 1,
                    backgroundColor: colors[Color.primary].backgroundColor,
                  }}
                >
                  <PercentRoundedIcon color="primary" />
                </Box>
                <Box
                  sx={{
                    ml: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography variant="h6" sx={{ lineHeight: 1 }}>
                      {sensorValues.gasPercentage?.toFixed(2)}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      %
                    </Typography>
                  </Box>
                  <Typography variant="subtitle2" sx={{ mt: 1, lineHeight: 1 }}>
                    Gas Percentage
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={visibityOfModule ? 12 : 6}>
              <Box mt={3} display="flex" justifyContent="flex-start">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 2,
                    borderRadius: 1,
                    backgroundColor: colors[Color.primary].backgroundColor,
                  }}
                >
                  <Co2RoundedIcon color="primary" />
                </Box>
                <Box
                  sx={{
                    ml: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography variant="h6" sx={{ lineHeight: 1 }}>
                      {sensorValues.co2?.toFixed(2)}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        ml: 1,
                        lineHeight: 1,
                        color: theme.palette.primary.main,
                      }}
                    >
                      CO2
                    </Typography>
                  </Box>
                  <Typography variant="subtitle2" sx={{ mt: 1, lineHeight: 1 }}>
                    CO₂
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <StyledMenu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <StyledMenuItem onClick={onNavigateUpdatePage}>
              <EditRoundedIcon sx={{ mr: 3 }} />
              Edit
            </StyledMenuItem>
            <StyledMenuItem onClick={onHide}>
              <DeleteRoundedIcon sx={{ mr: 3 }} />
              Hide
            </StyledMenuItem>
            <StyledMenuItem onClick={onNavigateDetailPage}>
              <InfoRoundedIcon sx={{ mr: 3 }} />
              Details
            </StyledMenuItem>
          </StyledMenu>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SensorCard;
