
import { ApolloProvider } from '@apollo/client';
import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Session, { SessionAuth } from "supertokens-auth-react/recipe/session";

import ModalProvider from '../context/modalContext';
import ModulesPage from 'src/pages/modules/Modules';
import BlankLayout from '../@core/layouts/BlankLayout';
import LoginPage from 'src/pages/login';
import UserLayout from './UserLayout';
import Error404 from '../pages/404';
import SensorsPage from 'src/pages/sensors/Sensors';
import ModuleViewPage from 'src/pages/modules/ModuleView';
import DashboardPage from 'src/pages/dashboard/Dashboard';
import SubstationsPage from 'src/pages/substations/Substations';
import SubstationViewPage from 'src/pages/substations/SubstationView';
import UsersPage from 'src/pages/users/Users';
import UsersViewPage from 'src/pages/users/UserView';
import SensorViewPage from 'src/pages/sensors/SensorView';
import UserAddEditPage from 'src/pages/users/UserAddEdit';
import ReportViewPage from 'src/pages/reports/ReportView';
import ReportsPage from 'src/pages/reports/Reports';
import client from 'src/@core/apollo';
import MaintenancePage from 'src/pages/maintenance/Maintenance';
import HelpPage from 'src/pages/help/Help';

const MainLayout = () => {
  const sessionContext = Session.useSessionContext();

  if (sessionContext.loading) {
    return null;
  }

  const SignIn: React.FC = () => (
    <BlankLayout>
      <LoginPage />
    </BlankLayout>
  );

  const MainRoute: React.FC = () => (
    <ModalProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <SessionAuth>
                <UserLayout />
              </SessionAuth>
            }
          >
            <Route index element={<DashboardPage />} />
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="substations" element={<SubstationsPage />} />
            <Route
              path="substation/view/:substationId"
              element={<SubstationViewPage />}
            />
            <Route path="modules" element={<ModulesPage />} />
            <Route path="module/view/:moduleId" element={<ModuleViewPage />} />
            <Route path="sensors" element={<SensorsPage />} />
            <Route path="sensor/view/:sensorId" element={<SensorViewPage />} />
            <Route path="reports" element={<ReportsPage />} />
            <Route path="report/view/:sensorId" element={<ReportViewPage />} />
            <Route path="users" element={<UsersPage />} />
            <Route path="user/view/:userId" element={<UsersViewPage />} />
            <Route path="user/:userId" element={<UserAddEditPage />} />
            <Route path="maintenance" element={<MaintenancePage />} />
            <Route path="help" element={<HelpPage />} />
            <Route path="*" element={<Navigate to="404" replace />} />
          </Route>
          <Route path="404" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </ModalProvider>
  );

  if (sessionContext.doesSessionExist) {
    // We wrap this with <SessionAuth /> so that
    // all claims are validated before showing the logged in UI.
    // For example, if email verification is switched on, and
    // the user's email is not verified, then <SessionAuth />
    // will redirect to the email verification page.
    return (
      <ApolloProvider client={client}>
        <MainRoute />
      </ApolloProvider>
    );
  } else {
    return <SignIn />;
  }
};

export default MainLayout;
