import React, { useState, MouseEvent } from 'react'
import { useField } from 'formik'
import TextField from '@mui/material/TextField'
import { IconButton, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface Props {
    name: string;
    placeholder?: string;
    alignText?: string;
    active?: string
    setActive?: (value: React.SetStateAction<string>) => void
    setLastActive?: (value: React.SetStateAction<string>) => void
    [x: string]: unknown
}

export const FTextFieldPassword = (props: Props) => {
    const [field, meta] = useField(props);

    const [visibilityOfPassword, setVisibilityOfPassword] = useState(false);

    const handleClickSetEnable = () => {
        setVisibilityOfPassword((visibilityOfPassword) => !visibilityOfPassword);
    }

    const handleMouseDownEnable = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const endAdornment = <InputAdornment position="end" sx={{mr: 2}}>
        <IconButton
            aria-label="toggle enabled"
            onClick={handleClickSetEnable}
            onMouseDown={handleMouseDownEnable}
            edge="end"
        >
            {visibilityOfPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
    </InputAdornment>

    const onFocus = (event: any) => {
        if (props.setLastActive)
            props.setLastActive(props.active || '')

        if (props.setActive)
            props.setActive(props.name)
    }

    return (
        <TextField
            {...field}
            {...props}
            sx={{maxWidth: "600px"}}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            onFocus={onFocus}
            type={visibilityOfPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment,
                sx: {
                    "& input": {
                        textAlign: props.alignText != null ? props.alignText : 'left',
                    }
                }
            }}
        />
    )
}
