// ** Context Imports
import { GlobalContext } from "src/context/GlobalContext";

// ** Icons Imports
import { SensorsRounded, SensorsOffRounded } from "@mui/icons-material";

// ** MUI Imports
import Badge from "@mui/material/Badge";
import { Avatar, IconButton, Tooltip, useTheme } from "@mui/material";

// ** React Imports
import React from "react";
import { useContext, useEffect } from "react";

// ** Type Imports
import { Color } from "src/types/enum-types/Color";
import { ThemeColor } from "src/@core/layouts/types";

type SensorBadgeProps = {
  id: string;
  name: string;
  serialNumber: string;
  onlineSensors: string[];
};

const SensorBadge = (props: SensorBadgeProps) => {

  // ** Props
  const { name, serialNumber, onlineSensors } = props;

  // ** State
  const [sensorStatusColor, setSensorStatusColor] = React.useState<ThemeColor>(
    Color.secondary
  );
  const [isOnline, setisOnline] = React.useState<boolean>(true);
  const [isVisible, setIsVisible] = React.useState<boolean>(true);

  // ** Utils

  // ** Context Datas
  const { visibilityDisabledSensors, setVisibilityDisabledSensors } =
    useContext(GlobalContext);
  const theme = useTheme();

  // ** Functions

  const onChangeSensorVisibility = () => {
    if (isVisible) {
      const sensors = [...visibilityDisabledSensors, serialNumber];
      setVisibilityDisabledSensors(sensors);
      setIsVisible(false);
    } else {
      const sensors = visibilityDisabledSensors.filter(
        (item) => item !== serialNumber
      );
      setVisibilityDisabledSensors(sensors);
      setIsVisible(true);
    }
  };

  useEffect(() => {
    if (onlineSensors.includes(serialNumber)) {
      setSensorStatusColor(Color.success);
      setisOnline(true);
    } else {
      setSensorStatusColor(Color.secondary);
      setisOnline(false);
    }
  }, [onlineSensors, serialNumber]);

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      variant="dot"
      sx={{
        "& .MuiBadge-badge": {
          backgroundColor: isOnline
            ? theme.palette.success.main
            : theme.palette.error.main,
          color: isOnline
            ? theme.palette.success.main
            : theme.palette.error.main,
          boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
          "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: isOnline ? "ripple 1.2s infinite ease-in-out" : "",
            border: "1px solid currentColor",
            content: '""',
            "@keyframes ripple": {
              "0%": {
                transform: "scale(.8)",
                opacity: 1,
              },
              "100%": {
                transform: "scale(2.4)",
                opacity: 0,
              },
            },
          },
        },
      }}
    >
      <Tooltip title={name}>
        <IconButton sx={{ p: 0, m: 0 }} onClick={onChangeSensorVisibility}>
          <Avatar
            alt={name}
            sx={{
              backgroundColor: isOnline
                ? theme.palette.success.light
                : theme.palette.error.light,
            }}
          >
            {isVisible ? (
              <SensorsRounded sx={{ color: "#fff" }} />
            ) : (
              <SensorsOffRounded sx={{ color: "#fff" }} />
            )}
          </Avatar>
        </IconButton>
      </Tooltip>
    </Badge>
  );
};

export default SensorBadge;
