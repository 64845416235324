// ** Component Imports
import VibrationChart from "src/@core/components/charts/VibrationChart";

// ** Graphql Imports

// ** MUI Imports
import Grid from "@mui/material/Grid";

// ** React Imports
import { useParams } from "react-router-dom";

// ** Type Imports
import TemperatureAndHumidityChart from "src/@core/components/charts/TemperatureAndHumidityChart";
import ConsolidatedChart from "src/@core/components/charts/ConsolidatedChart";
import { useEffect, useState } from "react";
import { useSensorLazyQuery } from "src/generated/graphql";

const ReportViewPage = () => {
  // ** Utils Functions
  const params = useParams();

  const [name, setName] = useState("");
  const [ getSensor ] = useSensorLazyQuery()

  useEffect(() => {
    getSensor({ variables: {
      id: params.sensorId as string
    } }).then(data => {
      setName(data.data?.sensor.name as string);
    });
  }, [name, setName])

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <TemperatureAndHumidityChart
          sensorId={params.sensorId as string}
          label="Temperature"
          title={name as string}
        />
      </Grid>
      <Grid item xs={12}>
        <VibrationChart
          sensorId={params.sensorId as string}
          label="Vibration"
          title={name as string}
        />
      </Grid>
      <Grid item xs={12}>
        <ConsolidatedChart
          sensorId={params.sensorId as string}
          label="Consolidated"
          title={name as string}
        />
      </Grid>
    </Grid>
  );
};

export default ReportViewPage;
