// ** Component Imports
import CustomAvatar from "src/@core/components/mui/avatar";
import SubstationInfoCard from "src/@core/components/card/SubstationInfoCard";

// ** Graphql Imports
import { useSubstationsLazyQuery } from "src/generated/graphql";

// ** Hook Imports
import { useNavigate } from "react-router-dom";

// ** Icon Imports
import ElectricMeterRoundedIcon from "@mui/icons-material/ElectricMeterRounded";

// ** Library Imports
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";

// ** MUI Imports
import { Box, Card, CardContent, Grid, Tooltip } from "@mui/material";

// ** React Imports
import { useEffect, useState } from "react";

// ** Type Imports
import { SubstationType } from "src/types/graphql/substationType";


// ** Components
const MapMark = (props: any) => (
  <Box sx={{ position: "relative" }}>
    <Card
      onClick={() => props.onClick(props.substationId)}
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        cursor: "pointer",
        backgroundColor: "#1B2441CC",
        "&:hover": {
          backgroundColor: "#1B2441",
        },
      }}
    >
      <CardContent
        sx={{
          width: 40,
          height: 40,
          p: 0,
          m: 0,
        }}
      >
        <Tooltip title={props.title}>
          <CustomAvatar
            skin="light"
            color="primary"
            sx={{
              width: 40,
              height: 40,
              borderRadius: "7px !important",
            }}
          >
            <ElectricMeterRoundedIcon />
          </CustomAvatar>
        </Tooltip>
      </CardContent>
    </Card>
  </Box>
);

const DashboardPage = () => {
  // ** Hooks
  const navigate = useNavigate();

  // ** States
  const [substations, setSubstations] = useState<SubstationType[]>([]);

  // ** Graphql Functions
  const [Substations] = useSubstationsLazyQuery();

  // ** Variables
  const defaultProps = {
    center: {
      lat: 44.333080173284976,
      lng: 26.838590382254353,
    },
    zoom: 9,
  };

  const customMarkerIcon = new Icon({
    iconUrl: '/substation.png',
    iconSize: [36, 36]
  });

  // ** Functions
  const fetchSubstations = () => {
    Substations({
      fetchPolicy: "cache-and-network",
    }).then((res) => {
      if (res.data?.substations) {
        setSubstations(res.data?.substations.items || ([] as SubstationType[]));
      }
    });
  };

  const onNavigateSubstationView = (pId: string) => {
    navigate(`/substation/view/${pId}`);
  };

  // ** UseEffects
  useEffect(() => {
    fetchSubstations();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Box
          sx={{
            width: "100%",
            borderRadius: 3,
          }}
        >
          <div
            style={{
              margin: 0,
              padding: 0,
              height: "calc(100vh - 122px)",
              width: "100%",
            }}
          >
            <MapContainer center={[45.9442858, 25.0094303]} zoom={6} scrollWheelZoom={false}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
                {substations.map((item, index) =>
                <Marker icon={customMarkerIcon} position={[Number(item?.latitude) || 0, Number(item?.longitude) || 0]}
                eventHandlers={{
                  click: () => {
                    onNavigateSubstationView(item.id)
                  },
                }} >
                  <Popup>
                    {item.name}
                  </Popup>
                </Marker>
                )}
            </MapContainer>
            {/* <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyDAQIns994YxmiHuh1S2XVFDtg53mOCEX8",
              }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            >
              {substations.map((item, index) => (
                <MapMark
                  key={index}
                  substationId={item.id}
                  lat={item.latitude}
                  lng={item.longitude}
                  text={item.name}
                  onClick={onNavigateSubstationView}
                />
              ))}
            </GoogleMapReact> */}
          </div>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            height: "calc(100vh - 122px)",
            maxHeight: "calc(100vh - 122px)",
            overflow: "scroll",
          }}
        >
          <Grid container xs={12} spacing={3}>
            {substations.map((item) => (
              <Grid item key={item.id} xs={12}>
                <SubstationInfoCard
                  id={item.id}
                  title={item.name}
                  latitude={item.latitude as string}
                  longitude={item.longitude as string}
                  color="primary"
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DashboardPage;
