import React, { Fragment, useEffect, useState } from 'react'
import { useField } from 'formik'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { CircularProgress, InputLabelProps } from '@mui/material'
import { useModuleLazyQuery, useModulesLazyQuery } from 'src/generated/graphql'

interface Option {
    id: string
    name: string
}

interface Props {
    name: string
    placeholder?: string
    label?: string
    value?: Option
    inputLabelProps?: InputLabelProps
    [x: string]: unknown
}

const ModuleAutocompleteField = (props: Props) => {
    const [field, meta, helpers] = useField(props.name)

    const [value, setValue] = useState<Option | null>(props.value || null)
    const [options, setOptions] = useState<readonly Option[]>([])
    const [inputValue, setInputValue] = useState('');

    const [Modules, { loading }] = useModulesLazyQuery()
    const [Module] = useModuleLazyQuery()

    const initOptions = async () => {
        await Modules({
        }).then((results) => {
            if (results) {
                setOptions([...(results.data?.modules.items as Option[])])
            }
        })
    }

    useEffect(() => {
        if (field.value)
            Module({
                variables: {
                    id: field.value
                },
                fetchPolicy: "cache-and-network",
            }).then(brandData => {
                setValue(brandData.data?.module || null)
            }
            )
    }, [])

    useEffect(() => {
        let active = true

        if (inputValue === '') {
            if (value)
                setOptions([value])
            else
                initOptions()
            return
        }

        Modules({
            variables: {
                name: inputValue,
            }
        }).then(
            results => {
                if (active) {
                    if (results) {
                        setOptions([...(results.data?.modules.items as Option[])])
                    }
                }
            }
        )

        return () => {
            active = false
        };
    }, [value, inputValue, Modules])

    return (
        <Autocomplete
            {...field}
            {...props}
            disablePortal
            filterOptions={(x) => x}
            autoComplete
            options={options}
            includeInputInList
            filterSelectedOptions
            loading={loading}
            isOptionEqualToValue={(option, v) => v.id === option.id}
            value={value}
            onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue)
            }}
            onChange={(_, newValue) => {
                setOptions(newValue ? [newValue as Option, ...options] : options);
                if (newValue) {
                    helpers.setValue((newValue as Option).id)
                } else {
                    helpers.setValue(null)
                }
                setValue(newValue as Option)
            }}
            getOptionLabel={(option: Option) => option.name || ""}
            renderOption={(renderProps, option) => {
                return (
                    <li {...renderProps} key={option.id}>
                        {option.name}
                    </li>
                );
            }}
            renderInput={(params) => <TextField
                {...params}
                name={props.name}
                error={meta.touched && Boolean(meta.error)}
                helperText={meta.touched && meta.error}
                label={props.label}
                InputLabelProps={
                    props.inputLabelProps
                }
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </Fragment>
                    ),
                }} />}
        />
    )
}

export default ModuleAutocompleteField
