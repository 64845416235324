// ** React Imports
import { SyntheticEvent, useEffect, useState, useCallback } from "react";

import ViewSidebarRoundedIcon from "@mui/icons-material/ViewSidebarRounded";
import ViewWeekRoundedIcon from "@mui/icons-material/ViewWeekRounded";
// ** MUI Imports
import Grid from "@mui/material/Grid";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  styled,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Card,
  CardContent,
  useTheme,
} from "@mui/material";
import { socket } from "src/socket";
import MuiTab, { TabProps } from "@mui/material/Tab";
import CustomAvatar from "src/@core/components/mui/avatar";

// ** Utils Import

// ** Component Imports

// ** Icon Imports
import GpsFixedRoundedIcon from "@mui/icons-material/GpsFixedRounded";

// ** Library Imports
import { useModulesLazyQuery, useSubstationLazyQuery } from "src/generated/graphql";
import GoogleMapReact from "google-map-react";
import { ModuleType } from "src/types/graphql/moduleType";
import { useNavigate, useParams } from "react-router-dom";
import ModuleInfoCard from "src/@core/components/card/ModuleInfoCard";
import DeviceHubRounded from "@mui/icons-material/DeviceHubRounded";
import useBgColor, { UseBgColorType } from "src/@core/hooks/useBgColor";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import { SubstationType } from "src/types/graphql/substationType";

// ** Next Imports

export enum IColorsType {
  active = "success",
  pending = "warning",
  inactive = "secondary",
}

export type IEnergy = {
  voltageL1N: number;
  voltageL2N: number;
  voltageL3N: number;
  voltageL4N?: number;
  voltageL1L2?: number;
  voltageL2L3?: number;
  VoltageL3L1?: number;
  currentL1: number;
  currentL2: number;
  currentL3: number;
  currentL4?: number;
  neutralCurrent?: number;
  measuredFreq?: number;
  activePowerL1N: number;
  activePowerL2N: number;
  activePowerL3N: number;
  activePowerL4N?: number;
  time: number;
}

const MapMark = (props: any) => (
  <Box sx={{ position: "relative" }}>
    <Card
      onClick={() => props.onClick(props.moduleId)}
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        cursor: "pointer",
        backgroundColor: "#1B2441CC",
        "&:hover": {
          backgroundColor: "#1B2441",
        },
      }}
    >
      <CardContent
        sx={{
          width: 40,
          height: 40,
          p: 0,
          m: 0,
        }}
      >
        <Tooltip title={props.title}>
          <CustomAvatar
            skin="light"
            color="primary"
            sx={{
              width: 40,
              height: 40,
              borderRadius: "7px !important",
            }}
          >
            <DeviceHubRounded />
          </CustomAvatar>
        </Tooltip>
      </CardContent>
    </Card>
  </Box>
);

const customMarkerIcon = new Icon({
  iconUrl: '/module.png',
  iconSize: [36, 36]
});

const Tab = styled(MuiTab)<TabProps>(({ theme }) => ({
  minHeight: 48,
  flexDirection: "row",
  "& svg": {
    marginBottom: "0 !important",
    marginRight: theme.spacing(3),
  },
}));

const SubstationViewPage = () => {
  const defaultProps = {
    center: {
      lat: 44.333080173284976,
      lng: 26.838590382254353,
    },
    zoom: 9,
  };

  // ** Hooks
  const navigate = useNavigate();
  const params = useParams();


  // ** States
  const [Modules] = useModulesLazyQuery();
  const [getSubstation] = useSubstationLazyQuery();

  // ** States
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [modules, setModules] = useState<ModuleType[]>([]);
  const [substation, setSubstation] = useState<SubstationType>();

  const [energyData, setEnergyData] = useState<IEnergy>();

  const [value, setValue] = useState<string>("location");

  const [visibityOfSubstation, setVisibityOfSubstation] =
    useState<boolean>(true);

  // ** Utils Functions

  // ** Functions
  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const changeVisibilityOfSubstation = () => {
    setVisibityOfSubstation(!visibityOfSubstation);
  };

  const fetchTableData = useCallback(async () => {
    await getSubstation({ variables: { id: params.substationId || ''}}).then(data => setSubstation(data.data?.substation))

    await Modules({
      variables: {
        substationId: params.substationId,
      },
      fetchPolicy: "cache-and-network",
    }).then((res) => {
      setTotalRowCount(res.data?.modules.count || 0);
      setModules(res.data?.modules.items || ([] as ModuleType[]));
    });
  }, []);

  const onNavigateModuleView = (pId: string) => {
    navigate(`/module/view/${pId}`);
  };

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  useEffect(() => {
    function onEnergyDataEvent(value: IEnergy) {
      value.voltageL1N = value.voltageL1L2 || 0;
      value.voltageL2N = value.voltageL2L3 || 0;
      value.voltageL3N = value.VoltageL3L1 || 0;
      setEnergyData(value);
    }

    socket.on("energy", onEnergyDataEvent);

    return () => {
      socket.off("energy", onEnergyDataEvent);
    };
  }, []);

  return (
    <TabContext value={value}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <TabList
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            sx={{
              width: "100%",
              display: visibityOfSubstation ? "flex" : "none",
            }}
          >
            <Tab
              value="location"
              label="Location"
              iconPosition="start"
              icon={<GpsFixedRoundedIcon />}
            />
          </TabList>
          <Box p={3}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
            >
              <Chip
                variant="outlined"
                color="primary"
                sx={{
                  fontWeight: 600,
                }}
                label={`Total number of modules: ${totalRowCount}`}
              />
              <Tooltip title={visibityOfSubstation ? "Hide" : "Show"}>
                <IconButton onClick={changeVisibilityOfSubstation}>
                  {visibityOfSubstation ? (
                    <ViewWeekRoundedIcon />
                  ) : (
                    <ViewSidebarRoundedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{ display: visibityOfSubstation ? "block" : "none" }}
        >
          <Box sx={{ mt: 0 }}>
            <TabPanel sx={{ p: 0 }} value="location">
              <Box width={"100%"}>
                <div
                  style={{
                    margin: 0,
                    padding: 0,
                    height: "calc(100vh - 200px)",
                    width: "100%",
                  }}
                >
                  {substation && 
                  <MapContainer center={[Number(substation?.latitude) || 0, Number(substation?.longitude) || 0]} zoom={15} scrollWheelZoom={false}>
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                      {modules.map((item, index) =>
                      <Marker icon={customMarkerIcon} position={[Number(item?.latitude) || 0, Number(item?.longitude) || 0]} 
                      eventHandlers={{
                        click: () => {
                          onNavigateModuleView(item.id)
                        },
                      }} >
                        <Popup>
                          {item.name}
                        </Popup>
                      </Marker>
                      )}
                  </MapContainer> }
                  {/* <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyDAQIns994YxmiHuh1S2XVFDtg53mOCEX8",
                    }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                  >
                    {modules.map((item, index) => (
                      <MapMark
                        key={index}
                        moduleId={item.id}
                        lat={item.latitude}
                        lng={item.longitude}
                        text={item.name}
                        onClick={onNavigateModuleView}
                      />
                    ))}
                  </GoogleMapReact> */}
                </div>
              </Box>
            </TabPanel>
          </Box>
        </Grid>
        <Grid item xs={12} md={visibityOfSubstation ? 8 : 12}>
          <Box
            sx={{
              height: "calc(100vh - 150px) !important",
              maxHeight: "calc(100vh - 150px) !important",
              overflow: "scroll",
            }}
          >
            <Grid container spacing={3}>
              {modules.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  display={"grid"}
                  gridAutoColumns={"1fr"}
                  gridAutoFlow={"column"}
                >
                  <ModuleInfoCard
                    key={index}
                    energyValues={energyData as IEnergy}
                    id={item.id}
                    title={item.name}
                    description={item.description as string}
                    sensorCount={item._count?.sensors as number}
                  ></ModuleInfoCard>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </TabContext>
  );
};

export default SubstationViewPage;
